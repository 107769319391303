import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

interface LabTestsIds {
  bloodwork?: string[];
  pregnancy?: string[];
}

export const createLabOrder = async ({
  patientId,
  employeeId,
  pdfMetadata,
  tests,
  isImmediate,
  featureToggle,
}: {
  patientId: string;
  employeeId: string;
  pdfMetadata: string;
  tests: LabTestsIds;
  isImmediate?: boolean;
  featureToggle?: boolean;
}): Promise<any[]> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<
      GraphQLResponse<{
        createLabOrder: { orderIds: string[] };
      }>
    >(
      `${ENV_CONFIG.LAB_SERVICE_GRAPHQL_URL}`,
      {
        query: `
          mutation ($labOrder: LabOrderPayload!) {
          createLabOrder(labOrder: $labOrder) {
            orderIds
          }
        }
        `,
        variables: {
          labOrder: {
            patientId,
            employeeId,
            pdfMetadata,
            tests,
            isImmediate,
            featureToggle,
          },
        },
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.createLabOrder;
  } catch (error: any) {
    logError("Error occurred. Cannot create lab order", error);
    throw error;
  }
};
