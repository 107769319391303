import React from "react";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { getMomentDate } from "../../../../utils/get-date-pretty";

export function BirthDate({ payload }: { payload: PatientDashboardItem }) {
  return (
    <p>
      {getMomentDate(payload.dateOfBirth)
        .add(12, "hours")
        .format("MMM Do, YYYY")}
    </p>
  );
}
