import React, { useContext, useState } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import { TextInput } from "../../../../NewComponents/Common/TextInput";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";
import { SurveyNavigationButtons } from "../../../Common/SurveyNavigationButtons";
import { DateInput } from "../../../../NewComponents/Common/DateInput";
import { Validator } from "../../../../../services/validator/validator.interface";
import { PopupLayerContext } from "../../../../NewComponents/Common/PopupLayer";

export type Data = { [p: string]: any };

export enum InputType {
  Date,
  Text,
}

interface DataInputQuestionProps {
  onDone: (data: Data) => void | Promise<void>;
  data: Data;
  questionKey: string;
  getTitle: (data: Data) => string;
  getDescription: (data: Data) => string;
  placeholder?: string;
  type?: InputType;
  validator: Validator;
  name: string;
}

function getInputComponentFromType(type: InputType) {
  switch (type) {
    case InputType.Date:
      return DateInput;
    case InputType.Text:
      return TextInput;
    default:
      return TextInput;
  }
}

export function DataInputQuestion({
  data,
  onDone,
  getTitle,
  getDescription,
  questionKey,
  placeholder,
  type = InputType.Text,
  validator,
  name,
}: DataInputQuestionProps) {
  const [currentData, setData] = useState(data[questionKey]);
  const [invalid, setInvalid] = useState<string>("");
  const { onBack } = useCustomSurveyStepper();
  const { showPopup } = useContext(PopupLayerContext);

  function submit() {
    const error = validator.validate(currentData, showPopup);

    if (typeof error === "string") setInvalid(error);
    else {
      sendSurveyQuestion(questionKey);
      onDone({ [questionKey]: currentData });
    }
  }

  const InputComponent = getInputComponentFromType(type);

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">{getTitle(data)}</p>
      <p className="survey-question__descr paragraph-font--color">
        {getDescription(data)}
      </p>
      <InputComponent
        id={name}
        name={name}
        onChange={(value) => {
          setData(value);
          setInvalid("");
        }}
        required
        value={currentData || ""}
        placeholder={placeholder}
        error={invalid}
        className="survey-question__input"
      />
      <SurveyNavigationButtons
        submit={submit}
        disabledContinue={!currentData}
        onBack={onBack}
      />
    </div>
  );
}
