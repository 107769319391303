import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SerializedError } from "@reduxjs/toolkit";
import { ENV_CONFIG } from "../../../../../config";
import { logError } from "../../../../../shared/logger";
import { LineItem } from "../../../../../types/Base/Products";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { ShippingInfo } from "../../../../../types/Entities/Patient";
import { PaymentOptions } from "../../../../../types/Payment/PaymentConfiguration";
import { ProductPayload } from "../../../../../types/Payment/Product";
import { NotificationLayerContext } from "../../../../NewComponents/Common/NotificationLayer";
import { Stepper } from "../../../../NewComponents/Common/Stepper";
import { CheckoutFormCheckoutInfo } from "../../../../NewComponents/Patient/CheckoutForm/checkoutInfo";
import { CheckoutFormPaymentMethods } from "../../../../NewComponents/Patient/CheckoutForm/paymentMethods";
import { CheckoutFormShippingInfo } from "../../../../NewComponents/Patient/CheckoutForm/shippingInfo";
import { getSessionState } from "../../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../../features/api/patients";
import { HoneydewAPI } from "../../../../../services/honeydew-api";
import { CustomerInfo } from "../../../../../types/CustomerInfo";
import {
  useGetPricesQuery,
  useProcessCheckoutMutation,
} from "../../../../../features/api/payment";
import { Skeleton } from "../../../../NewComponents/Common/Skeleton";
import { sendPaymentSuccess } from "../../../../../shared/analytics";

const stripePromise = loadStripe(ENV_CONFIG.STRIPE_KEY);

export function PaymentFormSurveyPageContent({
  onDone,
  data,
  stepper: { max, current },
}: SurveyComponentProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const pricesQuery = useGetPricesQuery(null);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [processCheckout, { isSuccess, isError, error }] =
    useProcessCheckoutMutation();
  const [isCurrentAddress, setIsCurrentAddress] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null);
  const [couponCode, setCouponCode] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const productsData: LineItem[] = data.products;

  useEffect(() => {
    async function getStripeCustomerInfo() {
      if (!activePatientId) return;
      const _customerInfo = await HoneydewAPI.payments.getCustomerInfo(
        activePatientId
      );
      setCustomerInfo(_customerInfo);
    }
    getStripeCustomerInfo();
  }, [activePatientId]);

  useEffect(() => {
    if (isError) {
      showError({
        title: "Something went wrong",
        description:
          (error as SerializedError).message || "Cannot process checkout",
      });
      return;
    }

    if (isSuccess) {
      onDone({});
    }
  }, [isSuccess, isError]);

  async function getPaymentMethod() {
    if (!patientQuery.isSuccess || !stripe || !elements) throw new Error("");

    if (!paymentMethodId) {
      const patient = patientQuery.data;
      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        const message =
          "Cannot submit payment: issues with stripe card element";
        logError(message);
        throw new Error(message);
      }

      const billingDetails = {
        name: cardHolderName,
        email: patient.email,
        address: patient.shippingInfo
          ? {
              line1: patient.shippingInfo?.addressLine1,
              line2: patient.shippingInfo?.addressLine2 || "",
              state: patient.shippingInfo?.state,
              postal_code: patient.shippingInfo?.zipCode,
              city: patient.shippingInfo?.city,
            }
          : undefined,
      };

      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethod.error) {
        throw new Error("");
      }

      return paymentMethod.paymentMethod.id;
    }

    return paymentMethodId;
  }

  async function submit(paymentOptions: PaymentOptions) {
    if (!patientQuery.isSuccess || !pricesQuery.isSuccess) return;
    const patient = patientQuery.data;
    const paymentMethod = await getPaymentMethod();
    const membershipPriceId = data.membershipPlan
      ? paymentOptions?.membership?.priceId
      : null;
    const purchasedProductsPriceIds: ProductPayload[] | null = productsData
      ? productsData
          .reduce(
            (acc: ProductPayload[], { priceId, quantity }) => [
              ...acc,
              {
                price: priceId,
                quantity,
              },
            ],
            []
          )
          .filter(({ quantity }) => quantity > 0)
      : null;

    try {
      await processCheckout({
        patientId: patient.patientId,
        paymentMethodId: paymentMethod,
        subscriptionPrice: membershipPriceId || undefined,
        productItems: purchasedProductsPriceIds || undefined,
        promotionCode: couponCode?.name || undefined,
        shippingInfo: isCurrentAddress ? patient.shippingInfo : shippingInfo,
        isPaymentMethodExists: !!paymentMethodId,
      });
      const boughtItems: { priceId: string; quantity: number }[] = [];
      purchasedProductsPriceIds?.forEach(({ price, quantity }) => {
        boughtItems.push({ priceId: price, quantity });
      });
      if (membershipPriceId)
        boughtItems.push({ priceId: membershipPriceId, quantity: 1 });
      const finalAmount = boughtItems.reduce((acc, { priceId, quantity }) => {
        const amount =
          pricesQuery.data.find(({ id }) => id === priceId)?.amount || 0;
        return acc + amount * quantity;
      }, 0);

      sendPaymentSuccess(finalAmount || 0, patientQuery?.data?.patientId);
    } catch (e: any) {
      showError({
        title: "Something went wrong",
        description:
          "Couldn't make a payment for some reason. Please, try again later",
      });
    }
  }

  return (
    <div className="survey-question initiate-checkout-page__page initiate-checkout-page__page--no-width">
      <div className="checkout-form-page">
        <div className="checkout-form-page--title">
          <Stepper max={max} current={current} />
          <p className="survey-question__title">Provide your order details</p>
        </div>
        <div className="checkout-form-page--card">
          <p className="checkout-form-page__subtitle">
            1. Select your payment method
          </p>
          {customerInfo ? (
            <CheckoutFormPaymentMethods
              cardHolderName={cardHolderName}
              items={customerInfo.paymentMethods}
              onCardHolderNameChange={(name) => setCardHolderName(name)}
              onSelectChange={(_paymentMethodId) =>
                setPaymentMethodId(_paymentMethodId)
              }
            />
          ) : (
            <Skeleton count={3} flex fullHeight fullWidth column />
          )}
        </div>
        {data.products?.some(({ quantity }: LineItem) => quantity > 0) ? (
          <div className="checkout-form-page--shipping">
            <p className="checkout-form-page__subtitle">
              2. Select your shipping address
            </p>
            <CheckoutFormShippingInfo
              onShippingInfoChange={(info) => setShippingInfo(info)}
              onSelectChange={(isCurrent) => setIsCurrentAddress(isCurrent)}
            />
          </div>
        ) : null}
        <div className="checkout-form-page--checkout">
          <CheckoutFormCheckoutInfo
            onCouponChange={setCouponCode}
            internalCredit={customerInfo ? customerInfo.internalCredit : 0}
            products={productsData}
            enableCheckout={patientQuery.isSuccess}
            subscription={data.membershipPlan}
            onSubmit={submit}
          />
        </div>
      </div>
    </div>
  );
}

export function PaymentFormSurveyPage(props: SurveyComponentProps) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormSurveyPageContent {...props} />
    </Elements>
  );
}
