import React, { useContext, useMemo, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { useBloodWorkStepper } from "../../../../../contexts/blood-work";
import { getSteps } from "./steps";
import CustomStepper from "../../../Common/Material/Stepper";
import { Button } from "../../../Common/Material/Button";
import {
  useAddBloodWorkMutation,
  usePopulateBloodSlipPdfMutation,
} from "../../../../../features/api/accutane";
import { NotificationLayerContext } from "../../../Common/NotificationLayer";
import { updateLabOrder } from "../../../../../services/honeydew-api/labs/update-lab-order";
import { useMobile } from "../../../../../hooks/use-mobile";

export default function CheckoutInsurance() {
  const { handleNext, handleBack, bloodwork, onClose, updateValues } =
    useBloodWorkStepper();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [loading, setLoading] = useState(false);
  const [generatingSlip, setGeneratingSlip] = useState(false);
  const [populatePdf] = usePopulateBloodSlipPdfMutation();
  const [addBloodWork] = useAddBloodWorkMutation();
  const { isMobile } = useMobile();

  const inProgress = useMemo(
    () => bloodwork.task?.status === "IN_PROGRESS",
    [bloodwork]
  );

  const oldInProgress = useMemo(
    () =>
      bloodwork.task?.status === "IN_PROGRESS" &&
      !bloodwork.labOrder?.populatedDocumentUrl,
    [bloodwork]
  );

  const isMigrated = useMemo(
    () => bloodwork.task?.status === "ASSIGNED_MIGRATED",
    [bloodwork]
  );

  const document = useMemo(
    () =>
      bloodwork?.labOrder?.populatedDocumentUrl
        ? {
            name: bloodwork.labOrder?.populatedDocumentName,
            url: bloodwork.labOrder?.populatedDocumentUrl,
          }
        : undefined,
    [bloodwork]
  );

  const handleSubmit = async () => {
    if (inProgress || isMigrated) {
      handleNext();
      return;
    }
    try {
      setGeneratingSlip(true);

      const metadata = JSON.parse(bloodwork?.labOrder?.pdfMetadata || "{}");

      const response = await populatePdf(metadata);

      updateValues({
        task: { ...bloodwork.task, status: "IN_PROGRESS" },
        labOrder: {
          ...bloodwork.labOrder,
          populatedDocumentName: response?.data?.document.name,
          populatedDocumentUrl: response?.data?.document.url,
        },
      });

      const result: any = await updateLabOrder({
        taskId: bloodwork.task?.id,
        placeOfService: "medical-office",
        paymentMethod: bloodwork.paymentMethod,
        document: response?.data?.document,
      });
      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to complete task",
        });
        return;
      }
      showSuccess({
        title: "Task successfully initiated",
        description:
          "Task has been marked as in progress and bloodslip was generated",
      });
    } catch (e) {
      showError({
        title: "Something went wrong...",
        description: "Unable to flag task as in progress",
      });
    } finally {
      setLoading(false);
      setGeneratingSlip(false);
    }
  };

  const onComplete = async () => {
    if (bloodwork.patient?.patientId) {
      setLoading(true);
      const result: any = await addBloodWork(bloodwork.patient.patientId);
      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to mark the blood test as complete",
        });
        setLoading(false);
        return;
      }
      showSuccess({
        title: "You’ve successfuly marked the blood test as complete!",
        description: "This task is done now.",
      });
      onClose?.();
    }
  };

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          How to complete your blood test
        </Typography>
        <CustomStepper
          steps={getSteps({
            onComplete,
            requestLabSlip: handleSubmit,
            loading,
            generatingSlip,
            needsFasting: bloodwork.needsFasting,
            canComplete: inProgress || isMigrated,
            oldInProgress,
            document,
          })}
          activeStep={inProgress ? 1 : 0}
        />
      </Grid>
      <Grid container gap={2} justifyContent={isMobile ? "center" : "start"}>
        {!inProgress && !isMigrated && (
          <Grid item xs={8} md="auto">
            <Button
              text="Change payment selection"
              size="small"
              view="ghost"
              fullWidth={isMobile}
              disabled={loading}
              onClick={handleBack}
            />
          </Grid>
        )}
        {inProgress && (
          <Grid item xs={8} md="auto">
            <Button
              text="Got it, back to care hub"
              size="small"
              isLoading={loading}
              fullWidth={isMobile}
              onClick={handleSubmit}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
