import { Validator } from "./validator.interface";

export class FullNameValidator implements Validator {
  // eslint-disable-next-line class-methods-use-this
  validate(data: string): string | boolean {
    if (!data.trim()) {
      return "Please, enter your full name";
    }

    if (data.length > 255) {
      return "Full name is too long";
    }

    if (data.match(/[^a-zA-Z' -]/)) {
      return "You can use only latin letters, space, - and ' symbols";
    }

    const words = data.split(" ").filter((it) => !!it);
    if (words.length < 2 || !words.every((it) => /[a-zA-Z]/.test(it))) {
      return "Please, enter your full name";
    }

    return true;
  }
}
