import React, { useState } from "react";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { NumberInput } from "../../../../NewComponents/Common/NumberInput";
import { SurveyNavigationButtons } from "../../../Common/SurveyNavigationButtons";

function surveyToComponent(data: SurveyData) {
  return data.weight;
}

function componentToSurvey(value: string) {
  return {
    weight: +value,
  };
}

export function WeightSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const _data = surveyToComponent(data);
  const [weight, setWeight] = useState<string>(`${_data || ""}`);
  const { onBack } = useCustomSurveyStepper();

  function submit() {
    if (!weight) return;

    sendSurveyQuestion("weight");
    onDone(componentToSurvey(weight));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">What is your weight?</p>
      <p className="survey-question__descr paragraph-font--color" />
      <NumberInput
        id="height-feet"
        name="height-feet"
        min={0}
        step={1}
        onChange={(value) => {
          setWeight(value.replaceAll(/[^0-9]/g, ""));
        }}
        required
        value={weight}
        label="Lbs"
        className="survey-question__input"
      />
      <SurveyNavigationButtons
        submit={submit}
        onBack={onBack}
        disabledContinue={!weight}
      />
    </div>
  );
}
