import React, { useMemo } from "react";
import { Typography, Grid } from "@mui/material";
import { getSteps, steps } from "./steps";
import CustomStepper from "../../../Common/Material/Stepper";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { Button } from "../../../Common/Material/Button";
import { calculateProgress } from "../../../../../utils/lab-work/get-bloodwork-step";

export default function Checkout() {
  const { handleNext, pregnancyWork } = usePregnancyWorkStepper();

  const activeStep = useMemo(
    () => calculateProgress(pregnancyWork.task?.histories ?? []),
    [pregnancyWork]
  );

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          How to complete your pregnancy test
        </Typography>
        <CustomStepper
          steps={getSteps({ needsFasting: pregnancyWork.needsFasting })}
          activeStep={activeStep}
        />
      </Grid>
      <Grid container gap={2} justifyContent="center">
        <Grid item xs={8} md={5}>
          <Button
            text="GOT IT, back to care hub"
            size="small"
            fullWidth
            onClick={handleNext}
          />
        </Grid>
      </Grid>
    </>
  );
}
