import { HoneydewAPI } from "../../services/honeydew-api";

type FetchChatDataParams = {
  filterArray: any[];
  userId: string;
};

export const fetchChatData = async ({
  filterArray,
  userId,
}: FetchChatDataParams) => {
  if (filterArray.length > 0) {
    const { filter } = filterArray[0];
    const mustQuery = Array.isArray(filter.must) ? [...filter.must] : [];

    // Ensure the chat field exists
    mustQuery.push({
      exists: {
        field: "chat",
      },
    });

    // Add condition to match chat.userId
    mustQuery.push({
      bool: {
        should: [
          {
            match: {
              "chat.userId": userId,
            },
          },
        ],
      },
    });

    // Construct the full query
    const query = {
      query: {
        from: 0,
        size: 1000,
        query: {
          function_score: {
            query: {
              bool: {
                must: mustQuery,
                filter: filter.filter || [],
              },
            },
          },
        },
        sort: [
          {
            latestChatTimestamp: {
              order: "desc",
            },
          },
        ],
      },
    };

    try {
      // Fetch data from the API
      const results = await HoneydewAPI.dashboard.queryDashboardItems(query);

      // Filter chats where subscription membership is true or doesn't exist
      const filteredChats = results.hits.filter((result: any) => {
        if (!result._source.subscription) {
          return true;
        }
        return result._source.subscription.membership === true;
      });

      return filteredChats;
    } catch (error) {
      console.log("Error in fetching table data", error);
      throw error;
    }
  }
  return [];
};
