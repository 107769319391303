import React from "react";
import { useSelector } from "react-redux";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { useSetFlagMutation } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { ReactComponent as UnpinIcon } from "../../../../Assets/NewIcons/pin.svg";
import { ReactComponent as PinIcon } from "../../../../Assets/NewIcons/unpin.svg";
import { EMPLOYEE_ROLES } from "../../../../types/Employee";
import { USER_ROLES } from "../../../../types/Main";
import { TableOperation } from "../../../../types/Table/TableOperation";

type RoleKeyMap = {
  [k in USER_ROLES]: string;
};

const mapRoleToKey: RoleKeyMap = {
  [USER_ROLES.PROVIDER]: "Provider",
  [USER_ROLES.CARE_COORDINATOR]: "CareCoordinator",
  [USER_ROLES.ADMINISTRATOR]: "Administrator",
  [USER_ROLES.ENROLLMENT_COORDINATOR]: "NO_PIN_BUTTON",
  [USER_ROLES.PATIENT]: "NO_PIN_BUTTON",
};

interface PinButtonProps {
  payload: PatientDashboardItem;
  employeeType: EMPLOYEE_ROLES;
  updateTable: (
    operation: TableOperation<PatientDashboardItem>
  ) => Promise<void>;
}

export function PinButton({
  payload,
  employeeType,
  updateTable,
}: PinButtonProps) {
  const [togglePin] = useSetFlagMutation();
  const { userId } = useSelector(getSessionState);

  const previousPinnedFlag = `pinnedBy${mapRoleToKey[employeeType]}`;
  const pinnedFlag = `pinnedBy${mapRoleToKey[employeeType]}_${userId}`;
  const pinned =
    !!payload.flags[pinnedFlag] || !!payload.flags[previousPinnedFlag];

  const pinPatient = async () => {
    await updateTable({
      operation: (list: PatientDashboardItem[]) => {
        const listWithoutPatient = list.filter(
          ({ patientId }) => patientId !== payload.patientId
        );
        const updatedFlags = { ...payload.flags, [pinnedFlag]: !pinned };
        const updatedPayload = { ...payload, flags: updatedFlags };

        const pinnedPatients = listWithoutPatient.filter(
          ({ flags }) => flags[pinnedFlag]
        );
        const unpinnedPatients = listWithoutPatient.filter(
          ({ flags }) => !flags[pinnedFlag]
        );
        return [...pinnedPatients, updatedPayload, ...unpinnedPatients];
      },
    });

    await togglePin({
      patientId: payload.patientId,
      flag: pinnedFlag,
      state: !pinned,
    });

    // If unpinning, also set the previousPinnedFlag to false
    if (pinned && payload.flags[previousPinnedFlag]) {
      await togglePin({
        patientId: payload.patientId,
        flag: previousPinnedFlag,
        state: false,
      });
    }
  };

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        pinPatient();
      }}
      data-tooltip={pinned ? "Unpin" : "Pin"}
    >
      {pinned ? <UnpinIcon /> : <PinIcon />}
    </div>
  );
}
