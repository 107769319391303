import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";
import Resizer from "react-image-file-resizer";
import { ReactComponent as DragIcon } from "../../../../Assets/NewIcons/upload-cloud.svg";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import { logError, logInfo } from "../../../../shared/logger";
import { ReactComponent as ClearIcon } from "../../../../Assets/NewIcons/cross-icon.svg";
import "./style.scss";
import { transformString } from "../../../../utils/transformString";

interface Props {
  side: string;
  sidePlaceholder?: string;
  setImage: (file: Blob | ImageBlob | null) => void;
  placeholderClassName?: string;
  customPreview?: JSX.Element | null;
  customDiscardButton?: JSX.Element | null;
  fileFieldText?: string;
  index?: number;
  removeImage?: JSX.Element | null;
  value?: Blob | ImageBlob | null;
  isValidUrl?: boolean;
}

const MAX_FILES = 1;
const MIN_FILE_SIZE = 0; // bytes

const errorMap: { [p: string]: string } = {
  "too-many-files": "Too many photos. Please upload only one photo per input",
  "file-too-small":
    "This photo is smaller than 100 KB. Please upload photos in better quality",
  "file-invalid-type":
    "Invalid image type. Please, convert an image type to .jpg, .png or .heic or choose another image",
};

const imageTurnMap: { [p: string]: string } = {
  face_left: "--left",
  face_center: "--center",
  face_right: "--right",
  neck: "--neck",
  chest_front: "--chest-front",
  butt: "--butt",
  left_arm: "--right-arm",
  right_arm: "--left-arm",
  frontal_hairline: "--frontal-hairline",
  mid_scalp: "--mid-scalp",
  neckline: "--neckline",
  abdomen: "--abdomen",
  back_1: "--back",
  back_2: "--back",
  groin_area: "--groin-area",
  left_hand: "--left-hand",
  right_hand: "--right-hand",
  left_leg: "--left-leg",
  right_leg: "--right-leg",
  left_foot: "--left-foot",
  right_foot: "--right-foot",
  left_hair_side: "--left-hair-side",
  right_hair_side: "--right-hair-side",
};

const imageMaxSize = 734003; // 0.7 Mb

// make image fittable to upload in Safari.
const adaptImage = async (image: Blob): Promise<Blob> => {
  const imageSize: { width: number; height: number } = await new Promise(
    (resolve) => {
      const htmlImage = new Image();
      htmlImage.onload = () => {
        resolve({ width: htmlImage.width, height: htmlImage.height });
      };
      htmlImage.src = URL.createObjectURL(image);
    }
  );

  if (image.size <= imageMaxSize) {
    // just optimize lower-sized images so they will not be stretched in CSS incorrectly
    return await new Promise((resolve) => {
      Resizer.imageFileResizer(
        image,
        imageSize.width,
        imageSize.height,
        "jpeg",
        90,
        0,
        (uri) => resolve(uri as Blob),
        "blob"
      );
    });
  }

  const imageSizeDecreaseCoefficient = Math.sqrt(imageMaxSize / image.size);

  return await new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      Math.round(imageSize.width * imageSizeDecreaseCoefficient),
      Math.round(imageSize.height * imageSizeDecreaseCoefficient),
      "jpeg",
      85,
      0,
      (uri) => resolve(uri as Blob),
      "blob"
    );
  });
};

const getFaceTurnForPlaceholderImageClass = (side: string) => {
  const classModifier = imageTurnMap[side] || null;

  if (!classModifier) {
    logError("Unrecognized face turn for image placeholder");
  }

  return classModifier;
};

export function ImageUploadTile({
  setImage,
  side,
  sidePlaceholder,
  customPreview,
  customDiscardButton,
  placeholderClassName,
  fileFieldText,
  value,
  isValidUrl,
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [isLoading, setLoadingStatus] = useState(false);

  useEffect(() => {
    if (value) {
      let fileObject = {};
      if (isValidUrl === true) {
        fileObject = {
          preview: value, // Directly use the URL
        };
      } else {
        fileObject = {
          preview: URL.createObjectURL(value),
        };
      }
      setFile(fileObject);
    }
  }, [value]);

  const clearImage = () => {
    setFile(null);
    setImage(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/jpeg, image/heic, image/heif, image/png, application/pdf",
    noClick: true,
    noKeyboard: true,
    maxFiles: MAX_FILES,
    minSize: MIN_FILE_SIZE,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (!acceptedFiles.length || error) {
        return;
      }

      setLoadingStatus(true);

      const _file = acceptedFiles[0];
      let formattedImage;

      if (_file.type === "image/heic" || _file.type === "image/heif") {
        formattedImage = await heic2any({
          blob: _file,
          toType: "image/jpeg",
        });
      } else {
        formattedImage = _file;
      }

      if (_file.type !== "application/pdf") {
        const adaptedImage = (await adaptImage(
          formattedImage as Blob
        )) as ImageBlob;

        Object.assign(_file, {
          preview: URL.createObjectURL(adaptedImage),
        });

        setImage(adaptedImage);
        setFile(_file);
        logInfo("file", adaptedImage);
        setLoadingStatus(false);
        return;
      }

      setImage(formattedImage as Blob);
      setFile(_file);
      setLoadingStatus(false);
    },
    onDropRejected: (files) => {
      logInfo("files[0]", files[0]);
      setError(errorMap[files[0].errors[0].code]);
    },
  });

  const discardImage = () => {
    setFile(null);
    setImage(null);
  };

  const closeErrorMsg = () => {
    setError("");
  };

  const renderPlaceholder = () => (
    <>
      <div
        className={
          placeholderClassName ||
          `image-upload-tile__drag-placeholder image-upload-tile__background image-upload-tile__background${getFaceTurnForPlaceholderImageClass(
            side
          )}`
        }
        onClick={open}
      >
        <DragIcon className="image-upload-tile__icon" />
        <span className="image-upload-tile__drag-text">
          <b>{fileFieldText || "Choose a file"}</b>
        </span>
        <span className="image-upload-tile__drag-text image-upload-tile__drag-text--second paragraph-font--color">
          or drag it here
        </span>
        {side && sidePlaceholder && (
          <span className="image-upload-tile__drag-text image-upload-tile__drag-text--side">
            {transformString(side)}
          </span>
        )}
        {isLoading ? (
          <div className="image-upload-tile__load-overlay" />
        ) : (
          <input {...getInputProps()} />
        )}
      </div>
      {file && (
        <button
          className="image-upload-tile__clear-button"
          onClick={clearImage}
          type="button"
        >
          <ClearIcon />
        </button>
      )}
    </>
  );

  const renderImageThumbnail = () => (
    <div className="image-upload-tile__image-container">
      {customPreview || (
        <img
          src={file?.preview}
          className="image-upload-tile__image-preview"
          alt="Discard"
        />
      )}
      {!customPreview && (
        <span className="image-upload-tile__drag-text image-upload-tile__drag-text--side">
          {transformString(side)}
        </span>
      )}
      {customDiscardButton ? (
        React.cloneElement(customDiscardButton, { discardImage })
      ) : (
        <button
          className="image-upload-tile__discard-button"
          onClick={discardImage}
          type="button"
        />
      )}
    </div>
  );

  const renderErrorMsg = () => (
    <div className="image-upload-tile__error-container">
      <button
        className="image-upload-tile__discard-button"
        onClick={closeErrorMsg}
        type="button"
      />
      <p className="image-upload-tile__error-msg">{error}</p>
    </div>
  );

  const render = () => {
    if (file) {
      return renderImageThumbnail();
    }
    if (error) {
      return renderErrorMsg();
    }

    return renderPlaceholder();
  };

  return (
    <div className="image-upload-tile__drag" {...getRootProps()}>
      {render()}
    </div>
  );
}
