export enum USER_ROLES {
  PATIENT = "patient",
  PROVIDER = "provider",
  CARE_COORDINATOR = "care-coordinator",
  ENROLLMENT_COORDINATOR = "enrollment-coordinator",
  ADMINISTRATOR = "administrator",
}

export enum ACTIONS {
  PIN = "pin",
  NO_SHOW = "no-show",
  DOTS = "dots",
}
