import zipState from "zip-state";
import { Validator } from "./validator.interface";
import { HONEYDEW_ELIGIBLE_STATES } from "../../data/states";
import { UnsupportedAreaPopupGenerator } from "../../app/NewComponents/Patient/UnsupportedAreaPopup";

export class ZipCodeValidator implements Validator {
  // eslint-disable-next-line class-methods-use-this
  validate(
    data: string,
    popup: (data: (...args: any[]) => any) => void
  ): string | boolean {
    const valid = new RegExp(/^\d{5}$/).test(data);
    if (!valid) {
      return "Invalid zip code";
    }
    const state = zipState(data);
    const isEligibleState =
      state && Object.keys(HONEYDEW_ELIGIBLE_STATES).includes(state);

    if (!isEligibleState) {
      popup(UnsupportedAreaPopupGenerator(data));
      return "";
    }

    return true;
  }
}
