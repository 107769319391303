import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useLazyGetCustomerPortalQuery } from "../../../../features/api/payment";
import { Button } from "../../../NewComponents/Common/Material/Button";
import {
  BackTitle,
  CardContainer,
  Container,
  LogoContainer,
  Subtitle,
  Title,
} from "./styles";
import { useMobile } from "../../../../hooks/use-mobile";
import { ReactComponent as ArrowBack } from "../../../../Assets/NewIcons/back.svg";
import { ReactComponent as BillingLogo } from "../../../../Assets/NewIcons/billing.svg";
import { ReactComponent as HyperLink } from "../../../../Assets/NewIcons/hyper-link.svg";

export function BillingPage() {
  const { activePatientId } = useSelector(getSessionState);
  const { data: patient } = useGetPatientByIdQuery(activePatientId as string);
  const [portalUrl, setPortalUrl] = useState<string>("");
  const [triggerGetCustomerPortal] = useLazyGetCustomerPortalQuery();
  const { isMobile } = useMobile();
  const history = useHistory();

  const fetchBillingData = async (customerId: string) => {
    const url = await triggerGetCustomerPortal(customerId).unwrap();
    setPortalUrl(url);
  };

  useEffect(() => {
    if (patient?.customerId) {
      fetchBillingData(patient.customerId);
    }
  }, [patient]);

  const handleOpenPortal = () => {
    window.open(portalUrl, "_blank");
  };

  const navigateBack = () => {
    history.push("/");
  };

  return (
    <Container
      container
      sx={{
        margin: isMobile ? "0px" : "32px 10rem",
        maxWidth: isMobile ? "100%" : "calc(100% - 30rem)",
        padding: isMobile ? "0px" : "16px",
      }}
      justifyContent="flex-start"
      alignSelf="flex-start"
      columnGap={1}
      rowGap={2}
    >
      {!isMobile && (
        <Grid item xs={12}>
          <BackTitle onClick={navigateBack}>
            <ArrowBack /> Back to Dashboard
          </BackTitle>
        </Grid>
      )}
      {!isMobile && (
        <Grid item xs={12}>
          <Title>Billing</Title>
        </Grid>
      )}
      <CardContainer container justifyContent="space-between">
        <Grid
          item
          md={8}
          xs={12}
          pr={1}
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
        >
          <Box
            sx={{
              display: "block",
              position: "relative",
            }}
          >
            {isMobile && (
              <BillingLogo
                style={{
                  float: "right",
                  marginTop: "40px",
                  maxWidth: "150px",
                  maxHeight: "150px",
                }}
              />
            )}
            <Subtitle gutterBottom>Review your payment information</Subtitle>
            <Typography variant="body1" color="textSecondary" mb={2}>
              Honeydew handles billing through our secure payment processor,
              Stripe.
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={2}>
              Click the button below to open the customer portal to update your
              payment method and view your payment history.
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={2}>
              If you’d like to cancel your membership, please email us at{" "}
              <span style={{ color: "#5E39F5" }}>
                membership@honeydewcare.com
              </span>
              .
            </Typography>
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="flex-start"
            alignContent="end"
          >
            <Button
              variant="contained"
              color="primary"
              text={
                !portalUrl
                  ? "Generating unique link"
                  : "OPEN STRIPE CUSTOMER PORTAL"
              }
              endIcon={portalUrl && <HyperLink />}
              onClick={handleOpenPortal}
              isLoading={!portalUrl}
              disabled={!portalUrl}
              sx={{ fontWeight: 500, fontSize: "12px" }}
            />
          </Box>
        </Grid>
        {!isMobile && (
          <Grid item md={4} xs={12}>
            <LogoContainer>
              <BillingLogo />
            </LogoContainer>
          </Grid>
        )}
      </CardContainer>
    </Container>
  );
}
