import { logError } from "../../shared/logger";

export async function addNewPaymentMethod({
  elements,
  stripe,
  billingDetails,
  CardElement,
}: any) {
  if (!stripe || !elements) return { error: "Stripe not initialized" };

  const cardElement = elements.getElement(CardElement);

  if (!cardElement) {
    const message = "Cannot submit payment: issues with stripe card element";
    logError(message);
    return { error: message };
  }

  const paymentMethod = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: billingDetails,
  });

  if (paymentMethod.error) {
    return { error: paymentMethod.error.message };
  }

  return { paymentId: paymentMethod.paymentMethod.id };
}
