import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  RadioGroup,
  Radio,
} from "@mui/material";
import { debounce } from "lodash";
import isValidZipCode from "../../../../utils/is-valid-zip-code";
import { CustomTextInput } from "../Material/TextInput";
import { CustomRadioButton } from "../Material/RadioButton";
import { LabLabel } from "./LabLabel";
import { Lab } from "../../../../types/Entities/Lab";
import { NoLabsLabel, SectionTitle, Title } from "./styles";
import { PricedLabs } from "./utils";

interface Props {
  onLabSelected: (value: Lab) => void;
  defaultZipCode?: string;
  labsWithPricing: PricedLabs[];
  updateLabLocations: (zipCode?: string) => Promise<Lab[]>;
  resetLabs: () => void;
}

export default function FindLab({
  onLabSelected,
  defaultZipCode,
  labsWithPricing,
  updateLabLocations,
  resetLabs,
}: Props) {
  const [loading, setLoading] = useState(true);
  const [zipCode, setZipCode] = useState(defaultZipCode);
  const [validZipCode, setValidZipCode] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [labsWithPricing]);

  useEffect(() => {
    if (!validZipCode) {
      setLoading(false);
      resetLabs();
    }
  }, [validZipCode]);

  useEffect(() => {
    if (defaultZipCode) {
      setZipCode(defaultZipCode);
    }
  }, [defaultZipCode]);

  const handleSelect = (labUniqueId: string) => {
    const lab = labsWithPricing.find(
      (item: PricedLabs) => item.labUniqueId === labUniqueId
    );
    if (lab) {
      const { labUniqueId: _, ...rest } = lab;
      onLabSelected(rest);
    }
  };

  const searchLabsWithZipCode = (inputZipCode: string) => {
    setZipCode(inputZipCode);
    const isValid = isValidZipCode(inputZipCode);

    if (!isValid || !zipCode) {
      setValidZipCode(false);
      return;
    }
    setValidZipCode(true);

    try {
      setLoading(true);
      const debouncedSearch = debounce(async () => {
        const pricedResponse = await updateLabLocations(inputZipCode);
        if (pricedResponse.length === 0) {
          setLoading(false);
        }
      }, 1000);

      debouncedSearch();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <Title>Find a lab near you</Title>
      </Grid>
      <Grid item xs={12}>
        <SectionTitle gutterBottom>ZIP CODE</SectionTitle>
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextInput
          placeholder="Enter a valid Zip Code"
          value={zipCode}
          onChange={(event) => searchLabsWithZipCode(event.target.value)}
          error={!validZipCode}
        />
      </Grid>
      <Grid item xs={12}>
        {!validZipCode && (
          <Typography variant="subtitle2" mt={1} ml={2}>
            Please enter a valid zip code.
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <SectionTitle variant="subtitle1" mt={2}>
          LAB LOCATIONS
        </SectionTitle>
      </Grid>

      {loading && (
        <Grid item xs={12}>
          <CircularProgress color="primary" />
        </Grid>
      )}

      {!loading && validZipCode && (
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={1}
            onChange={(event) => handleSelect(event.target.value)}
          >
            <Grid container maxHeight="55vh" overflow="scroll">
              {labsWithPricing.map((lab) => (
                <Grid item xs={12} key={lab.labId} pr={4}>
                  <CustomRadioButton
                    value={lab.labUniqueId}
                    control={<Radio />}
                    label={<LabLabel lab={lab} />}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </Grid>
      )}
      {!loading && validZipCode && labsWithPricing.length === 0 && (
        <NoLabsLabel>No labs found within 25 mile radius.</NoLabsLabel>
      )}
      {!loading && !validZipCode && labsWithPricing.length === 0 && (
        <NoLabsLabel>Please enter a valid zip code.</NoLabsLabel>
      )}
    </Grid>
  );
}
