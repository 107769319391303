import React, { useState, useEffect } from "react";
import { PriorityTableBody, ProvidersTableBody } from "./tableBody";
import "./style.css";
import { PriorityTableHead, ProvidersTableHead } from "./tableHead";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { TableItemValue } from "../../../../types/Table/TableItemValue";
import { SortingFunction } from "../../../../types/Table/SortingFunction";
import { SortingPayload } from "../../../../types/Table/SortingPayload";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { USER_ROLES } from "../../../../types/Main";
import { Provider } from "../../../../types/Employee";

const columnsList: TableHeadPayload<TableBodyPayload<Provider>>[] = [
  {
    title: "State",
  },
  {
    title: "Substitute Priority 1",
  },
  {
    title: "Substitute Priority 2",
  },
  {
    title: "Substitute Priority 3",
  },
  {
    title: "Substitute Priority 4",
  },
  {
    title: "Substitute Priority 5",
  },
  {
    title: "Actions",
  },
];

export function PriorityTable() {
  const [sort, setSort] = useState<SortingPayload | null>(null);
  const providersQuery = useGetEmployeesByRoleQuery(USER_ROLES.PROVIDER);
  const [priorityData, setPriorityData] = useState([]);
  const [providerData, setProviderData] = useState();

  useEffect(() => {
    if (providersQuery?.data) {
      // Filter out providers with no stateLicenses or empty stateLicenses
      const cleanedProviders = providersQuery?.data?.filter(
        (provider) =>
          provider.stateLicenses && provider.stateLicenses.length > 0
      );

      const groupedDataTemp = {};

      cleanedProviders.forEach((provider) => {
        provider?.stateLicenses?.forEach((license) => {
          if (license && license.state && license.priority) {
            const { state, priority } = license;
            if (!groupedDataTemp[state]) {
              groupedDataTemp[state] = {
                state,
                priority1: [],
                priority2: [],
                priority3: [],
                priority4: [],
                priority5: [],
                id: provider.id,
              };
            }

            // Add provider's name to the respective priority list
            const providerName = `${provider.firstName} ${provider.lastName}`;
            if (priority === 1)
              groupedDataTemp[state].priority1.push(providerName);
            else if (priority === 2)
              groupedDataTemp[state].priority2.push(providerName);
            else if (priority === 3)
              groupedDataTemp[state].priority3.push(providerName);
            else if (priority === 4)
              groupedDataTemp[state].priority4.push(providerName);
            else if (priority === 5)
              groupedDataTemp[state].priority5.push(providerName);
          }
        });
      });
      const sortedPriorityData = Object.values(groupedDataTemp).sort((a, b) =>
        a.state.localeCompare(b.state)
      );
      setPriorityData(Object.values(sortedPriorityData));

      const stateProviders: any = {};

      cleanedProviders?.forEach((provider) => {
        provider?.stateLicenses?.forEach((license) => {
          const state = license?.state;
          if (!stateProviders[state]) {
            stateProviders[state] = [];
          }
          stateProviders[state].push({
            id: provider?.id,
            name: `${provider?.firstName} ${provider?.lastName}`,
            priority: license?.priority,
            license: license?.license,
          });
        });
      });

      const result = Object.keys(stateProviders).map((state) => ({
        state,
        providers: stateProviders[state],
      }));

      setProviderData(result);
    } else {
      setPriorityData([]);
    }
  }, [providersQuery]);

  return priorityData ? (
    <div
      className="patientsTable-wrapper priorityTable-wrapper"
      style={{
        background: "white",
        padding: "30px 10px",
      }}
    >
      <table className="patientsTable" style={{ background: "white" }}>
        <PriorityTableHead
          columns={columnsList}
          onSortChange={(payload) => setSort(payload)}
        />
        <PriorityTableBody
          priorityList={priorityData}
          providerList={providerData}
        />
      </table>
    </div>
  ) : (
    <Skeleton flex count={5} fullWidth column style={{ height: 60 }} />
  );
}
