import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import "./style.scss";
import { CalendlySurveyQuestion } from "./survey-questions/calendly";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Loader } from "../../../NewComponents/Common/Loader";
import { getSessionState } from "../../../../features/session";
import { Step, useSurveySteps } from "../../../../hooks/use-survey-steps";

export function SubmitAppointmentPage({ patientId }: { patientId: string }) {
  const history = useHistory();
  console.log("[ONBOARDING] previousPage: ", document.referrer);
  const patientQuery = useGetPatientByIdQuery(patientId);
  const { userId } = useSelector(getSessionState);
  const [moveFromCurrentStep] = useSurveySteps(Step.SCHEDULE_APPOINTMENT);
  const onboardingFlag = useFeatureIsOn("onboarding-flag");

  if (!patientQuery.isSuccess) {
    return (
      <div className="main-area__loader-wrap">
        <div className="main-area__loader">
          <Loader />
        </div>
      </div>
    );
  }

  const surveyQuestions: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: CalendlySurveyQuestion,
    },
  ];

  return (
    <CustomSurvey
      title="SCHEDULE YOUR INITIAL VISIT"
      questions={surveyQuestions}
      data={{
        patient: patientQuery.data,
      }}
      onComplete={() => {
        moveFromCurrentStep(userId, onboardingFlag);
        return true;
      }}
    />
  );
}
