import { Grid, styled } from "@mui/material";

export const StyledCardInput = styled(Grid)`
  border: 1px solid #e0e0e0;
  border-radius: 80px;
  padding: 8px 16px;
  height: 48px;
  background-color: #f9f9fa;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
`;
