import { Box, styled } from "@mui/material";

export const Container = styled(Box)({
  textDecoration: "none",
  border: "1px solid #F2F4F7",
  padding: "12px",
  borderRadius: "8px",
  width: "fit-content",
  color: "#403E48",
  fontSize: "12px",
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "8px",
});
