import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { ReactComponent as MapLogo } from "../../../../Assets/NewIcons/map.svg";
import { Lab } from "../../../../types/Entities/Lab";
import { LabAddress, LabName } from "./styles";
import { parsePrice } from "../../../../utils/price";

export function LabLabel({ lab }: { lab: Lab }) {
  return (
    <Grid container>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={9}>
          <LabName>{lab?.metadata?.name}</LabName>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" display="flex">
          <Typography variant="subtitle2">{`$${parsePrice(
            lab?.totalPrice
          )}`}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={9}>
          <LabAddress>
            {`${lab?.metadata?.firstLine ?? ""} ${
              lab?.metadata?.secondLine ?? ""
            } ${lab?.metadata?.city}, ${lab?.metadata?.state} ${
              lab?.metadata?.zipCode
            }`}
          </LabAddress>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" display="flex">
          <Typography variant="subtitle2">
            <Box gap="5px" display="flex" alignItems="center">
              <MapLogo /> {`${lab?.distance} mi`}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
