import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

interface Props {
  placeholder: string;
  variant?: "outlined" | "filled" | "standard";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  endAdornment?: React.ReactNode;
  height: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "80px",
    backgroundColor: "#F9F9FA",
    height: "48px",
    border: "1px solid #E1E0E6",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E1E0E6",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "&&& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0 0 0 3px #eeebfc",
    borderColor: "#c0b1fb",
    borderWidth: "1px",
  },
}));

export function CustomTextInput({
  placeholder,
  variant = "outlined",
  onChange,
  defaultValue,
  ...props
}: Props & TextFieldProps) {
  return (
    <StyledTextField
      variant={variant}
      placeholder={placeholder}
      fullWidth
      defaultValue={defaultValue}
      onChange={onChange}
      {...props}
    />
  );
}
