import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { v4 } from "uuid";
import { Grid } from "@mui/material";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Material/Button";
import { ImageUploadTile } from "../../../../NewComponents/Patient/ImageUploadTile";
import { NotificationLayerContext } from "../../../../NewComponents/Common/NotificationLayer";
import { HoneydewAPI } from "../../../../../services/honeydew-api";
import { FollowUpImage } from "../../../../../types/FollowUp";
import { useSubmitFollowUpMutation } from "../../../../../features/api/follow-ups";
import { generateImageFilename } from "../../../../../utils/generate-image-filename";
import { StyledSelect } from "../../../../NewComponents/Common/StyledSelect";
import { SkinImage, SkinImageType, skinImageTypes } from "./skin-image-types";
import { getSessionState } from "../../../../../features/session";
import {
  useGetPatientByIdQuery,
  useSubmitMedicalBackgroundMutation,
} from "../../../../../features/api/patients";
import { ENV_CONFIG } from "../../../../../config";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";
import { useMobile } from "../../../../../hooks/use-mobile";

export function SkinImagesSurveyQuestion({
  onDone,
  data,
}: SurveyComponentProps) {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const [submitMedicalBackground] = useSubmitMedicalBackgroundMutation();
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const patientSkinType = !!patientQuery?.data?.medicalBackground?.skinSurvey;
  const isDefaultface = ["rosacea", "acne"].includes(data?.skinIssueType);
  const [selectedSkinTypes, setSelectedSkinTypes] = useState<string[]>([]);
  const [submitFollowUp, { isLoading, isSuccess, isError, data: _data }] =
    useSubmitFollowUpMutation();
  const [skinImages, setSkinImages] = useState<SkinImage[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { isMobile } = useMobile();

  const { onBack } = useCustomSurveyStepper();

  useEffect(() => {
    if (isError) {
      showError({
        title: "Something went wrong...",
        description: "Unable to submit skin images. Please, try again later",
      });
      return;
    }

    if (isSuccess) {
      onDone({});
      sendSurveyQuestion("skinImages");
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isDefaultface) {
      setSkinImages(JSON.parse(JSON.stringify(skinImageTypes[0]?.images)));
      setSelectedSkinTypes(["face"]);
    }
  }, [isDefaultface]);

  async function getImageSize(imageUrl: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img.naturalWidth * img.naturalHeight);
      };
      img.onerror = () => {
        reject(new Error("Image could not be loaded"));
      };
      img.src = imageUrl;
    });
  }

  async function submit() {
    if (skinImages.find(({ image }) => !image)) {
      showError({
        title: "Images uploading error",
        description:
          "Some images weren't loaded by you. Please, add remaining images of your skin and submit them again",
      });
      return;
    }

    if (!data.id) {
      showError({
        title: "Something went wrong...",
        description: "Skin images uploading error: Cannot find patient ID",
      });
    }

    const imagesPayload: FollowUpImage[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const skinImage of skinImages) {
      const fileName = generateImageFilename(skinImage.image as Blob, data.id);

      const presignedUrl = await HoneydewAPI.followUps.getPresignedUrl(
        fileName
      );

      const skinImageType = skinImageTypes.find((type) =>
        type.images.some((image) => image.side === skinImage.side)
      );

      const uploadedS3Image = await axios.put(presignedUrl, skinImage.image, {
        headers: {
          "Content-Type": skinImage.image?.type as string,
        },
      });
      if (
        uploadedS3Image?.status === 200 &&
        uploadedS3Image?.config?.data.size > 0
      ) {
        imagesPayload.push({
          id: v4(),
          fileName,
          side: skinImage.side,
          type: skinImageType?.value,
          description: skinImage?.description,
        });
      } else {
        showError({
          title: "Something went wrong...",
          description: "Image size is 0 or undefined. Please try again.",
        });
        throw new Error("Image size is 0 or undefined.");
        return;
      }
    }
    const baseUrl = ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN;
    for (let i = 0; i < imagesPayload.length; i++) {
      const imageUrl = `${baseUrl}/${imagesPayload[i].fileName}`;
      // eslint-disable-next-line no-await-in-loop
      const imageSize = await getImageSize(imageUrl);
      if (imageSize <= 0) {
        showError({
          title: "Something went wrong...",
          description: `Image size for ${imagesPayload[i].fileName} is 0 or undefined. Please try again.`,
        });
        return;
      }
    }
    const payload = JSON.parse(localStorage.getItem("payload")) || {};
    if (payload && payload?.sex) {
      const response: any = await submitMedicalBackground({
        patientId: activePatientId,
        payload,
      });
      if (response?.data && response.data?.data) {
        const result: any = await submitFollowUp({
          patientId: data.id,
          images: imagesPayload,
        });
      } else if (response.error || response.data.errors) {
        showError({
          title: "Something went wrong...",
          description:
            "Unable to submit your medical background. Please, try again later",
        });
        throw new Error("Unable to submit medical background.");
      }
    } else if (patientSkinType) {
      await submitFollowUp({
        patientId: data.id,
        images: imagesPayload,
      });
    }
  }

  function setImage(image: ImageItem, index: number) {
    skinImages[index].image = image;
    setSkinImages(Array.from(skinImages));
  }

  function handleSkinTypeChange(selectedOptions: SkinImageType[]) {
    setSelectedSkinTypes(selectedOptions.map((option) => option.value));

    if (selectedSkinTypes.length < selectedOptions.length) {
      const latestSelectedOption = selectedOptions[selectedOptions.length - 1];
      setSkinImages((skinSelectedImages) => [
        ...skinSelectedImages,
        ...(latestSelectedOption?.images || []),
      ]);
    } else if (selectedOptions.length > 0) {
      const removedOption = selectedSkinTypes.filter(
        (prevOption) =>
          !selectedOptions.some((newOption) => newOption.value === prevOption)
      );
      const keptImages = skinImages.filter(
        (image) => image.group !== removedOption[0]
      );

      setSkinImages(keptImages);
    } else {
      setSkinImages([]);
    }
  }

  const removeImage = (side: string) => {
    const newImages = skinImages.filter((image) => image.side !== side);
    setSkinImages(newImages);
  };

  return (
    <div className="survey-question survey-page__skin-images-wrap">
      <p className="survey-question__title">Last step! Let's see your skin.</p>
      <p className="survey-question__descr paragraph-font--color">
        Photos help your Honeydew Provider diagnose your skin and track your
        progress. Take or upload photos of your skin below.
      </p>
      <p className="survey-page__input-title">AFFECTED skin area(s):</p>
      <StyledSelect
        isMulti
        id="skinImage"
        name="skinImage"
        options={skinImageTypes}
        onChange={handleSkinTypeChange}
        value={skinImageTypes.filter((option) =>
          selectedSkinTypes.includes(option.value)
        )}
        isClearable={false}
        styles={{
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: "150px", // Set the maximum width for the tags
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "#FFFFFF",
            padding: "6px",
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "#FFFFFF",
          }),
          control: (provided, state) => ({
            ...provided,
            outline: "none", // Remove the blue border on focus
            boxShadow: state.isFocused
              ? "0 0 0 2px lightgrey"
              : provided.boxShadow,
            borderColor: state.isFocused ? "lightgrey" : provided.borderColor,
            borderRadius: "80px",
            padding: "6px",
          }),
        }}
      />
      <p className="survey-page__input-text">
        Select the key area(s) you’d like your provider to review
      </p>
      <div className="survey-page__skin-images">
        {skinImages.map(({ title, side, description, image }, index) => (
          <div>
            <ImageUploadTile
              key={side}
              setImage={(imageBlob) => setImage(imageBlob, index)}
              side={side}
              sidePlaceholder={title}
              index={index}
              value={image}
              removeImage={() => removeImage(side)}
            />
            <div className="survey-page__skin-images__description">
              <p>{description}</p>
            </div>
          </div>
        ))}
      </div>
      <Grid
        container
        spacing={2}
        marginTop="16px"
        flexDirection={isMobile ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md="auto">
          <Button
            text="Back"
            view="secondary"
            fullWidth={isMobile}
            onClick={onBack}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Button
            text="submit images"
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await submit();
              } catch (e) {
                console.log(e);
              } finally {
                setIsSubmitting(false);
              }
            }}
            isLoading={isSubmitting}
            fullWidth={isMobile}
            disabled={
              skinImages.some(({ image }) => !image) ||
              isLoading ||
              isSubmitting ||
              skinImages.length === 0
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
