import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";
import { Lab } from "../../../types/Entities/Lab";

export const updateLabOrder = async ({
  taskId,
  placeOfService,
  selectedPaymentMethod,
  paymentMethod,
  charges,
  customerId,
  lab,
  document,
}: {
  taskId: string;
  placeOfService?: string;
  selectedPaymentMethod?: string;
  paymentMethod?: string;
  charges?: { amount: number; description: string }[];
  customerId?: string;
  lab?: Partial<Lab>;
  document?: { name: string; url: string };
}): Promise<any> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<GraphQLResponse<any>>(
      `${ENV_CONFIG.LAB_SERVICE_GRAPHQL_URL}`,
      {
        query: `
          mutation ($payload: UpdateLabOrder!) {
            updateLabOrder(payload: $payload) { nothing }
          }
        `,
        variables: {
          payload: {
            taskId,
            placeOfService,
            selectedPaymentMethod,
            paymentMethod,
            charges,
            customerId,
            lab,
            document,
          },
        },
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data;
  } catch (error: any) {
    logError("Error occurred. Cannot update lab order", error);
    throw error;
  }
};
