import { Box, styled, Typography } from "@mui/material";

export const WarningBanner = styled(Box)(() => ({
  padding: "8px",
  backgroundColor: "#FFF7E5",
  borderRadius: "24px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "fit-content",
  gap: "8px",
}));

export const WarningText = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 400,
}));
