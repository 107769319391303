import { Box, Grid, styled, Typography } from "@mui/material";

export const Container = styled(Grid)({
  borderRadius: "8px",
});

export const Title = styled(Typography)({
  marginBottom: "16px",
  fontSize: "32px",
  fontweight: 500,
  lineHeight: "40px",
});

export const LogoContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

export const BackTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17.6px",
  color: "#583AD6",
  cursor: "pointer",
  alignItems: "center",
  display: "flex",
  gap: "8px",
});

export const CardContainer = styled(Grid)({
  backgroundColor: "#FFFFFF",
  border: "1px solid #F2F4F7",
  borderRadius: "8px",
  padding: "2rem",
});

export const Subtitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "32px",
});
