import React from "react";
import { Accordion, AccordionProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)(() => ({
  border: "1px solid #DFD7FD",
  borderRadius: "8px",
  backgroundColor: "#FBFBFE",
  boxShadow: "none",
  "&:first-of-type": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  "&.MuiAccordionSummary-root": {
    marginBottom: "-20px",
  },
  ".MuiAccordionDetails-root": {
    padding: "0px 16px 16px",
  },
}));

export function CustomAccordion(props: AccordionProps) {
  return <StyledAccordion {...props} />;
}
