import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  getMomentDate,
  getLocalDateNonMilitary,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import { ReactComponent as NoShowIcon } from "../../../../Assets/NewIcons/eye.svg";
import "./style.scss";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { useGetEmployeesByRoleQuery } from "../../../../features/api/employees";
import { useArchivePatientMutation } from "../../../../features/api/patients";
import {
  Administrator,
  CareCoordinator,
  Provider,
} from "../../../../types/Employee";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { USER_ROLES } from "../../../../types/Main";
import { PinButton } from "../../../NewComponents/Common/PinButton";
import {
  useMarkAsNoShowMutation,
  useConfirmAppointmentMutation,
} from "../../../../features/api/appointments";
import { wait } from "../../../../utils/wait";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { ReactComponent as ActionOptions } from "../../../../Assets/icons/action-options.svg";
import { ReactComponent as ArchiveAppt } from "../../../../Assets/icons/archive-patient.svg";
import { ReactComponent as CancelAppt } from "../../../../Assets/icons/cancel-appt.svg";
import { ReactComponent as ConfirmAppt } from "../../../../Assets/icons/confirm-appt.svg";
import { ReactComponent as ResceduleAppt } from "../../../../Assets/icons/rescedule-appt.svg";
import { ReactComponent as ConfirmedAppt } from "../../../../Assets/icons/confirmed-appt.svg";
import { FullName } from "../../../NewComponents/Common/TableComponents/FullName";
import { BirthDate } from "../../../NewComponents/Common/TableComponents/BirthDate";
import { Actions } from "../../../NewComponents/Common/TableComponents";

function ProviderNames({ providerIds }: { providerIds: string[] }) {
  const employeesQuery = useGetEmployeesByRoleQuery("provider");
  const providers = employeesQuery.data as Provider[] | undefined;
  const names = providers
    ?.filter(({ id }) => providerIds.includes(id))
    .map(({ firstName, lastName, title }) => (
      <p>
        {firstName} {lastName}
        {title ? `, ${title}` : ""}
      </p>
    ));

  // eslint-disable-next-line no-nested-ternary, react/jsx-no-useless-fragment
  return names ? names.length ? <>{names}</> : <p>-</p> : <Skeleton />;
}

function CareCoordinatorNames({
  careCoordinatorIds,
}: {
  careCoordinatorIds: string[];
}) {
  const employeesQuery = useGetEmployeesByRoleQuery("care-coordinator");
  const providers = employeesQuery.data as CareCoordinator[] | undefined;
  const names = providers
    ?.filter(({ id }) => careCoordinatorIds.includes(id))
    .map(({ firstName, lastName }) => (
      <p>
        {firstName} {lastName}
      </p>
    ));

  // eslint-disable-next-line no-nested-ternary, react/jsx-no-useless-fragment
  return names ? names.length ? <>{names}</> : <p>-</p> : <Skeleton />;
}

export const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => <FullName payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } doc['lastNameNumberValue'].value / 100000000.0`,
          },
        },
      },
    ],
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => <BirthDate payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ followUp, appointmentDate }) => {
      const dateOfService =
        followUp?.isInitialFollowUp === false
          ? followUp.timestamp
          : appointmentDate;
      return (
        <p>{dateOfService ? getLocalDateNonMilitary(dateOfService) : "-"}</p>
      );
    },
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "followUp.timestamp",
                },
              },
              {
                match: {
                  "followUp.isInitialFollowUp": false,
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "appointmentDate",
                },
              },
              {
                bool: {
                  should: [
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: "followUp",
                          },
                        },
                      },
                    },
                    {
                      match: {
                        "followUp.isInitialFollowUp": true,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Next Follow-Up",
    get: () => 0,
    getPretty: (payload) => (
      <p>
        {payload.followUp?.nextFollowUpDate
          ? getLocalDateNonMilitary(payload.followUp.nextFollowUpDate)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "followUp.nextFollowUpDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.nextFollowUpDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Provider",
    get: (payload) => "",
    getPretty: (payload) => (
      <ProviderNames
        providerIds={
          payload.careTeam
            ?.filter(({ role }) => role === USER_ROLES.PROVIDER)
            .map(({ employeeId }) => employeeId) || []
        }
      />
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "providerLastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['providerLastNameNumberValue'].value / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Care coord.",
    get: (payload) => "",
    getPretty: (payload) => (
      <CareCoordinatorNames
        careCoordinatorIds={
          payload.careTeam
            ?.filter(({ role }) => role === USER_ROLES.CARE_COORDINATOR)
            .map(({ employeeId }) => employeeId) || []
        }
      />
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "careCoordinatorLastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['careCoordinatorLastNameNumberValue'].value / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload, _, updateTable) => (
      <Actions
        payload={payload}
        updateTable={updateTable}
        employeeType={USER_ROLES.ADMINISTRATOR}
        reload={reload}
      />
    ),
  },
];
