import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { ReactComponent as HomeIcon } from "../../../../../Assets/NewIcons/home.svg";
import { ReactComponent as DocumentIcon } from "../../../../../Assets/icons/documents.svg";
import { ReactComponent as ProfileIcon } from "../../../../../Assets/NewIcons/profile.svg";
import { ReactComponent as WalletIcon } from "../../../../../Assets/NewIcons/wallet.svg";
import { ReactComponent as LogOutIcon } from "../../../../../Assets/NewIcons/log-out.svg";
import { ReactComponent as QuestionMarkIcon } from "../../../../../Assets/NewIcons/question-mark.svg";
import { ReactComponent as PatientsGroupIcon } from "../../../../../Assets/NewIcons/patients-group.svg";
import { MenuItem } from "../types";
import { signOut } from "../../../../../features/session";
import { PopupLayerContext } from "../../PopupLayer";
import { HelpPopupGenerator } from "../../../Patient/HelpPopup";
import { ActiveWrap } from "../active-wrap";
import { clearAllStores } from "../../../../../features/utils/clear-all-stores";
import { useGetMyProfileQuery } from "../../../../../features/api/patients";
import { PatientPickerPopupGenerator } from "../../../Patient/PatientPickerPopup";

interface Props {
  onClose: () => void;
}

export function MenuItemsForPatient({ onClose }: Props) {
  const { closeAllPopups } = useContext(PopupLayerContext);
  const { isLoading, data: patients, isSuccess } = useGetMyProfileQuery(null);
  const { showPopup } = useContext(PopupLayerContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const itemsList: MenuItem[] = [
    {
      icon: HomeIcon,
      text: "Home",
      path: "/",
    },
    // {
    //   icon: RecordsIcon,
    //   text: "Records",
    //   path: "/records",
    // },
    {
      icon: ProfileIcon,
      text: "Profile",
      path: "/profile",
    },
    {
      icon: DocumentIcon,
      text: "Documents",
      path: "/document",
    },
    {
      icon: WalletIcon,
      text: "Billing",
      path: "/billing",
    },
    // {
    //   icon: HeartIcon,
    //   text: "Medical background",
    //   path: "/med-background",
    // },
    {
      icon: QuestionMarkIcon,
      text: "Help",
      onClick: () => {
        showPopup(HelpPopupGenerator());
      },
    },
    {
      icon: PatientsGroupIcon,
      text: "Switch profiles",
      onClick: () => {
        showPopup(PatientPickerPopupGenerator());
      },
      condition: () => isSuccess && patients.length > 1,
    },
    {
      icon: LogOutIcon,
      text: "Log out",
      onClick: () => {
        closeAllPopups();
        dispatch(signOut());
        clearAllStores();
        localStorage.removeItem("currentIndex");
        localStorage.removeItem("patientsTableSorting");
        history.push("/login");
      },
    },
  ];

  return (
    <>
      {itemsList
        .filter((item) => !item.condition || item.condition())
        .map((item) => (
          <li className="menu__list-item">
            <ActiveWrap item={item} onClick={onClose}>
              <div className="menu__circle">
                <item.icon className="menu__item-icon" />
              </div>
              <p>{item.text}</p>
            </ActiveWrap>
          </li>
        ))}
    </>
  );
}
