import { isNaN } from "lodash";

export const parsePrice = (value: number | string): string => {
  const parsedValue = typeof value === "string" ? Number(value) : value;

  if (isNaN(parsedValue)) {
    throw new Error("Invalid input: value is not a valid number");
  }

  return parsedValue.toFixed(2); // Always format to two decimal places
};
