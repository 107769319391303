import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation,
  useParams,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientsPageForCareCoordinator } from "../../NewPages/CareCoordinator/PatientsPage";
import { Footer } from "../../NewComponents/Common/Footer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import PatientFollowUpVisitComponent from "./PatientFollowUpVisit";
import PrintPatientInfoComponent from "./PrintPatientInfoComponent";
import PatientDashboardPageForCareCoordinatorComponent from "./PatientDashboardPageForCareCoordinator";
import { useGetAssignedPatientsQuery } from "../../../features/api/patients";
import { getSessionState } from "../../../features/session";
import { PatientFollowUpVisitMobileScreen } from "../../Pages/Provider/PatientFollowUpVisit/PatientFollowUpVisitMobileScreen";
import { DashboardDataProvider } from "../../../contexts/dashboard-data";

interface Props {
  setMenuVisibility: (isVisible: boolean) => void;
}

function ProtectedRoutesCare({ setMenuVisibility }: Props) {
  const [isAuthorised, setIsAuthorised] = useState(true);
  const [paramsId, setParamsId] = useState(null);
  const { userId } = useSelector(getSessionState);

  const careTeam = useGetAssignedPatientsQuery(userId);

  useEffect(() => {
    if (careTeam.status === "fulfilled" && paramsId) {
      const isExist = careTeam?.data.some((obj) => obj.patientId === paramsId);
      setIsAuthorised(isExist);
    }
  }, [careTeam, paramsId]); // Added paramsId to the dependency array

  return (
    <Switch>
      <Route path="/print/:userId">
        <PrintPatientInfoComponent
          setMenuVisibility={setMenuVisibility}
          isAuthorised={isAuthorised}
          setParamsId={setParamsId}
        />
      </Route>
      <Route path="/:userId/visit">
        <div className="main-area">
          <div className="main-area follow-up-visit__mobile">
            <PatientFollowUpVisitMobileScreen />
          </div>

          <div className="main-area follow-up-visit__web">
            <PatientFollowUpVisitComponent
              isAuthorised={isAuthorised}
              setParamsId={setParamsId}
            />
          </div>
        </div>
      </Route>
      <Route path="/:userId">
        <div className="main-area">
          <PatientDashboardPageForCareCoordinatorComponent
            isAuthorised={isAuthorised}
            setParamsId={setParamsId}
          />
        </div>
      </Route>
      <Route path="/">
        <DashboardDataProvider>
          <div className="main-area">
            <PatientsPageForCareCoordinator />
            <ChatWidget />
            <Footer />
          </div>
        </DashboardDataProvider>
      </Route>
    </Switch>
  );
}

export default ProtectedRoutesCare;
