import React, { FormEvent, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { signIn } from "../../../../features/session";
import {
  show as showErrorNotification,
  hide as hideErrorNotifcation,
} from "../../../../features/errorNotification";
import { ForgotPassword } from "../../../Components/Common/ForgotPassword";
import { PasswordReset } from "../../../Components/Common/PasswordReset";
import "./style.scss";
import { logError } from "../../../../shared/logger";
import { AuthenticationService } from "../../../../services/cognito";
import { SubmitButton } from "../../../NewComponents/Common/Button";
import { TextInput } from "../../../NewComponents/Common/TextInput";
import { setOldPasswordExternally } from "../../../NewComponents/Common/ChangePasswordPopup";
import { SimplePageLayout } from "../../../NewComponents/Common/SimplePageLayout";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { useAddNewPatientToAccountMutation } from "../../../../features/api/patients";
import { NewPassword } from "../../../Components/Common/NewPassword";
import { checkIsAccutaneSource } from "../../../../utils/patient/isAccutaneSource";
import { growthbook } from "../../../../App";

const views = {
  login: "login",
  forgotPassword: "forgorPassword",
  verificationCode: "verificationCode",
  passwordReset: "passwordReset",
  newPassword: "newPassword",
};

export function LogInPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const verificationCode = searchParams.get("verificationCode");
  const username = searchParams.get("username");
  const dispatch = useDispatch();
  const [createPatientInAccount, { isSuccess }] =
    useAddNewPatientToAccountMutation();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [newPasswordData, setNewPasswordData] = useState<any>(null);
  const [email, setEmail] = useState(username || "");
  const [password, setPassword] = useState("");
  const [submitInProgress, setSubmitState] = useState(false);
  const [view, setView] = useState(
    verificationCode && email ? views.passwordReset : views.login
  );
  const location = useLocation();
  const history = useHistory();
  const submitButtonStatus =
    // eslint-disable-next-line no-nested-ternary
    !email.length || !password.length
      ? "disabled"
      : submitInProgress
      ? "loading"
      : undefined;

  const goToRegistrationHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push("/registration");
  };

  const submit = async (event: FormEvent) => {
    dispatch(hideErrorNotifcation());
    setSubmitState(true);
    event.preventDefault();

    try {
      const result = await AuthenticationService.signIn({
        email,
        password,
        onNewPasswordChallenge: (userAttr, user) => {
          setNewPasswordData({
            userAttr,
            user,
          });
          setView(views.newPassword);
        },
      });
      dispatch(signIn(result));
      console.log("init", result);
      if (window.sessionStorage.getItem("patientInfoMultiAcc")) {
        const newPatientData = JSON.parse(
          window.sessionStorage.getItem("patientInfoMultiAcc") as string
        );
        window.sessionStorage.removeItem("patientInfoMultiAcc");
        await createPatientInAccount({
          patient: newPatientData,
        });
        showSuccess({
          title: "You've successfuly added new patient to your account!",
          description: "New patient will be accessible in your application",
        });
      }
      setOldPasswordExternally(password);
      window.location.href = (location.state as any)?.targetPath || "/";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(signIn(null));
      showError({
        title: "Something went wrong",
        description: e.message,
      });
      setSubmitState(false);
    }
  };

  const showForgotPasswordForm = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setView(views.forgotPassword);
  };

  useEffect(() => {
    checkIsAccutaneSource(location, history);
  }, [location, history]);

  const getView = () => {
    switch (view) {
      case views.login:
        return (
          <>
            <p className="logInContainer__title">Welcome back</p>
            <p className="logInContainer__description">
              Please enter your details to log in
            </p>
            <form className="logInContainer__form" onSubmit={submit}>
              <div className="logInContainer__field-block">
                <TextInput
                  disabled={submitInProgress}
                  id="email"
                  name="email"
                  onChange={(value) => setEmail(value.trim())}
                  placeholder="Enter your email"
                  required
                  type="text"
                />
              </div>
              <div className="logInContainer__field-block">
                <TextInput
                  disabled={submitInProgress}
                  id="password"
                  name="password"
                  onChange={(value) => setPassword(value)}
                  placeholder="Enter your password"
                  required
                  type="password"
                />
              </div>
              <a
                href="#"
                className="logInContainer__text logInContainer__text--link"
                onClick={showForgotPasswordForm}
              >
                Forgot password?
              </a>
              <SubmitButton
                text="Log in"
                status={submitButtonStatus}
                className="logInContainer__log-in-button"
              />
            </form>
            <p className="logInContainer__signup">
              <span className="logInContainer__text">
                Don't have an account?
              </span>
              <a
                href="#"
                className="logInContainer__text logInContainer__text--link logInContainer__signup__sign-up-link"
                onClick={goToRegistrationHandler}
              >
                Sign Up
              </a>
            </p>
          </>
        );
      case views.forgotPassword:
        return <ForgotPassword />;
      case views.passwordReset:
        if (!verificationCode) {
          logError(
            "Cannot render password reset form: no verification code found"
          );
          return null;
        }
        return (
          <PasswordReset verificationCode={verificationCode} username={email} />
        );
      case views.newPassword:
        return (
          <NewPassword
            userAttr={newPasswordData.userAttr}
            user={newPasswordData.user}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="app-login">
      <SimplePageLayout
        onBack={view !== views.login ? () => setView(views.login) : undefined}
      >
        <div className="logInContainer">{getView()}</div>
      </SimplePageLayout>
    </div>
  );
}
