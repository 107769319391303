import React from "react";
import { LinearProgress, Box, useTheme } from "@mui/material";

export function ProgressBar({ progress }: { progress: number }) {
  const theme = useTheme();

  return (
    <Box sx={{ position: "absolute", top: 0, right: 0, left: 0 }} width="100%">
      <LinearProgress
        sx={{
          height: "8px",
          backgroundColor: theme.palette.common.subtleGrey,
          "& .MuiLinearProgress-bar": {
            backgroundColor: theme.palette.common.fernGreen,
          },
        }}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
