import {
  Conversation,
  JSONArray,
  JSONObject,
  Message,
} from "@twilio/conversations";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSessionState } from "../../../../../features/session";
import {
  ChatParticipantsRemap,
  ConversationAttributes,
  ConversationParticipant,
} from "../../../../../types/Chat";
import { getChatDatePretty } from "../../../../../utils/get-date-pretty";
import { Button } from "../../Button";
import "./style.scss";
import { PopupLayerContext } from "../../PopupLayer";
import DocumentOverviewPopupGenerator from "../../../Provider/DocumentOverviewPopup";
import { AccutaneDocument } from "../../../../../services/acneaway-api/entities/accutane/get-documents";
import {
  getDocumentTasks,
  setDocumentsTasks,
  setProviderTasks,
} from "../../../../../features/providers";
import {
  setCareCoordinatorTasks,
  setDocumentTasks,
} from "../../../../../features/careCoordinators";
import ChatMessagePopover from "../../ChatMessagePopover";
import { useGetEmployeeQuery } from "../../../../../features/api/employees";
import { useGetPatientByIdQuery } from "../../../../../features/api/patients";
import { USER_ROLES } from "../../../../../types/Main";
import { Skeleton } from "../../Skeleton";
import { HoneydewAPI } from "../../../../../services/honeydew-api";

function getFriendlyRole(role: string) {
  switch (role) {
    case USER_ROLES.PROVIDER:
      return "Provider";
    case USER_ROLES.CARE_COORDINATOR:
      return "Care Coordinator";
    case USER_ROLES.ADMINISTRATOR:
      return "Administrator";
    case USER_ROLES.PATIENT:
      return "Patient";
    default:
      return role;
  }
}

function getUpdatersByRole(role: string | null) {
  switch (role) {
    case "patients": {
      return {
        tasksSetter: () => [],
        documentsSetter: () => [],
        documentsGetter: () => [],
        disableFeatures: true,
      };
    }
    case "providers": {
      return {
        tasksSetter: setProviderTasks,
        documentsSetter: setDocumentsTasks,
        documentsGetter: getDocumentTasks,
      };
    }
    case "care-coordinators":
    case "admins": {
      return {
        tasksSetter: setCareCoordinatorTasks,
        documentsSetter: setDocumentTasks,
        documentsGetter: getDocumentTasks,
      };
    }
    default: {
      return null;
    }
  }
}

const BOT_PARTICIPANT = {
  identity: "Honeydew",
  userId: "",
  role: "bot",
  userFullName: "Honeydew",
  identityTypeFriendly: "",
};

type Participant = {
  fullName: string;
  role: string;
  identity: string;
};

interface Props {
  messages: Message[];
  conversation: Conversation;
  onMessageDelete: (messageId: string) => void;
  patientId: string;
}

enum MESSAGE_OWNERS {
  ME = "me",
  PROVIDER = "provider",
  CARE_COORDINATOR = "care-coordinator",
  ADMINISTRATOR = "administrator",
  PATIENT = "patient",
  BOT = "bot",
}

interface MediaMessage extends Message {
  attributes: {
    attachment: {
      data: string | any;
      type: string;
    };
  };
}

interface ChatMessageProps {
  currentIdentity: string;
  index: number;
  renderSystemMessage: (
    message: MediaMessage,
    messageType: string
  ) => JSX.Element | null;
  onMessageDelete: (messageId: string) => void;
  onIdentityChange: (newIdentity: string) => void;
  message: Message;
  messages: Message[];
  userRole: string | null;
  identity: string | null;
  oldParticipantsRemap?: ChatParticipantsRemap;
}

function getMessageOwnerClass(participant: Participant, identity: string) {
  let owner;
  if (identity === participant.identity) owner = MESSAGE_OWNERS.ME;
  else {
    switch (participant.role) {
      case "provider":
        owner = MESSAGE_OWNERS.PROVIDER;
        break;
      case "care-coordinator":
        owner = MESSAGE_OWNERS.CARE_COORDINATOR;
        break;
      case "administrator":
        owner = MESSAGE_OWNERS.ADMINISTRATOR;
        break;
      case "patient":
        owner = MESSAGE_OWNERS.PATIENT;
        break;
      default:
        owner = MESSAGE_OWNERS.BOT;
        break;
    }
  }

  return owner ? `chat-channel__message--${owner}` : "";
}

function getIdentity(
  author: string,
  oldParticipantsRemap?: ChatParticipantsRemap
) {
  return oldParticipantsRemap?.[author]?.id || author;
}

function ChatMessage({
  currentIdentity,
  index,
  renderSystemMessage,
  onIdentityChange,
  message,
  messages,
  userRole,
  identity,
  onMessageDelete,
  oldParticipantsRemap,
}: ChatMessageProps) {
  const [shouldShowPopover, setVisible] = useState(false);
  const [participant, setParticipant] = useState<Participant>();
  const employeeQuery = useGetEmployeeQuery(
    getIdentity(message.author as string, oldParticipantsRemap),
    {
      skip: message.author === "bot",
    }
  );
  const patientQuery = useGetPatientByIdQuery(
    getIdentity(message.author as string, oldParticipantsRemap),
    {
      skip: message.author === "bot",
    }
  );

  useEffect(() => {
    if (message.author === "bot") {
      setParticipant({
        fullName: BOT_PARTICIPANT.userFullName,
        role: BOT_PARTICIPANT.role,
        identity: message.author,
      });
      return;
    }
    if (patientQuery.isSuccess) {
      setParticipant({
        fullName: patientQuery.data.fullName,
        role: "patient",
        identity: patientQuery.data.patientId,
      });
      return;
    }

    if (employeeQuery.isSuccess) {
      setParticipant({
        fullName: `${employeeQuery.data.firstName} ${
          employeeQuery.data.lastName
        }${
          employeeQuery.data.role === USER_ROLES.PROVIDER
            ? `, ${employeeQuery.data.title}`
            : ""
        }`,
        role: employeeQuery.data.role,
        identity: employeeQuery.data.id,
      });
    }
  }, [patientQuery, employeeQuery]);
  const currentMessage: MediaMessage = message as MediaMessage;
  const nextMessage = messages[index + 1];
  const isFirstInGroup = currentMessage.author !== currentIdentity;
  const isLastInGroup = nextMessage?.author !== currentMessage.author;
  onIdentityChange(currentMessage.author as string);

  const onClosePopover = () => setVisible(false);

  return (
    <div
      className={`chat-channel__message ${
        participant ? getMessageOwnerClass(participant, identity ?? "") : null
      } ${isFirstInGroup ? "chat-channel__message--first" : ""} ${
        isLastInGroup ? "chat-channel__message--last" : ""
      }`}
    >
      <p className="chat-channel__title">
        {participant ? (
          <>
            {" "}
            {participant.fullName}{" "}
            <span>{`${
              participant.role && participant.role !== "bot"
                ? `(${getFriendlyRole(participant.role)})`
                : ""
            }`}</span>
            {currentMessage.author === "bot" && (
              <span className="chat-channel__title__app-sign">App</span>
            )}
          </>
        ) : (
          <Skeleton />
        )}
      </p>
      <i
        className="chat-channel__text"
        onContextMenu={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        {currentMessage?.attributes &&
        currentMessage?.attributes?.attachment?.type
          ? renderSystemMessage(
              currentMessage,
              currentMessage.attributes.attachment.type
            )
          : currentMessage.text}
        {userRole === "admins" && shouldShowPopover && (
          <ChatMessagePopover
            messageId={currentMessage.messageId}
            conversationId="12"
            onMessageDelete={onMessageDelete}
            onClose={onClosePopover}
          />
        )}
      </i>
      <i className="chat-channel__time">
        {getChatDatePretty(currentMessage?.timestamp as Date)}
      </i>
    </div>
  );
}

export function ChatChannel({
  messages,
  conversation,
  onMessageDelete,
  patientId,
}: Props) {
  const { showPopup } = useContext(PopupLayerContext);
  const { userId, userRole, activePatientId } = useSelector(getSessionState);
  const [isDutyTime, setIsDutyTime] = useState(true);

  useEffect(() => {
    const isOutsideOperatingHours = () => {
      const now = new Date();
      const options = {
        timeZone: "America/New_York",
        hour: "2-digit",
        hour12: false,
      };
      const currentHourET = parseInt(
        new Intl.DateTimeFormat("en-US", options).format(now),
        10
      );
      return currentHourET < 9 || currentHourET >= 22;
    };

    const updateDutyTime = () => {
      const isNotOnDuty = isOutsideOperatingHours();
      setIsDutyTime(!isNotOnDuty);
    };
    const interval = setInterval(updateDutyTime, 30000);
    updateDutyTime();
    return () => clearInterval(interval);
  }, []);

  function createMarkup(body: string) {
    return {
      __html: body,
    };
  }

  function renderSystemMessage(message: MediaMessage, messageType: string) {
    const documentFromMessage: AccutaneDocument =
      message.attributes?.attachment.data;

    const onViewDocumentClick = () => {
      if (userId && activePatientId && userRole !== "patients") {
        showPopup(
          DocumentOverviewPopupGenerator(
            documentFromMessage.title,
            activePatientId,
            documentFromMessage.id,
            documentFromMessage.url,
            userId
          )
        );
      } else if (userRole === "patients") {
        showPopup(
          DocumentOverviewPopupGenerator(
            documentFromMessage.title,
            activePatientId,
            documentFromMessage.id,
            documentFromMessage.url,
            userId,
            true
          )
        );
      }
    };

    switch (messageType) {
      case "pdf":
        return (
          <div className="chat-message__message chat-message__message--system">
            <p className="chat-message__body">{`A file titled "${
              message.attributes.attachment?.data?.title
            }.${message.attributes.attachment?.data?.url
              .split(".")
              .pop()}" was uploaded`}</p>
            <Button
              size="small"
              view="secondary"
              className="chat-channel__attachment"
              text="View document"
              onClick={onViewDocumentClick}
            />
          </div>
        );
      case "text":
        return (
          <div className="chat-channel__message chat-channel__message--system">
            <p
              className="chat-channel__message-body"
              dangerouslySetInnerHTML={createMarkup(message.text as string)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  let currentIdentity = "";

  function onIdentityChange(newIdentity: string) {
    currentIdentity = newIdentity;
  }

  function renderMessages() {
    const oldParticipantsRemap = (conversation?.attributes as JSONObject)
      ?.participantsRemap as ChatParticipantsRemap | undefined;
    return messages.map((message, index) => (
      <ChatMessage
        currentIdentity={currentIdentity}
        index={index}
        renderSystemMessage={renderSystemMessage}
        onIdentityChange={onIdentityChange}
        message={message}
        messages={messages}
        identity={userRole === "patients" ? activePatientId : userId}
        userRole={userRole}
        onMessageDelete={onMessageDelete}
        oldParticipantsRemap={oldParticipantsRemap}
      />
    ));
  }

  return (
    <div className="chat-channel">
      {renderMessages()}
      {userRole === "patients" && !isDutyTime && (
        <div className="chat-channel__alert-msg">
          Heads up!
          <br /> Our operating hours are daily 9am-10pm ET, so you might receive
          a delayed response.
        </div>
      )}
    </div>
  );
}
