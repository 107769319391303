import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as ChevronIcon } from "../../../Assets/NewIcons/chevron.svg";
import { ReactComponent as CloseIcon } from "../../../Assets/NewIcons/button-cancel.svg";
import "./style.scss";
import { Skeleton } from "../Common/Skeleton";
import { PopupLayerContext } from "../Common/PopupLayer";
import { ActionPopupGenerator } from "../Common/ActionPopup";
import {
  useConfirmPregnancyPreventionMutation,
  useGetAccutaneByPatientIdQuery,
  useAddBloodWorkMutation,
  useRemovePatientTaskMutation,
} from "../../../features/api/accutane";
import { getSessionState } from "../../../features/session";
import { NotificationLayerContext } from "../Common/NotificationLayer";
import { HoneydewAPI } from "../../../services/honeydew-api";
import { AcneAwayAPI } from "../../../services/acneaway-api";
import { useSetFlagMutation } from "../../../features/api/patients";
import { StatusBadge } from "../Common/Material/StatusBadge";
import { TaskHistory } from "../../../types/TaskHistory";

interface ExpandableItemProps {
  label?: string;
  description?: string | JSX.Element;
  icon?: boolean;
  id?: string;
  status?: string;
  histories?: TaskHistory[];
  action?: () => void;
}

interface ExpandableInfoProps {
  title: string;
  items: ExpandableItemProps[];
  extra?: JSX.Element;
  loading?: boolean;
}

function ExpandableItem({
  label,
  description,
  icon,
  id,
  status,
  histories,
  action,
}: ExpandableItemProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { showPopup } = useContext(PopupLayerContext);
  const [confirmPregnancyPrevention] = useConfirmPregnancyPreventionMutation();
  const [removePatientTask] = useRemovePatientTaskMutation();
  const { activePatientId } = useSelector(getSessionState);
  const [addBloodWork] = useAddBloodWorkMutation();
  const [setFlag] = useSetFlagMutation();

  const accutane = useGetAccutaneByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const handleSuccess = async () => {
    await showSuccess({
      title: "Success",
      description: "Task removed successfully",
    });
  };

  const handleError = async () => {
    await showError({
      title: "Error",
      description: "Error occurred when trying to delete task",
    });
  };

  const updateFlagForTask = async (fieldName: string) => {
    const response: any = await setFlag({
      patientId: activePatientId,
      flag: fieldName,
      state: true,
    });
    if (response.data) handleSuccess();
    else handleError();
  };

  const taskHandlers = {
    governmentIssuedIdAction: () =>
      updateFlagForTask("isRemovedGovernmentIssuedId"),
    insuranceInfoAction: () => updateFlagForTask("isRemovedInsuranceInfo"),
    initialVisitAction: () => updateFlagForTask("isRemovedInitialVisit"),
    medicalBackgroundAction: () =>
      updateFlagForTask("isRemovedMedicalBackground"),
    initiateMembershipAction: () =>
      updateFlagForTask("isRemovedInitiateMembership"),
    scheduleCallWithProviderAction: () =>
      updateFlagForTask("isRemovedScheduleCallWithProvider"),
    providerResponseToFollowUpAction: () =>
      updateFlagForTask("isRemovedProviderResponseToFollowUp"),
    newFollowUpVisitAction: () =>
      updateFlagForTask("isRemovedNewFollowUpVisit"),
    initiateAccutaneAction: () =>
      updateFlagForTask("isRemovedInitiateAccutane"),
    renewMembershipAction: () => updateFlagForTask("isRemovedRenewMembership"),
    formOfBirthControlAction: async () => {
      const result = await confirmPregnancyPrevention({
        patientId: activePatientId,
        primaryForm: accutane.data?.birthControl?.primaryForm,
        secondaryForm: accutane.data?.birthControl?.secondaryForm,
      });
      if (result.error) await handleError();
      else await handleSuccess();
    },
    completePregnancyTestAction: async () => {
      const confirmPregnancyResult =
        await AcneAwayAPI.patients.addPregnancyLabTest(activePatientId);
      if (confirmPregnancyResult.error) await handleError();
      else await handleSuccess();
    },
    signIpledgeConsent: async () => {
      const result = await removePatientTask({
        patientId: activePatientId,
        taskName: "markIpledgeConsentAsSigned",
        flagName: null,
      });
      if (result.error) await handleError();
      else await handleSuccess();
    },
    getBloodworkDoneAction: async () => {
      const confirmBloodworkResult = await addBloodWork(activePatientId);
      if (confirmBloodworkResult.error) await handleError();
      else await handleSuccess();
    },
  };

  const removeTaskById = async (taskId: string) => {
    const taskHandler = taskHandlers[taskId];
    if (taskHandler) {
      console.log("result in switch", id);
      await taskHandler();
    } else {
      console.log("result in default", id);
    }
  };
  const removeTaskFromPatientDashboard = () => {
    // call separate apis for separate tasks depending on the click item id
    console.log("id in remove", id);
    removeTaskById(id);
  };

  return (
    <>
      {label && (
        <Grid container flexDirection="row" alignItems="center" gap={2}>
          <Grid item>
            <Typography
              variant="body1"
              className={`expandable-info__content__item__label ${
                description
                  ? ""
                  : "expandable-info__content__item__label--no-description"
              }`}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item>
            {status && <StatusBadge status={status} histories={histories} />}
          </Grid>
        </Grid>
      )}
      {description && (
        <p
          className={`${
            label?.length
              ? "expandable-info__content__item__description"
              : "expandable-info__content__item__description-only"
          }`}
        >
          {description}
        </p>
      )}
      {icon && (
        <p
          onClick={(e) => {
            e.stopPropagation();
            if (action) {
              action();
            } else
              showPopup(
                ActionPopupGenerator({
                  title: `Cancel task: ${label}`,
                  text: `Note: This will remove the task from the assignees dashboard. This action cannot be reversed`,
                  action: () => {
                    removeTaskFromPatientDashboard();
                  },
                  actionText: "Confirm",
                })
              );
          }}
        >
          <div className="close-icon">
            <CloseIcon />
          </div>
        </p>
      )}
    </>
  );
}

function ExpandableInfo({ title, items, extra, loading }: ExpandableInfoProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  console.log(items);

  return (
    <div className="expandable-info">
      <p className="expandable-info__header" onClick={toggleExpanded}>
        <span className="expandable-info__header__title">
          {loading ? <Skeleton fullWidth flex /> : title}
        </span>
        {loading ? null : (
          <ChevronIcon
            className={expanded ? "expandable-info__header--opened" : ""}
          />
        )}
        {extra && !loading && (
          <div className="expandable-info__header__extra">{extra}</div>
        )}
      </p>
      {expanded && (
        <div className="expandable-info__content">
          {items.map((it) => (
            <p className="expandable-info__content__item">
              {loading ? (
                <Skeleton fullWidth flex />
              ) : (
                <ExpandableItem
                  label={it.label}
                  description={it.description}
                  icon={it.showRemoveIcon}
                  id={it.key}
                  status={it.status}
                  action={it.action}
                  histories={it.histories}
                />
              )}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default ExpandableInfo;
