import React from "react";
import { WarningBanner, WarningText } from "./styles";
import { ReactComponent as WarningIcon } from "../../../../Assets/NewIcons/warning-rounded.svg";

export function FastingBanner() {
  return (
    <WarningBanner>
      <WarningIcon height="20px" />
      <WarningText>
        Reminder to fast 12 hours before your test. Drinking water is okay.
      </WarningText>
    </WarningBanner>
  );
}
