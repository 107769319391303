import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    text: {
      secondary: "#6C6A7C",
    },
    primary: {
      main: "#5e39f5",
    },
    common: {
      green: "#4E9047",
      fernGreen: "#60B257",
      subtleGrey: "#F2F4F7",
    },
  },
  typography: {
    fontFamily: "Cera Pro, Arial, sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: "-0.02em",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: "-0.01em",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: "0",
    },
    h4: {
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.43,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontSize: "0.75rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#6C6A7C",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 300,
      textTransform: "uppercase",
      lineHeight: 2.5,
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#60B257",
          },
        },
      },
    },
  },
});
