import React, { useContext, useEffect, useState } from "react";
import {
  getLocalDateNonMilitary,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import "./style.scss";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { PinButton } from "../../../NewComponents/Common/PinButton";
import {
  FullName,
  BirthDate,
  Actions,
} from "../../../NewComponents/Common/TableComponents";
import { USER_ROLES } from "../../../../types/Main";

export const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => <FullName payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['lastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => <BirthDate payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ followUp }) => (
      <p>
        {followUp?.timestamp
          ? getLocalDateNonMilitary(followUp.timestamp)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "followUp.timestamp",
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Next Follow-Up",
    get: () => 0,
    getPretty: (payload) => (
      <p>
        {payload.followUp?.nextFollowUpDate
          ? getLocalDateNonMilitary(payload.followUp.nextFollowUpDate)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "followUp.nextFollowUpDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.nextFollowUpDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload, __, updateTable) => (
      <Actions
        payload={payload}
        reload={reload}
        updateTable={updateTable}
        employeeType={USER_ROLES.CARE_COORDINATOR}
      />
      // <div className="patients-table__actions">
      //  {payload.subscription?.membership ||
      //  ((payload.appointmentStatus === "PENDING" ||
      //    payload.appointmentStatus === "CONFIRMED") &&
      //    (!payload.followUp || payload.followUp.isInitialFollowUp)) ? (
      //    <PinButton employeeType={USER_ROLES.CARE_COORDINATOR} updateTable={updateTable} payload={payload} />
      //  ) : null}
      // </div>
    ),
  },
];
