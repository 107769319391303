import React, { useContext, useMemo, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { getSteps } from "./steps";
import CustomStepper from "../../../Common/Material/Stepper";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { Button } from "../../../Common/Material/Button";
import { usePopulateBloodSlipPdfMutation } from "../../../../../features/api/accutane";
import { NotificationLayerContext } from "../../../Common/NotificationLayer";
import { updateLabOrder } from "../../../../../services/honeydew-api/labs/update-lab-order";
import { AcneAwayAPI } from "../../../../../services/acneaway-api";
import { useMobile } from "../../../../../hooks/use-mobile";

export default function CheckoutMedicalOffice() {
  const { handleNext, handleBack, pregnancyWork, onClose, updateValues } =
    usePregnancyWorkStepper();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [populatePdf] = usePopulateBloodSlipPdfMutation();
  const [generatingSlip, setGeneratingSlip] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useMobile();
  const inProgress = useMemo(
    () => pregnancyWork.task?.status === "IN_PROGRESS",
    [pregnancyWork]
  );

  const isMigrated = useMemo(
    () => pregnancyWork.task?.status === "ASSIGNED_MIGRATED",
    [pregnancyWork]
  );

  const oldInProgress = useMemo(
    () =>
      pregnancyWork.task?.status === "IN_PROGRESS" &&
      !pregnancyWork.labOrder?.populatedDocumentUrl,
    [pregnancyWork]
  );

  const document = useMemo(
    () =>
      pregnancyWork?.labOrder?.populatedDocumentUrl
        ? {
            name: pregnancyWork.labOrder?.populatedDocumentName,
            url: pregnancyWork.labOrder?.populatedDocumentUrl,
          }
        : undefined,
    [pregnancyWork]
  );

  const handleSubmit = async () => {
    if (inProgress || isMigrated) {
      handleNext();
      return;
    }
    try {
      setGeneratingSlip(true);

      const metadata = JSON.parse(pregnancyWork?.labOrder?.pdfMetadata || "{}");

      const response = await populatePdf(metadata);

      const result: any = await updateLabOrder({
        taskId: pregnancyWork.task?.id,
        placeOfService: pregnancyWork.placeOfService,
        paymentMethod: pregnancyWork.paymentMethod,
        document: response?.data?.document,
      });

      updateValues({
        task: { ...pregnancyWork.task, status: "IN_PROGRESS" },
        labOrder: {
          ...pregnancyWork.labOrder,
          populatedDocumentName: response?.data?.document.name,
          populatedDocumentUrl: response?.data?.document.url,
        },
      });

      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to complete task",
        });
        return;
      }
      showSuccess({
        title: "Task successfully initiated",
        description: "Task has been marked as in progress",
      });
    } catch (e) {
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
    } finally {
      setLoading(false);
    }
  };

  const onComplete = async () => {
    if (!pregnancyWork.patient?.patientId) {
      showError({
        title: "Something went wrong...",
        description: "Patient ID not found",
      });
    }
    try {
      setLoading(true);
      await AcneAwayAPI.patients.addPregnancyLabTest(
        pregnancyWork.patient.patientId
      );
      showSuccess({
        title: "Task successfully completed",
        description: "Task has been marked as completed",
      });
      onClose?.();
    } catch (e) {
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          How to complete your pregnancy test
        </Typography>
        <CustomStepper
          steps={getSteps({
            onComplete,
            requestLabSlip: handleSubmit,
            loading,
            generatingSlip,
            needsFasting: pregnancyWork.needsFasting,
            canComplete: inProgress || isMigrated,
            oldInProgress,
            document,
          })}
          activeStep={inProgress ? 1 : 0}
        />
      </Grid>
      <Grid container gap={2} justifyContent={isMobile ? "center" : "start"}>
        {!inProgress && !isMigrated && (
          <Grid item xs={8} md="auto">
            <Button
              text="Change payment selection"
              size="small"
              view="ghost"
              disabled={loading}
              fullWidth={isMobile}
              onClick={handleBack}
            />
          </Grid>
        )}
        {inProgress && (
          <Grid item xs={8} md="auto">
            <Button
              text="Got it, back to care hub"
              size="small"
              disabled={loading}
              fullWidth={isMobile}
              onClick={handleSubmit}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
