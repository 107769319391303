import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export const pubNubSubscribeKey = async () => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.get<
      GraphQLResponse<{ getPubNubSubscribeKey: string }>
    >(`${ENV_CONFIG.CHATS_SERVICE_GRAPHQL_URL}`, {
      headers: {
        Authorization: token,
      },
      params: {
        query: `query { getPubNubSubscribeKey }`,
      },
    });
    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }
    return result.data.data.getPubNubSubscribeKey;
  } catch (error: any) {
    logError("Error occurred while fetching the PubNub Subscribe Key", error);
    throw error;
  }
};
