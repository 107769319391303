import { styled } from "@mui/material/styles";

export const CustomList = styled("ul")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  listStyleType: "disc",
}));

export const CustomListItem = styled("li")(() => ({
  color: "#6C6A7C",
}));

export const CustomNumericList = styled("ul")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  listStyleType: "number",
  fontSize: "0.75rem",
}));
