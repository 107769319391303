import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";
import { LabPricesResponse } from "../../../types/LabPricesResponse";

export const getLabsPrices = async (
  taskId: string
): Promise<LabPricesResponse[]> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<
      GraphQLResponse<{ getLabPrices: LabPricesResponse[] }>
    >(
      `${ENV_CONFIG.LAB_SERVICE_GRAPHQL_URL}`,
      {
        query: `
          query getLabPrices($taskId: ID!) {
            getLabPrices(taskId: $taskId) {
                testId
                testName
                prices {
                    price
                    testCode
                    labProvider
                    walkInPrice
                    drawFee
                }
            }
          }
        `,
        variables: {
          taskId,
        },
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getLabPrices;
  } catch (error: any) {
    logError("Error occurred. Cannot get lab locations", error);
    throw error;
  }
};
