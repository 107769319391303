import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { growthbook } from "../App";

interface Props {
  children: JSX.Element[];
  session: any;
  userId: string | null;
}

function PrivateRoute({ children, session, userId, ...rest }: Props) {
  const location = useLocation();

  useEffect(() => {
    if (userId) {
      growthbook.init({ streaming: true });
      growthbook.setAttributes({
        id: userId,
      });
    }
  }, [userId]);

  return (
    <Route {...rest}>
      {session ? (
        children
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { targetPath: location.pathname } }}
        />
      )}
    </Route>
  );
}

export default PrivateRoute;
