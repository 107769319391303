import { Accutane } from "../../types/Entities/Accutane";
import { FormsOfPregnancyPopupGenerator } from "../../app/NewComponents/Patient/FormsOfPregnancyPopup";
import { PregnancyTestPopupGenerator } from "../../app/NewComponents/Patient/PregnancyTestPopup";
import isValidDate from "../is-valid-date";
import { getLatestTaskByType } from "../get-latest-task-by-type";

export const getPatientMonthlyTasks = (
  accutane: Accutane | null,
  accutaneTasks
) => {
  const pregnancyTest = getLatestTaskByType(
    accutaneTasks,
    "completePregnancyTest"
  );

  const needPregnancy = [
    "ASSIGNED",
    "ASSIGNED_MIGRATED",
    "IN_PROGRESS",
  ].includes(pregnancyTest?.status);

  localStorage.setItem("pregnancyTask", JSON.stringify(pregnancyTest));

  const tasks = [
    {
      name: "Confirm forms of birth control",
      isCompleted: accutane?.birthControl?.completed,
      key: "formOfBirthControlAction",
      action: FormsOfPregnancyPopupGenerator(),
    },
    {
      name: "Complete pregnancy test",
      isCompleted: !needPregnancy || pregnancyTest?.status === "COMPLETED",
      status: pregnancyTest?.status,
      histories: pregnancyTest?.histories,
      key: "completePregnancyTestAction",
      isLabTask: !isValidDate(accutane?.lastConfirmationDate),
      action: PregnancyTestPopupGenerator(
        !isValidDate(accutane?.lastConfirmationDate)
      ),
    },
  ];

  return tasks;
};
