import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../Common/Button";
import { ReactComponent as Rocket } from "../../../../Assets/NewIcons/rocket.svg";
import { ReactComponent as WomanWithTablets } from "../../../../Assets/NewIcons/woman-with-tablets.svg";
import { ReactComponent as ThinkingWoman } from "../../../../Assets/NewIcons/thinking-woman.svg";
import { ReactComponent as CreditCards } from "../../../../Assets/NewIcons/credit-cards.svg";
import { ReactComponent as AccutaneOnboarding } from "../../../../Assets/NewIcons/accutane-onboarding.svg";
import { ReactComponent as CloseIconFilled } from "../../../../Assets/NewIcons/close-icon-filled.svg";
import { ReactComponent as CheckmarkFilled } from "../../../../Assets/NewIcons/checkmark-filled.svg";
import { ReactComponent as DosAndDonts } from "../../../../Assets/NewIcons/dos-and-donts.svg";
import { InfoBlock } from "../../Common/InfoBlock";
import { HelpPopupTemplate } from "../../Common/PopupLayer/help";
import "./styles.scss";
import {
  getPatientStore,
  storeAccutaneInfo,
} from "../../../../features/patientView";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { Skeleton } from "../../Common/Skeleton";
import { useInitiateAccutaneMutation } from "../../../../features/api/accutane";
import { BasicPopupTemplate } from "../../Common/PopupLayer/basic";
import { useHotReload } from "../../../../contexts/hot-reload";

interface Props {
  onClose: () => void;
}

const stepPictures: Record<number, JSX.Element> = {
  0: <Rocket />,
  1: <WomanWithTablets />,
  2: <ThinkingWoman />,
  3: <DosAndDonts />,
  4: <CreditCards />,
  5: <AccutaneOnboarding />,
};

function AccutaneFirstStep() {
  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[0]}</div>
      <p className="accutane-modal__step-title">
        Get started with Isotretinoin
      </p>
      <p className="accutane-modal__step-text">
        Your provider has added isotretinoin, commonly known as accutane, to
        your treatment plan.
      </p>
    </div>
  );
}

function AccutaneSecondStep() {
  return (
    <div className="accutane-modal__second-step">
      <div className="accutane-modal__step-image">{stepPictures[1]}</div>
      <p className="accutane-modal__step-title">Isotretinoin 101</p>
      <p className="accutane-modal__step-text">
        Isotretinoin, commonly known as Accutane, is an oral retiniod medication
        (a derivative of Vitamin A).
      </p>
      <p className="accutane-modal__step-text">
        It is the most effective medication against chronic or severe acne, with
        long-term results in most patients after about 5-6 months of proper use.
      </p>
    </div>
  );
}

function AccutaneThirdStep() {
  const treatmentPlanText = (
    <span className="accutane-modal__info">
      You can access a more complete resource guide on isotretinoin{" "}
      <a
        href="https://rxaccutane.com/assets/pdf/accutane-medication-guide.pdf"
        target="_blank"
        rel="noreferrer"
        className="accutane-modal__info-link"
      >
        here
      </a>
      .
    </span>
  );

  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[2]}</div>
      <p className="accutane-modal__step-title">What should I expect?</p>
      <p className="accutane-modal__step-description-section">
        <span className="accutane-modal__step-description-section-title accutane-modal__step-description-section__text">
          1. Purging:{" "}
        </span>
        <span className="accutane-modal__step-text">
          Your skin may get worse before it gets better
        </span>
      </p>
      <p className="accutane-modal__step-description-section">
        <span className="accutane-modal__step-description-section-title accutane-modal__step-description-section__text">
          2. Dryness:{" "}
        </span>
        <span className="accutane-modal__step-text accutane-modal__step-description-section__text">
          Dryness can happen not only on your face but throughout your body,
          within the mucous membranes of your lips and eyes as well.
        </span>
      </p>
      <div className="accutane-modal__step-description-section">
        <span className="accutane-modal__step-description-section-title accutane-modal__step-description-section__text">
          3. Monthly iPledge requirements:{" "}
        </span>
        <ul>
          <li className="accutane-modal__step-text accutane-modal__step-description-section--list-item">
            Routine bloodwork
          </li>
          <li className="accutane-modal__step-text accutane-modal__step-description-section--list-item">
            Updates to your clinician
          </li>
          <li className="accutane-modal__step-text accutane-modal__step-description-section--list-item">
            Pregnancy tests and prevention, if applicable
          </li>
        </ul>
      </div>
      <InfoBlock
        view="info"
        text={treatmentPlanText}
        className="accutane-modal__info-block"
      />
    </div>
  );
}

function AccutaneFourthStep() {
  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[3]}</div>
      <p className="accutane-modal__step-title">
        Do’s and Dont’s on Isotretinoin
      </p>
      <div className="accutane-modal__step-description-section accutane-modal__step-description-section__dos">
        <ul>
          <li className="accutane-modal__step-description-section__dos__item">
            <CheckmarkFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Avoid sunlight / UV
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CheckmarkFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Limit alcohol use
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CheckmarkFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Report depressive thoughts
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CheckmarkFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Be careful while driving
            </span>
          </li>
        </ul>
        <ul>
          <li className="accutane-modal__step-description-section__dos__item">
            <CloseIconFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Don’t get pregnant
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CloseIconFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Do not donate blood
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CloseIconFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Do not breastfeed
            </span>
          </li>
          <li className="accutane-modal__step-description-section__dos__item">
            <CloseIconFilled />
            <span className="accutane-modal__step-description-section__dos__text">
              Do not have cosmetic procedures
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

function AccutaneFifthStep() {
  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[4]}</div>
      <p className="accutane-modal__step-title">Pricing</p>
      <p className="accutane-modal__step-text">
        Accutane management is an add-on of{" "}
        <span className="accutane-modal__step-text--bold">$25/month</span>. This
        covers all extra support including iPledge compliance, blood work
        reviews, and symptom management.
      </p>
      <p className="accutane-modal__step-text accutane-modal__step--last-paragraph">
        The medication can be covered by your insurance. If you are paying with
        cash, we’ll help you find the best price.
      </p>
    </div>
  );
}

function AccutaneSixStep() {
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[5]}</div>
      <p className="accutane-modal__step-title">Next steps</p>
      <div className="accutane-modal__step-description-section">
        <ul>
          <li className="accutane-modal__step-description-section__text accutane-modal__step-description-section--list-item">
            Sign iPledge consent forms
          </li>
          {patientQuery.data ? (
            <>
              {" "}
              {patientQuery.data.medicalBackground?.sex === "Female" &&
                !patientQuery.data?.flags?.isNotPregnantCapability && (
                  <>
                    <li className="accutane-modal__step-description-section__text accutane-modal__step-description-section--list-item">
                      Complete pregnancy test
                    </li>
                    <li className="accutane-modal__step-description-section__text accutane-modal__step-description-section--list-item">
                      Confirm your forms of birth control
                    </li>
                  </>
                )}
              {patientQuery.data.medicalBackground?.sex === "Male" && (
                <li className="accutane-modal__step-description-section__text accutane-modal__step-description-section--list-item">
                  Complete baseline bloodwork (a task with instructions will
                  appear on your dashboard)
                </li>
              )}
            </>
          ) : (
            <Skeleton />
          )}
        </ul>
      </div>
    </div>
  );
}

function AccutaneSeventhStep() {
  return (
    <div>
      <div className="accutane-modal__step-image">{stepPictures[4]}</div>
      <p className="accutane-modal__step-title">Add Payment Method</p>
      <p className="accutane-modal__step-text">
        Oops! Looks like there is some issue with your current Card{" "}
      </p>
      <p className="accutane-modal__step-text accutane-modal__step--last-paragraph">
        Would you like to add another payment method?
      </p>
    </div>
  );
}

const viewForStep = (currentStep: number) => {
  switch (currentStep) {
    case 0: {
      return <AccutaneFirstStep />;
    }
    case 1: {
      return <AccutaneSecondStep />;
    }
    case 2: {
      return <AccutaneThirdStep />;
    }
    case 3: {
      return <AccutaneFourthStep />;
    }
    case 4: {
      return <AccutaneFifthStep />;
    }
    case 5: {
      return <AccutaneSixStep />;
    }
    case 6: {
      return <AccutaneSeventhStep />;
    }
    default: {
      return null;
    }
  }
};

function ShowConfirmPopup({ onClose, onConfirm }: any) {
  return (
    <BasicPopupTemplate title="Submit Payment" onClose={onClose}>
      <div>
        <p
          className="accutane-modal__step-title"
          style={{ margin: "0 0 12px 0" }}
        >
          Oops! Please confirm your payment info
        </p>
        <p className="accutane-modal__step-text">
          There was an error processing your payment for the Accutane <br />{" "}
          plan. Please submit your payment or <br />
          contact <b>membership@honeydewcare.com</b> <br /> for assistance.
        </p>
        <p className="accutane-modal__step-text accutane-modal__step--last-paragraph">
          The “<b>Submit Payment</b>” button will take you to a checkout page.
        </p>
        <div
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={onClose}
            text="Cancel"
            size="small"
            view="secondary"
          />
          <Button onClick={onConfirm} text="SUBMIT PAYMENT" size="small" />
        </div>
      </div>
    </BasicPopupTemplate>
  );
}

export function AccutanePopup({ onClose }: Props) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [initiateAccutane] = useInitiateAccutaneMutation();
  const { setShouldReload } = useHotReload();
  const [checkoutSessionUrl, setCheckoutSessionUrl] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [step, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const backButtonVisible = step > 0;
  const isLastStep = step === 5;
  const isAddPaymentStep = step === 6;

  if (!patientQuery.data) {
    return null;
  }

  const onClickHandler = async () => {
    if (!patientQuery.data) return null;
    try {
      setLoading(true);
      const result: any = await initiateAccutane(patientQuery.data.patientId);
      const checkoutSessionLink = result?.data?.body?.checkoutSessionUrl;
      if (checkoutSessionLink) {
        showError({
          title: "Error",
          description:
            "There was an error processing your payment for the Accutane plan. Please submit your payment or contact membership@honeydewcare.com for assistance.",
        });
        setCheckoutSessionUrl(checkoutSessionLink.url);
        setShowConfirmation(true);
        throw new Error("Stripe Payment Failed");
      }
      if (result.error) {
        throw new Error(JSON.stringify(result.error));
      }
      showSuccess({
        title: "Success",
        description: "Successfully enrolled to accutane",
      });
      setTimeout(() => {
        setShouldReload(true);
        onClose();
      }, 2000);
    } catch (error: any) {
      let errorMessage =
        "Oops! Something went wrong, please try again or contact info@honeydewcare.com";
      const newErrorMessage = JSON.parse(error.message);
      if (newErrorMessage.status !== "FETCH_ERROR")
        errorMessage =
          "Oops! There was an error charging your payment method for the Accutane plan. Please try again or contact membership@honeydewcare.com";

      showError({
        title: "Error",
        description: errorMessage,
      });
      logError("Error occurred when trying to initiate accutane");
    } finally {
      setLoading(false);
    }

    return null;
  };

  const goNextHandler = () => {
    setActiveStep(step + 1);
  };

  const onAddPaymentMethodClickHandler = () => {
    window.open(checkoutSessionUrl, "_blank");
    setShowConfirmation(false);
    onClose();
    throw new Error("Insufficient Funds");
  };

  const handleNextButtonStep = () => {
    if (isLastStep) {
      return onClickHandler;
    }
    return goNextHandler;
  };

  const goBackHandler = () => {
    setActiveStep(step - 1);
  };

  const getStepClass = (itemStep: number) =>
    itemStep === step
      ? "accutane-modal__control-steps-step accutane-modal__control-steps-step--active"
      : "accutane-modal__control-steps-step";

  const getNextButtonText = () => {
    if (!isLastStep) {
      if (step === 4) {
        return "ADD IT";
      }
      if (step === 6) {
        return "ADD CARD";
      }
      return "NEXT";
    }
    return "CLOSE";
  };

  return (
    <div className="accutane-modal">
      <div className="accutane-modal__content">{viewForStep(step)}</div>
      <div className="accutane-modal__control">
        <ul className="accutane-modal__control-steps">
          <li className={getStepClass(0)} />
          <li className={getStepClass(1)} />
          <li className={getStepClass(2)} />
          <li className={getStepClass(3)} />
          <li className={getStepClass(4)} />
          <li className={getStepClass(5)} />
        </ul>
        <div className="accutane-modal__control-buttons">
          {backButtonVisible && (
            <Button
              onClick={goBackHandler}
              text="BACK"
              size="small"
              view="secondary"
            />
          )}
          <Button
            onClick={handleNextButtonStep()}
            text={getNextButtonText()}
            disabled={loading}
            size="small"
          />
        </div>
      </div>
      {showConfirmation && (
        <ShowConfirmPopup
          onClose={() => setShowConfirmation(false)}
          onConfirm={onAddPaymentMethodClickHandler}
        />
      )}
    </div>
  );
}

export function AccutanePopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <AccutanePopup onClose={onClose} />
      </HelpPopupTemplate>
    );
  };
}
