import { Box, styled } from "@mui/material";

interface Props {
  background: string;
  color: string;
}
export const Container = styled(Box)<Props>`
  border-radius: 24px;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  cursor: default;
`;
