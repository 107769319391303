import {
  TemplatesList,
  TestsOrderValues,
} from "../../app/NewComponents/Common/SendBloodSlipPopup";
import { Provider } from "../../types/Employee";
import { Patient } from "../../types/Entities/Patient";
import { dateFormat } from "../dates";
import { getMomentDate } from "../get-date-pretty";

const getSpecificValues = (option: string) => {
  switch (option) {
    case TemplatesList.MALE_PATTERN_HAIR_LOSS: {
      return {
        diagnosisCode: "L63.0",
        duration: "Does not repeat",
      };
    }
    default: {
      return {
        diagnosisCode: "L70.0",
        duration: "Valid every 4 weeks for 6 months as needed",
      };
    }
  }
};

export function getFormFieldsByTemplateOption({
  option,
  patient,
  provider,
}: {
  option: any;
  patient: Patient;
  provider: Provider;
  isPregnantCapability?: boolean;
}) {
  const isLipidProfileInOrder = option?.tests?.some(
    (test) => test.label === TestsOrderValues.LIPID_PROFILE
  );

  const specificValues = getSpecificValues(option?.value);
  return {
    "bloodSlipDetails.template": {
      label: option?.label ?? option,
      value: option?.value ?? "custom",
    },
    "bloodSlipDetails.diagnosisCode": specificValues.diagnosisCode,
    "bloodSlipDetails.levelsToBeMeasured": option?.tests ?? [],
    "bloodSlipDetails.validFrom": getMomentDate(new Date()).format(dateFormat),
    "bloodSlipDetails.duration": specificValues.duration,
    "patientDetails.name": patient?.fullName,
    "patientDetails.dateOfBirth": patient?.dateOfBirth,
    "providerDetails.name": `${provider?.firstName} ${provider?.lastName}`,
    "providerDetails.npi": provider?.npiNumber,
    "bloodSlipDetails.alertText": isLipidProfileInOrder
      ? "Important: Please fast for 12 hours before getting your bloodwork done. You are able to drink water while fasting."
      : null,
  };
}
