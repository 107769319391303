import React, { useEffect, useState } from "react";
import { useGetAssignedPatientsByEmployeeQuery } from "../../../../features/api/patients";
import { ReactComponent as WarningIcon } from "../../../../Assets/NewIcons/warning-rounded.svg";
import { getStateAbbreviation } from "../../../../utils/state-name-to-abbreviation";

function UncoveredStates({ provider, availableProviders }: any) {
  const assignedPatients = useGetAssignedPatientsByEmployeeQuery(provider.id);
  const [distinctProviderStates, setDistinctProviderStates] = useState([]);
  const [patientStates, setPatientStates] = useState([]);
  const [uncoveredStates, setUncoveredStates] = useState([]);

  useEffect(() => {
    if (assignedPatients?.data) {
      const patients = assignedPatients.data;
      const uniqueStates = [
        ...new Set(
          patients.map(
            (patient) =>
              getStateAbbreviation(patient.shippingInfo?.state) ||
              patient?.state
          )
        ),
      ];
      setPatientStates(uniqueStates);
    }
  }, [assignedPatients]);

  useEffect(() => {
    const currentProviderId = provider?.employeeId;
    const finalProvidersList = availableProviders.filter(
      (pro) => pro.id !== currentProviderId
    );

    const distinctStatesOfProviders = Array.from(
      new Set(
        finalProvidersList.flatMap((prov) =>
          prov.stateLicenses.map((license) => license?.stateAbbreviation)
        )
      )
    );
    setDistinctProviderStates(distinctStatesOfProviders);
  }, [availableProviders]);

  useEffect(() => {
    if (patientStates.length > 0 && distinctProviderStates.length > 0) {
      const normalize = (states) =>
        states
          .filter((state) => state !== undefined && state !== null) // Remove undefined or null
          .map((state) => state.trim().toLowerCase());

      // Normalize both arrays
      const normalizedPatientStates = normalize(patientStates);
      const normalizedProviderStates = new Set(
        normalize(distinctProviderStates)
      );

      // Find patient states not in provider states
      const uniquePatientStates = patientStates.filter(
        (state, index) =>
          !normalizedProviderStates.has(normalizedPatientStates[index])
      );

      setUncoveredStates(uniquePatientStates);
    }
  }, [patientStates, distinctProviderStates]);

  return uncoveredStates.length > 0 ? (
    <span style={{ display: "flex", alignItems: "end" }}>
      <span>
        <WarningIcon />
      </span>
      <span style={{ margin: "0 10px" }}>
        Not covered in {uncoveredStates.join(", ")}
      </span>
    </span>
  ) : null;
}

export default UncoveredStates;
