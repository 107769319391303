import { useState } from "react";

export function useProgress(
  initialValue: number,
  initialStep: number
): [number, (step: number) => void, (currentPage: number) => void] {
  const [progress, setProgress] = useState(initialValue);
  let internalStep: number = initialStep;

  const moveStep = (currentPage: number) => {
    setProgress((oldProgress) =>
      Math.max(oldProgress, (currentPage + 1) * internalStep)
    );
  };

  const setStep = (step: number) => {
    internalStep = step;
  };

  return [progress, setStep, moveStep];
}
