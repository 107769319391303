import { TemplatesList } from "../../app/NewComponents/Common/SendBloodSlipPopup";

export const getDocumentName = (template: string) => {
  switch (template) {
    case TemplatesList.FEMALE_FIRST_PREGNANCY:
      return "Pregnancy test Lab Slip";
    default:
      return "Bloodwork Lab Slip";
  }
};
