import React, { createContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetMiscellaneousTasksQuery } from "../features/api/employees";
import { getSessionState } from "../features/session";
import { PatientDashboardItem } from "../types/Dashboard";

export const DashboardDataContext = createContext<{
  miscellaneousTasks: {
    count?: number;
    isFetching?: boolean;
    list?: any[];
    fetchMoreData?: () => void;
    lastEvaluatedKey?: { id: string; compositeKey: string };
  };
}>({ miscellaneousTasks: {} });

export function DashboardDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { userId: employeeId } = useSelector(getSessionState);
  const [newKeyToEvaluate, setNewKeyToEvaluate] = useState<
    { id: string; compositeKey: string } | undefined
  >();
  const {
    data: tasks,
    isFetching,
    refetch,
  } = useGetMiscellaneousTasksQuery({
    employeeId: employeeId as string,
    lastKeyEvaluated: newKeyToEvaluate,
  });

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [refetch]);

  const fetchMoreData = () => {
    setNewKeyToEvaluate(tasks?.lastKeyEvaluated);
  };

  const contextValue = useMemo(
    () => ({
      miscellaneousTasks: {
        list: tasks?.miscTasks || [],
        count: tasks?.count,
        lastEvaluatedKey: tasks?.lastKeyEvaluated,
        fetchMoreData,
        isFetching,
      },
    }),
    [tasks, isFetching]
  );

  return (
    <DashboardDataContext.Provider value={contextValue}>
      {children}
    </DashboardDataContext.Provider>
  );
}
