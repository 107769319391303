import React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CheckedIcon } from "./icon";
import { UncheckedIcon } from "./unchecked";

const StyledCheckbox = styled(Checkbox)(() => ({
  borderRadius: "4px",
  padding: 0,
  "& .MuiSvgIcon-root": { fontSize: 26 },
}));

export function CustomCheckbox(props: CheckboxProps) {
  return (
    <StyledCheckbox
      {...props}
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon filled />}
    />
  );
}
