import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HelpPopupTemplate } from "../../../NewComponents/Common/PopupLayer/help";
import { Accutane, Gender } from "../../../../types/Entities/Accutane";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.scss";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { Patient } from "../../../../types/Entities/Patient";
import { Provider } from "../../../../types/Employee";
import { useUpdateTaskStatusMutation } from "../../../../features/api/accutane";
import { useGetCareTeamQuery } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { useGetEmployeeQuery } from "../../../../features/api/employees";

interface PopupProps {
  onClose: () => void;
}

interface ReviewNotesSection extends Accutane {
  taskId: string;
  userId: string;
  employeeId: string;
  patient?: Patient;
  provider?: Provider;
  inviteId?: string | undefined;
  role?: string;
  compositeKey?: string;
}

function ReviewNotesSectionPopup({
  patientId,
  onClose,
  taskId,
  userId,
  employeeId,
  compositeKey,
}: {
  patientId: string;
  onClose: () => void;
  taskId: string;
  userId: string;
  employeeId: string;
  compositeKey: string;
}) {
  const history = useHistory();
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();

  const { userId: adminId } = useSelector(getSessionState);
  const careTeamQuery = useGetCareTeamQuery(patientId);
  const employeeQuery = useGetEmployeeQuery(employeeId);

  const onSubmit = async () => {
    const updatedBy = {
      id: employeeId || "",
      role: employeeQuery?.data?.role || "",
    };
    const result: any = await updateTaskStatus({
      taskId,
      compositeKey,
      updatedBy,
      patientId,
      status: "COMPLETED",
    });

    if (result.error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to complete task",
      });
      return;
    }
    const updatedCareTeam = await careTeamQuery.refetch();
    if (updatedCareTeam.data?.length) {
      const userIdExistsInCareTeam = updatedCareTeam.data.some(
        (employee) => employee.employeeId === adminId
      );
      if (!userIdExistsInCareTeam) {
        history.push("/");
      }
    }
    showSuccess({
      title: "Success!",
      description: "Successfully completed task",
    });
    onClose();
  };

  return (
    <div className="enroll-ipledge-popup">
      <p className="enroll-ipledge-popup__title">Review notes section</p>
      <div className="enroll-ipledge-popup__content">
        <p className="enroll-ipledge-popup__content-text">
          You were tagged in a new note. Please review the notes section.
          <br />
          <br />
          When you have completed this task, please confirm by clicking the
          button below.
        </p>
      </div>
      <div className="enroll-ipledge-popup__control-buttons">
        <Button text="Cancel" onClick={onClose} size="small" view="secondary" />
        <Button text="Confirm" onClick={onSubmit} size="small" />
      </div>
    </div>
  );
}

function ReviewNotesSectionPopupGenerator(accutane: ReviewNotesSection) {
  return function render({ onClose }: PopupProps) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <ReviewNotesSectionPopup
          patientId={accutane.userId}
          taskId={accutane.taskId}
          onClose={onClose}
          userId={accutane.userId}
          employeeId={accutane.employeeId}
          compositeKey={accutane.compositeKey}
        />
      </HelpPopupTemplate>
    );
  };
}

export default ReviewNotesSectionPopupGenerator;
