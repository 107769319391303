import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as MedicalBackgroundImage } from "../../../../Assets/NewIcons/medical-background.svg";
import { Step, useSurveySteps } from "../../../../hooks/use-survey-steps";
import { Button } from "../../../NewComponents/Common/Button";
import { getSessionState } from "../../../../features/session";

export function SurveyLastStep() {
  const [moveFromCurrentStep] = useSurveySteps(Step.ALL_SET);
  const onboardingFlag = useFeatureIsOn("onboarding-flag");
  const { userId } = useSelector(getSessionState);

  return (
    <div className="survey-page">
      <MedicalBackgroundImage className="survey-page__image" />
      <p className="survey-page__title">
        All done! You are ready for your visit.
      </p>
      <p className="survey-page__text paragraph-font--color">
        Please check your email for a calendar invitation and the meeting
        details of your initial consultation.
      </p>
      <Button
        text="Great!"
        onClick={() => moveFromCurrentStep(userId, onboardingFlag)}
      />
    </div>
  );
}
