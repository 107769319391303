import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { getSessionState } from "../../../../features/session";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { InsurancePopupGenerator } from "../../../NewComponents/Patient/InsurancePopup";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import { PatientTabs } from "../../../../types/Patient";
import PatientDocuments from "../../../NewComponents/Patient/PatientDocuments";
import IpledgeDetails from "../../../NewComponents/Patient/IpledgeDetails";
import "./style.scss";
import { EditPatientInfoPopup } from "../../../Components/Common/EditPatientInfoPopup";
import ExpandableInfo from "../../../NewComponents/ExpandableInfo";
import { ProviderTabs } from "../../../../types/Provider";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import { CareTeamBlock } from "../../../NewComponents/Administrator/CareTeamBlock";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import { concatShippingInfo } from "../../../../utils/concat-shipping-info";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { useGetDocumentsByPatientIdQuery } from "../../../../features/api/accutane";
import { Loader } from "../../../NewComponents/Common/Loader";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { pregnancyCapabilityStatus } from "../../../../utils/patient/pregnancyCapabilityStatus";

const patientTabsItems = [
  {
    label: PatientTabs.TREATMENT_HISTORY,
    key: "treatment-history-tab",
  },
  {
    label: PatientTabs.DOCUMENTS,
    key: "documents-tab",
  },
];

export function PatientDashboard() {
  const { showPopup } = useContext(PopupLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const enabled = useFeatureIsOn("onboarding-flag");
  const location = useLocation();
  const { pathname } = location;

  const [currentTab, setTab] = useState(
    PatientTabs.TREATMENT_HISTORY as string
  );

  const onTabChange = (tab: string) => {
    setTab(tab);
  };

  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );

  useEffect(() => {
    if (appointmentsQuery?.data && appointmentsQuery?.data.length > 0) {
      setAppointmentDate(appointmentsQuery.data[0].startTime);
      setPatientTimezone(appointmentsQuery.data[0]?.inviteeTimezone);
    }
  }, [appointmentsQuery.data]);

  console.log("enabled A/B", enabled);

  useEffect(() => {
    setTab(
      pathname === "/document"
        ? PatientTabs.DOCUMENTS
        : PatientTabs.TREATMENT_HISTORY
    );
  }, [pathname]);

  useEffect(() => {
    const targetDiv = document.getElementById("patient-tabs");
    if (targetDiv) {
      window.scrollTo(0, targetDiv.offsetTop);
    }
  }, [location, currentTab]);

  function openInsuranceInfo(e: React.MouseEvent) {
    e.stopPropagation();
    if (patientQuery.data)
      showPopup(InsurancePopupGenerator({ patient: patientQuery.data }));
  }

  const activePatientShipping = {
    address1: patientQuery.data?.shippingInfo?.addressLine1,
    address2: patientQuery.data?.shippingInfo?.addressLine2,
    city: patientQuery.data?.shippingInfo?.city,
    state: patientQuery.data?.shippingInfo?.state,
    zipCode: patientQuery.data?.shippingInfo?.zipCode,
  };
  const shippingInfo = concatShippingInfo(activePatientShipping);

  const expandableInfo = [
    {
      title: "Parent's contact information",
      items: [
        {
          label: "Parent name",
          description: patientQuery.data?.parentInfo?.name ?? "N/A",
        },
        {
          label: "Parent phone",
          description: patientQuery.data?.parentInfo?.phone ?? "N/A",
        },
        {
          label: "Parent email",
          description: patientQuery.data?.parentInfo?.email ?? "N/A",
        },
      ],
      key: "contactInformation",
      loading: patientQuery.isLoading || !patientQuery.isSuccess,
    },
    {
      title: "Shipping address",
      items: [
        {
          label: shippingInfo,
        },
      ],
      key: "shippingInfo",
      loading: patientQuery.isLoading || !patientQuery.isSuccess,
    },
    {
      title: "Health insurance",
      items: [
        {
          label: "Member ID",
          description: patientQuery.data?.insurance?.memberId ?? "N/A",
        },
        {
          label: "Insurance name",
          description: patientQuery.data?.insurance?.insuranceName ?? "N/A",
        },
        {
          label: "Policy holder name",
          description: patientQuery.data?.insurance?.policyHolderName ?? "N/A",
        },
        {
          label: "Group number",
          description: patientQuery.data?.insurance?.groupNumber ?? "N/A",
        },
        {
          label: "RxBin number",
          description: patientQuery.data?.insurance?.rxBinNumber ?? "N/A",
        },
      ],
      extra: (
        <div className="edit-button">
          <EditIcon onClick={openInsuranceInfo} />
        </div>
      ),
      key: "healthInsurance",
      loading: patientQuery.isLoading || !patientQuery.isSuccess,
    },
  ];

  const tabsItems = [
    {
      label: ProviderTabs.CARE_TEAM,
      key: "care-team",
      children: <CareTeamBlock />,
    },
  ];

  const patientInfo =
    patientQuery.data &&
    (moment().diff(patientQuery.data.dateOfBirth, "years") >= 18 ||
      !patientQuery.data.parentInfo)
      ? expandableInfo.slice(1)
      : expandableInfo;

  return (
    <>
      <EditPatientInfoPopup />
      <div className="patient-details-dashboard">
        <div className="dashboard-info-container">
          <div className="dashboard-info-wrapper">
            <div className="dashboard-short-info">
              <FollowUpCard />
              <IpledgeDetails
                patientId={patientQuery.data?.patientId}
                isNotPregnantCapability={pregnancyCapabilityStatus(
                  patientQuery?.data
                )}
              />
              <div className="dashboard-short-info__patient-info">
                <div>
                  {patientInfo.map((it) => (
                    <ExpandableInfo
                      title={it.title}
                      items={it.items}
                      extra={it.extra}
                      key={it.key}
                      loading={it.loading}
                    />
                  ))}
                </div>
              </div>
              <Tabs items={tabsItems} />
            </div>
            <div className="dashboard-detailed-info" id="patient-tabs">
              <Tabs
                initialTab={currentTab}
                className="dashboard-detailed-info__tabs"
                items={patientTabsItems}
                onChange={onTabChange}
              />
              {currentTab === ProviderTabs.TREATMENT_HISTORY && (
                <>
                  <TreatmentHistory editable={false} hideNotes />
                  <VisitsHistory
                    appointmentDate={appointmentDate}
                    patientTimezone={patientTimezone}
                  />
                </>
              )}
              {currentTab === PatientTabs.DOCUMENTS &&
                (patientQuery.data ? (
                  <PatientDocuments userId={patientQuery.data.patientId} />
                ) : (
                  <Skeleton flex fullWidth column count={3} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
