import { Box, styled } from "@mui/material";

export const MostCommonPill = styled(Box)`
  background-color: #f3faf2;
  color: #60b257;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 8px;
  border-radius: 24px;
  margin-left: 8px;
`;
