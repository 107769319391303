import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { Radio, RadioProps } from "@mui/material";
import { ReactComponent as Unchecked } from "./icon.svg";

export function CustomRadio({ props }: { props?: RadioProps }) {
  return <Radio {...props} icon={<Unchecked />} checkedIcon={<Unchecked />} />;
}

export const CustomRadioButton = styled(FormControlLabel)(({ theme }) => ({
  border: "1px solid #F2F4F7",
  borderRadius: "80px",
  padding: "10px",
  margin: "8px 0",
  width: "100%",
  backgroundColor: "#FFFFFF",
  "& .MuiRadio-root": {
    padding: "9px",
  },
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiFormControlLabel-label": {
    width: "85%",
  },
}));
