import { PricedLabs } from "../../app/NewComponents/Common/FindLab/utils";

export const PHYSICIAN_REQUISITION = 1;
export const ORDER_COST = 3;
export const SERVICE_FEE = 25;

export function getPriceRange(prices: PricedLabs[]) {
  const allPrices = prices.map(({ totalPrice }) => totalPrice);

  const minPrice = Math.min(...allPrices);
  const maxPrice = Math.max(...allPrices);

  if ([minPrice, maxPrice].includes(Infinity)) return "";

  if (minPrice === maxPrice) return `$${minPrice.toFixed(2)}`;
  return `$${minPrice.toFixed(2)}-$${maxPrice.toFixed(2)}`;
}
