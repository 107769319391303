import * as payments from "./payments";
import * as followUps from "./follow-ups";
import * as dashboard from "./patient-dashboard";
import * as chats from "./chats";
import * as accutane from "./accutane";
import * as providers from "./providers";
import * as labs from "./labs";

export const HoneydewAPI = {
  payments,
  followUps,
  dashboard,
  chats,
  accutane,
  providers,
  labs,
};
