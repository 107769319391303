import React, { useMemo } from "react";
import { Typography, Grid } from "@mui/material";
import { useBloodWorkStepper } from "../../../../../contexts/blood-work";
import { getSteps } from "./steps";
import CustomStepper from "../../../Common/Material/Stepper";
import { Button } from "../../../Common/Material/Button";
import { calculateProgress } from "../../../../../utils/lab-work/get-bloodwork-step";

export default function Checkout() {
  const { handleNext, bloodwork } = useBloodWorkStepper();

  const activeStep = useMemo(
    () => calculateProgress(bloodwork.task?.histories ?? []),
    [bloodwork]
  );

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          How to complete your blood test
        </Typography>
        <CustomStepper
          steps={getSteps({
            needsFasting: bloodwork.needsFasting,
            lab: bloodwork?.selectedLab || bloodwork?.labOrder?.lab,
          })}
          activeStep={activeStep}
        />
      </Grid>
      <Grid container gap={2} justifyContent="center">
        <Grid item xs={8} md={5}>
          <Button
            text="GOT IT, back to care hub"
            size="small"
            fullWidth
            onClick={handleNext}
          />
        </Grid>
      </Grid>
    </>
  );
}
