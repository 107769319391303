import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ReactComponent as InfoSign } from "../../../../../Assets/NewIcons/info-rounded.svg";
import { useBloodWorkStepper } from "../../../../../contexts/blood-work";

export default function Welcome() {
  const { bloodwork, setIsNextDisabled } = useBloodWorkStepper();

  useEffect(() => {
    setIsNextDisabled(!bloodwork.patient?.patientId);
  }, [bloodwork.patient]);

  return (
    <Grid container direction="column" p={2}>
      <Typography variant="h5" gutterBottom>
        Getting your blood work done
      </Typography>
      <Box display="flex" alignItems="center">
        <InfoSign />
        <Typography variant="h6" ml={1}>
          Here is when to expect blood work:
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{ pl: 4, listStyleType: "disc", rowGap: 1, mt: 1 }}
      >
        <Box component="li">
          <Typography variant="body1" color="text.secondary" mb={1}>
            Before starting treatment (baseline)
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1" color="text.secondary" mb={1}>
            After 2 months of taking the medication
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1" color="text.secondary" mb={1}>
            One month after finishing treatment
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2">
        <strong>Note:</strong> You and/or your provider may request additional
        bloodwork.
      </Typography>
    </Grid>
  );
}
