import React, { useEffect } from "react";
import { Box, Typography, Grid, RadioGroup, Radio } from "@mui/material";
import { CustomRadioButton } from "../../../Common/Material/RadioButton";
import { PregnancyWorkPopupSteps } from "../steps";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { ReactComponent as CheckIcon } from "../../../../../Assets/NewIcons/check-icon.svg";
import { MostCommonPill } from "./styles";
import StepperButtons from "../../../Stepper/StepperButtons";

export default function LabSelection() {
  const {
    handleNext,
    pregnancyWork,
    updateValues,
    handleBack,
    setIsNextDisabled,
    isNextDisabled,
  } = usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(!pregnancyWork.placeOfService);
  }, []);

  const handleContinue = (value?: string) => {
    let nextStep;
    const flag = value ?? pregnancyWork.placeOfService;
    if (flag === "lab") {
      if (pregnancyWork.paymentMethod === "with-insurance") {
        nextStep = PregnancyWorkPopupSteps.INSURANCE_LAB;
      } else {
        nextStep = PregnancyWorkPopupSteps.FIND_LAB;
      }
    } else {
      nextStep = PregnancyWorkPopupSteps.MEDICAL_OFFICE;
    }
    handleNext(nextStep);
  };

  const handleChange = (value?: "lab" | "medical-office") => {
    if (value) {
      updateValues({ placeOfService: value });
      handleContinue(value);
    }
  };

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          Where will you go to take your pregnancy test?
        </Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          onClick={(event: any) => handleChange(event?.target?.value)}
          defaultValue={pregnancyWork.placeOfService}
        >
          <Grid container columnGap={8}>
            <Grid item xs={12} md={5}>
              <CustomRadioButton
                value="lab"
                control={<Radio />}
                label={
                  <Box>
                    <Grid container>
                      <Typography variant="body1">Lab</Typography>
                      <MostCommonPill>Most Common</MostCommonPill>
                    </Grid>
                    <Typography variant="subtitle2">
                      i.e. LabCorp, Quest Diagnostics, etc
                    </Typography>
                    <Typography variant="subtitle2">
                      <CheckIcon style={{ marginRight: 2 }} />
                      Results will be sent automatically
                    </Typography>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <CustomRadioButton
                value="medical-office"
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="body1">Medical office</Typography>
                    <Typography variant="subtitle2">
                      i.e. PCP, urgent care, Planned Parenthood
                    </Typography>
                    <Typography variant="subtitle2">
                      <strong style={{ fontWeight: 500 }}>Note:</strong> Results
                      must be faxed or uploaded
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
      <StepperButtons
        backText="BACK"
        confirmText="Continue"
        onBack={handleBack}
        onConfirm={handleContinue}
        isConfirmDisabled={isNextDisabled}
      />
    </>
  );
}
