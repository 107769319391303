import React from "react";
import { Box, Typography, Grid, RadioGroup, Radio } from "@mui/material";
import { PaymentMethod } from "../../../../../types/CustomerInfo";
import { CustomRadioButton } from "../../Material/RadioButton";

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paymentMethods: PaymentMethod[];
}

export default function ChangePaymentMethod({
  handleChange,
  paymentMethods,
}: Props) {
  return (
    <Grid container direction="column" p={2}>
      <Typography variant="h5" gutterBottom>
        Select a payment method
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={handleChange}
      >
        <Grid container columnGap={8}>
          {paymentMethods?.map((paymentMethod) => (
            <Grid item xs={12} md={5} key={paymentMethod.id}>
              <CustomRadioButton
                value={paymentMethod.id}
                control={<Radio />}
                label={
                  <Typography variant="h6">
                    {`Paying with ${paymentMethod.brand} ending ${paymentMethod.cardNumber}`}
                  </Typography>
                }
              />
            </Grid>
          ))}
          <Grid item xs={12} md={5}>
            <CustomRadioButton
              value="new-card"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body2">
                    Add a new credit or debit card
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  );
}
