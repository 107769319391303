import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";

export interface LabOrder {
  id: string;
  orderedAt: string;
  patientId: string;
  pdfMetadata: string;
  taskId: string;
  testIds: string[];
  paymentMethod: "cash" | "with-insurance";
  placeOfService: "lab" | "medical-office";
  populatedDocumentName?: string;
  populatedDocumentUrl?: string;
}

export const getLabOrder = async (taskId: string): Promise<LabOrder> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<
      GraphQLResponse<{ getLabOrdersByTaskId: LabOrder }>
    >(
      `${ENV_CONFIG.LAB_SERVICE_GRAPHQL_URL}`,
      {
        query: `
          query getLabOrdersByTaskId($taskId: String!) {
            getLabOrdersByTaskId(taskId: $taskId) {
                id
                orderedAt
                patientId
                pdfMetadata
                taskId
                testIds
                paymentMethod
                placeOfService
                lab {
                  distance
                  labId
                  slug
                  metadata {
                    firstLine
                    secondLine
                    phoneNumber
                    faxNumber
                    city
                    name
                    state
                    zipCode
                  }
                }
                populatedDocumentName
                populatedDocumentUrl
            }
          }
        `,
        variables: {
          taskId,
        },
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getLabOrdersByTaskId;
  } catch (error: any) {
    logError("Error occurred. Cannot get lab locations", error);
    throw error;
  }
};
