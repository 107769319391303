import { styled, Typography } from "@mui/material";

export const ConsentText = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#403E48",
});

export const WalkInPrice = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#6C6A7C",
});

export const SavingsText = styled(Typography)({
  color: "#4E9047",
  fontSize: "16px",
  fontWeight: 500,
});

export const TestDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#6C6A7C",
});

export const TestCaption = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#403E48",
  marginBottom: "16px",
});

export const TestName = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#403E48",
});

export const CardName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#403E48",
});
