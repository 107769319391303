import React, { useContext } from "react";
import { InlineWidget } from "react-calendly";
import { useParams, useHistory } from "react-router-dom";
import { ENV_CONFIG } from "../../../../config";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { LoadingBlock } from "../../../NewComponents/Common/LoadingBlock";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import "./style.scss";

export function RescheduleAppointmentPage() {
  const history = useHistory();
  const { patientId } = useParams();
  const { showError } = useContext(NotificationLayerContext);
  const patientQuery = useGetPatientByIdQuery(patientId as string);
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    patientId as string
  );

  const patient = patientQuery.data;

  if (!patientQuery.isSuccess || !appointmentsQuery.isSuccess) {
    return (
      <div className="reschedule-appointment-page__loader">
        <LoadingBlock />
      </div>
    );
  }

  if (!patient) {
    history.push("/");
    showError({
      title: "Something went wrong",
      description: "Cannot find such patient",
    });
    return null;
  }

  const widgetUrl = ENV_CONFIG.CALENDLY_APPOINTMENT_WIGDET_URLS[patient.state];
  return (
    <div className="reschedule-appointment-page">
      <p className="reschedule-appointment-page__title">
        Reschedule your initial visit below.
      </p>
      <InlineWidget
        url={widgetUrl}
        utm={{
          utmCampaign: ENV_CONFIG.ENVIRONMENT,
          utmSource: patient.patientId,
          utmMedium: appointmentsQuery.data[0].appointmentId,
        }}
        prefill={{
          email: patient.email,
          name: patient.fullName,
        }}
      />
    </div>
  );
}
