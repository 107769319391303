/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";

type CustomSurveyStepper = {
  updateValues: (form: any) => void;
  setIsNextDisabled: (value: boolean) => void;
  cleanup: () => void;
  setBackFunction: (value: () => void) => void;
  onBack: () => void;
  currentStep: number;
  data: any;
  isNextDisabled: boolean;
};

const DEFAULT_VALUE = {
  updateValues: () => {},
  setIsNextDisabled: () => {},
  cleanup: () => {},
  setBackFunction: () => () => {},
  onBack: () => {},
  currentStep: 0,
  data: {},
  isNextDisabled: false,
};

const CustomSurveyStepperContext =
  createContext<CustomSurveyStepper>(DEFAULT_VALUE);

export function CustomSurveyStepperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [onBack, setBack] = useState(() => () => {});
  const [data, setData] = useState<any>({});

  const cleanup = () => {
    setCurrentStep(0);
    setIsNextDisabled(false);
  };

  useEffect(() => () => cleanup(), []); // cleanup when unmounted

  const updateValues = (form: any) => {
    setData({ ...data, ...form });
  };

  const setBackFunction = (value: () => void) => {
    setBack(() => value);
  };

  const value = useMemo(
    () => ({
      updateValues,
      setIsNextDisabled,
      cleanup,
      setBackFunction,
      onBack,
      currentStep,
      data,
      isNextDisabled,
    }),
    [currentStep, data, isNextDisabled, onBack]
  );
  return (
    <CustomSurveyStepperContext.Provider value={value}>
      {children}
    </CustomSurveyStepperContext.Provider>
  );
}

export const useCustomSurveyStepper = () =>
  useContext(CustomSurveyStepperContext);
