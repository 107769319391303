import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { LineItem, ProductData } from "../../../../types/Base/Products";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import { ReactComponent as Plus } from "../../../../Assets/NewIcons/plus.svg";
import { ReactComponent as Minus } from "../../../../Assets/NewIcons/minus.svg";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { getSessionState } from "../../../../features/session";
import { useGetPricesQuery } from "../../../../features/api/payment";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { initialProductsData } from "../../../../data/products";
import { Skeleton } from "../../Common/Skeleton";

type Product = {
  priceId: string;
  image: string;
  title: string;
  description: string;
  inTreatment: boolean;
  amount: number;
  quantity: number;
  soldout?: boolean;
};

interface Props {
  initialQuantityData?: LineItem[];
  className?: string;
  disableSuggestions?: boolean;
  onChange: (value: LineItem[]) => void;
}

function ProductSkeleton() {
  return (
    <div className="products-list__item">
      <div className="products-list__content">
        <div className="products-list__image-container">
          <Skeleton flex fullHeight fullWidth />
        </div>
        <div className="products-list__text-block">
          <p className="products-list__title">
            <Skeleton flex fullWidth />
          </p>
          <p className="products-list__description paragraph-font--color">
            <Skeleton flex fullWidth />
          </p>
        </div>
      </div>
      <div className="products-list__selection">
        <p className="products-list__price">
          <Skeleton flex fullWidth />
        </p>
        <div className="products-list__counter">
          <Skeleton flex fullWidth />
        </div>
      </div>
    </div>
  );
}

export function ProductsPicker({
  onChange,
  className,
  disableSuggestions,
  initialQuantityData,
}: Props) {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const pricesQuery = useGetPricesQuery(null);
  const [products, setProducts] = useState<Product[] | null>(null);

  useEffect(() => {
    if (products) {
      onChange(
        products.map(({ priceId, quantity }) => ({
          priceId,
          quantity,
        }))
      );
    }
  }, [products]);

  useEffect(() => {
    if (followUpsQuery.isError || pricesQuery.isError) {
      showError({
        title: "Something went wrong...",
        description: "Cannot request products. Please, try again later",
      });
      return;
    }
    if (followUpsQuery.isSuccess && pricesQuery.isSuccess) {
      const productsPrices = pricesQuery.data.filter(
        ({ type, keywords }) =>
          type === "product" &&
          !!initialProductsData.find(({ keyword }) =>
            keywords.includes(keyword)
          )
      );
      const lastFollowUp = followUpsQuery.data.at(0);
      setProducts(
        productsPrices.map(
          ({
            id,
            medicineId,
            additionalMedicationIds,
            keywords,
            title,
            amount,
          }) => {
            const initialData = initialProductsData.find(({ keyword }) =>
              keywords.includes(keyword)
            ) as ProductData;
            const inTreatment = !disableSuggestions
              ? !!lastFollowUp?.treatmentPlan?.medications?.some(
                  ({ medicineKey }) =>
                    medicineKey === medicineId ||
                    additionalMedicationIds?.includes(medicineKey)
                )
              : false;
            return {
              priceId: id,
              title,
              soldout: initialData.soldout,
              image: initialData.image,
              description: initialData.description,
              quantity: inTreatment && !initialData.soldout ? 1 : 0,
              amount,
              inTreatment,
            };
          }
        )
      );
    }
  }, [
    followUpsQuery.isSuccess,
    pricesQuery.isSuccess,
    followUpsQuery.isError,
    pricesQuery.isError,
  ]);
  return (
    <div className={`products-list ${className}`}>
      {products?.map(
        ({
          image,
          title,
          description,
          inTreatment,
          amount,
          soldout,
          quantity,
          priceId,
        }) => (
          <div className="products-list__item">
            <div className="products-list__content">
              <div
                className={`products-list__image-container ${
                  soldout && "products-list__image-container-soldout"
                }`}
              >
                <img
                  src={image}
                  className="products-list__image"
                  alt="product"
                />
                {soldout && (
                  <div className="products-list__soldout">
                    <span>SOLD OUT</span>
                  </div>
                )}
              </div>
              <div className="products-list__text-block">
                <p className="products-list__title">{title}</p>
                <p className="products-list__description paragraph-font--color">
                  {description}
                </p>
              </div>
            </div>
            {inTreatment && (
              <div className="products-list__check">
                <CheckIcon />
                <span>In your treatment plan</span>
              </div>
            )}
            <div className="products-list__selection">
              <p className="products-list__price">
                ${(amount / 100).toFixed(2)}
              </p>
              <div className="products-list__counter">
                {!soldout ? (
                  <>
                    <button
                      type="button"
                      className={`products-list__count-button ${
                        soldout && "products-list__count-button-disabled"
                      }`}
                      disabled={soldout}
                      onClick={() => {
                        if (quantity < 1) return;
                        setProducts(
                          products.map((it) => ({
                            ...it,
                            quantity:
                              it.priceId === priceId
                                ? it.quantity - 1
                                : it.quantity,
                          }))
                        );
                      }}
                    >
                      <Minus />
                    </button>
                    <p className="products-list__count-value">{quantity}</p>
                    <button
                      type="button"
                      className={`products-list__count-button ${
                        soldout && "products-list__count-button-disabled"
                      }`}
                      disabled={soldout}
                      onClick={() => {
                        setProducts(
                          products.map((it) => ({
                            ...it,
                            quantity:
                              it.priceId === priceId
                                ? it.quantity + 1
                                : it.quantity,
                          }))
                        );
                      }}
                    >
                      <Plus />
                    </button>
                  </>
                ) : (
                  <p className="products-list__soldout-label">Sold Out</p>
                )}
              </div>
            </div>
          </div>
        )
      ) || initialProductsData.map(() => <ProductSkeleton />)}
    </div>
  );
}
