/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "../../../Common/Material/Button";
import { FastingBanner } from "../../../Common/FastingBanner";
import { FileButton } from "../../../Common/Material/FileButton";

export const steps = [
  {
    label: "Print the lab slip",
    description: (
      <Box>
        <Typography variant="subtitle2">
          Once your lab slip is created, please download, print, and bring it
          with you to the lab. The lab slip will also be stored in your
          Documents tab.
        </Typography>
      </Box>
    ),
    completed: true,
    key: "download_lab_slip",
  },
  {
    label: "Visit your local lab and complete your urine HCG test",
    description: (
      <Box>
        <Typography variant="subtitle2">
          If you need lab suggestions, try LabCorp or Quest.
        </Typography>
      </Box>
    ),
    completed: false,
    key: "submit_results",
  },
  {
    label: "Once done, mark your test as complete by using the button below",
    description: (
      <Button
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
        onClick={() => {}}
        view="secondary"
        size="small"
        text="MARK HCG TEST AS COMPLETE"
      />
    ),
    completed: false,
    key: "mark_complete",
  },
  {
    label: "The lab will send us your test results ",
    description: undefined,
    completed: false,
    key: "lab_send",
  },
  {
    label: "Your provider will review the results",
    description: undefined,
    completed: false,
    key: "review",
  },
];

export const getSteps = ({
  onComplete,
  requestLabSlip,
  loading,
  generatingSlip,
  needsFasting,
  canComplete,
  document,
  oldInProgress,
}: {
  onComplete: () => void;
  requestLabSlip: () => void;
  loading?: boolean;
  needsFasting?: boolean;
  generatingSlip?: boolean;
  canComplete?: boolean;
  oldInProgress?: boolean;
  document?: { name?: string; url?: string };
}) =>
  steps.map((step) => {
    if (step.key === "download_lab_slip") {
      return {
        ...step,
        description: (
          <Box>
            <Typography variant="subtitle2" mb={1}>
              Once your lab slip is created, please download, print, and bring
              it with you to the lab. The lab slip will also be stored in your
              Documents tab.
            </Typography>
            {!oldInProgress &&
              (document?.url ? (
                <FileButton name={`${document.name}.pdf`} url={document.url} />
              ) : (
                <Button
                  text="Request lab slip"
                  onClick={requestLabSlip}
                  size="small"
                  isLoading={generatingSlip}
                />
              ))}
          </Box>
        ),
      };
    }
    if (step.key === "mark_complete") {
      return {
        ...step,
        description: (
          <Button
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
            onClick={onComplete}
            isLoading={loading}
            disabled={!canComplete || loading}
            view="secondary"
            size="small"
            text="MARK HCG TEST AS COMPLETE"
          />
        ),
      };
    }
    if (step.key === "submit_results" && needsFasting) {
      return {
        ...step,
        description: (
          <Box>
            <FastingBanner />
            <Typography variant="subtitle2" mt={1}>
              If you need lab suggestions, try LabCorp or Quest.
            </Typography>
          </Box>
        ),
      };
    }
    return step;
  });
