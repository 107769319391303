/* eslint-disable no-nested-ternary */
import React from "react";
import {
  Button as MuiButton,
  CircularProgress,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

type Sizes = "big" | "small" | "extra-small" | undefined;

interface CustomButtonProps extends MuiButtonProps {
  text: string | JSX.Element;
  view?: "primary" | "secondary" | "ghost";
  size?: Sizes;
  isLoading?: boolean;
  tooltip?: string;
  fullWidth?: boolean;
  type?: "button" | "submit"; // Distinguish between regular and submit buttons
  onClick?: () => Promise<void> | void;
}

const sizes = (size: Sizes) => {
  switch (size) {
    case "big":
      return {
        height: "48px",
        padding: "0 32px",
        fontSize: "16px",
      };
    case "small":
      return {
        height: "40px",
        padding: "0 24px",
        fontSize: "14px",
      };
    case "extra-small":
      return {
        height: "30px",
        padding: "0 16px",
        fontSize: "10px",
      };
    default:
      return {
        height: "48px",
        padding: "0 32px",
        fontSize: "16px",
      };
  }
};

const CustomButton = styled(MuiButton)<CustomButtonProps>(
  ({ view, size, fullWidth }) => ({
    textTransform: "uppercase",
    borderRadius: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: view === "secondary" ? 700 : 500,
    width: fullWidth ? "100%" : "auto",
    backgroundColor:
      view === "secondary"
        ? "var(--secondary-active-button-bg-color)"
        : view === "ghost"
        ? "transparent"
        : "var(--primary-active-button-bg-color)",
    color:
      view === "secondary"
        ? "var(--secondary-active-button-text-color)"
        : view === "ghost"
        ? "var(--primary-active-button-bg-color)"
        : "var(--primary-active-button-text-color)",
    ...sizes(size),
    transition: "all 0.15s ease-out",

    "&:hover": {
      backgroundColor:
        view === "secondary"
          ? "var(--secondary-hover-button-bg-color)"
          : view === "ghost"
          ? "rgba(0, 0, 0, 0.04)" // Slight hover effect for ghost
          : "var(--primary-hover-button-bg-color)",
    },
    "&:active": {
      backgroundColor:
        view === "secondary"
          ? "var(--secondary-press-button-bg-color)"
          : view === "ghost"
          ? "rgba(0, 0, 0, 0.08)" // Slight press effect for ghost
          : "var(--primary-press-button-bg-color)",
    },
    "&.Mui-disabled": {
      backgroundColor: "var(--disabled-button-bg-color)",
      color: "var(--disabled-button-text-color)",
    },
  })
);

export function Button({
  text,
  view = "primary",
  size = "big",
  isLoading = false,
  tooltip,
  fullWidth = false,
  type = "button", // Default to a regular button
  onClick,
  ...props
}: CustomButtonProps) {
  const handleClick = async () => {
    if (onClick && type === "button") await onClick();
  };

  return (
    <CustomButton
      view={view}
      size={size}
      fullWidth={fullWidth}
      type={type}
      onClick={handleClick}
      disabled={isLoading || props.disabled}
      data-tooltip={tooltip}
      className={isLoading ? ".Mui-disabled" : ""}
      {...props}
    >
      {text}
      {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
    </CustomButton>
  );
}
