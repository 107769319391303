import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PregnancyWorkContainer = styled(Grid)`
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
`;

export const SectionTitle = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: "#403E48",
});

export const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  color: "#403E48",
  marginBottom: "2rem",
});
