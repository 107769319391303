import { ENV_CONFIG } from "../../config";
import { Patient } from "../../types/Entities/Patient";
import { getToday } from "../dates";
import { parseFieldsToPdf } from "./parse-fields-to-pdf";

export const submitLabSlip = async ({
  data,
  name,
  patient,
  populatePdf,
  showWarn,
  showSuccess,
}: {
  data: any;
  name: string;
  patient: Patient;
  populatePdf: any;
  showWarn: any;
  showSuccess: any;
}) => {
  if (!patient.patientId) return;
  const fieldsToSend = parseFieldsToPdf(data);
  const result: any = await populatePdf({
    patientId: patient.patientId,
    documentName: `${name} for ${patient.fullName} ${getToday()}`,
    documentId: ENV_CONFIG.BLOOD_SLIP_TEMPLATE_ID,
    fields: fieldsToSend,
    isDefaultData: true,
  });

  if (result.error) {
    showWarn({
      title: "Something went wrong...",
      description: "Unable to populate a lab slip",
    });
    return;
  }

  showSuccess({
    title: "Success",
    description: "Lab slip was successfully populated",
  });
};
