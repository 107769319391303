import React, { useState, useEffect } from "react";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";
import { TextArea } from "../../../../NewComponents/Common/TextArea";
import { RadioButtonsForm } from "../../../../NewComponents/Common/RadioButtonsForm";
import { CheckboxesList } from "../../../../NewComponents/Common/CheckboxesList";
import { replaceText } from "../../../../../utils/replaceText";
import { SurveyCheckboxes } from "../../../../NewComponents/Common/SurveyCheckboxes";
import { SurveyNavigationButtons } from "../../../Common/SurveyNavigationButtons";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";

function surveyToComponent(data: SurveyData, variable: string) {
  return data[variable];
}

function componentToSurvey(value: string, questionType: string) {
  const updatedValue = {
    [questionType]: value,
  };
  return updatedValue;
}

export function SurveyQuestionTemplete({
  data,
  onDone,
  question,
  isChildAccount,
}: SurveyComponentProps) {
  const [currentData, setData] = useState<string | boolean>(
    surveyToComponent(data, question?.value)
  );
  const { onBack } = useCustomSurveyStepper();

  useEffect(() => {
    setData(surveyToComponent(data, question?.value));
  }, [question]);

  const checkAnswerToSet = (data1) => {
    if (data1 === undefined) return undefined;
    if (data1 === "") return true;
    return Boolean(data1);
  };

  const [answer, setAnswer] = useState<null | boolean>(
    checkAnswerToSet(currentData)
  );

  const optionalText = isChildAccount ? "your child’s" : "your";

  useEffect(() => {
    if (currentData !== null) {
      setAnswer(checkAnswerToSet(currentData));
    }
  }, [question, currentData]);

  function updateData(value) {
    setAnswer(value);
    if (!value) {
      setData(value);
    } else {
      setData(null);
    }
  }
  function submit() {
    if (isEmpty(currentData)) return;

    sendSurveyQuestion(question?.value);
    onDone(componentToSurvey(currentData, question?.value));
  }

  function autocontinue(value: any, answer_?: boolean) {
    if (answer_) setAnswer(value);
    setData(value);

    sendSurveyQuestion(question?.value);
    onDone(componentToSurvey(value, question?.value));
  }
  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">
        {replaceText(question.label, "your/your child’s", optionalText)}
      </p>
      {question?.description && (
        <p className="survey-question__descr paragraph-font--color">
          {question?.description}
        </p>
      )}
      {question?.textArea && (
        <TextArea
          onChange={(value) => setData(value)}
          placeholder={question?.placeholder}
          maxRows={5}
          currentValue={currentData}
        />
      )}

      {question?.isInputTypeNumber && (
        <input
          className="text-area text-input__field"
          type="number"
          value={currentData as string}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (
              inputValue === "" ||
              (parseInt(inputValue, 10) >= 1 &&
                parseInt(inputValue, 10) <= question?.maxNumber)
            ) {
              setData(inputValue);
            }
          }}
          placeholder={question?.placeholder}
          min="1"
          max="10"
        />
      )}

      {question?.radioButtonsForm && (
        <RadioButtonsForm
          list={[
            {
              value: question?.options[0].value,
              label: question?.options[0].label,
              extra:
                answer === true ? (
                  <TextArea
                    onChange={(value) => setData(value)}
                    placeholder={question?.placeholder}
                    maxRows={5}
                    value={currentData}
                  />
                ) : (
                  false
                ),
            },
            {
              value: question?.options[1].value,
              label: question?.options[1].label,
            },
          ]}
          onChange={(value) =>
            currentData === undefined && !value
              ? autocontinue(value, true)
              : updateData(value)
          }
          currentValue={answer !== null ? answer : undefined}
        />
      )}

      {question?.checkboxesList && (
        <CheckboxesList
          id={question.value}
          list={question.options}
          currentValues={currentData}
          onChange={(value) => setData(value)}
        />
      )}
      {question?.otherCheckboxesList && (
        <SurveyCheckboxes
          id={question.value}
          list={question.options}
          currentValues={currentData}
          onChange={(value) => setData(value)}
        />
      )}

      {!question?.textArea &&
        !question?.radioButtonsForm &&
        !question?.checkboxesList &&
        !question?.isInputTypeNumber &&
        !question?.otherCheckboxesList && (
          <RadioButtonsList
            list={question.options}
            currentValue={
              currentData !== null ? (currentData as string) : undefined
            }
            onChange={(value) =>
              isEmpty(currentData) ? autocontinue(value) : setData(value)
            }
          />
        )}

      <SurveyNavigationButtons
        submit={submit}
        onBack={onBack}
        disabledContinue={/^\s*$/.test(currentData) || isEmpty(currentData)}
      />
    </div>
  );
}
