import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { Button } from "../../Common/Button";
import { ReactComponent as DoctorInGray } from "../../../../Assets/NewIcons/doctor-in-gray.svg";
import { getFollowUpDatePretty } from "../../../../utils/get-date-pretty";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { SubscriptionExpiredPopupGenerator } from "../SubscriptionExpiredPopup";
import {
  useGetCareTeamQuery,
  useGetPatientByIdQuery,
} from "../../../../features/api/patients";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { Skeleton } from "../../Common/Skeleton";
import { InfoBlock } from "../../Common/InfoBlock";
import {
  useGetAllEmployeesOnTimeOffQuery,
  useGetEmployeesByRoleQuery,
} from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { useGetTasksQuery } from "../../../../features/api/accutane";

enum STATES {
  LOADING,
  NO_INITIAL_VISIT,
  INITIAL_VISIT_SUBMITTED,
  UPCOMING_INITIAL_VISIT,
  EXPIRED_INITIAL_VISIT,
  FOLLOW_UP_VISIT,
  FOLLOW_UP_VISIT_OVERDUE,
  FOLLOW_UP_SUBMITTED,
}

export function FollowUpVisitCard({ patientId }: { patientId: string }) {
  const history = useHistory();
  const { showPopup } = useContext(PopupLayerContext);
  const [state, setState] = useState(STATES.LOADING);
  const patientQuery = useGetPatientByIdQuery(patientId);
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(patientId);
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(patientId);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(patientId);
  const allTimeOffsQuery = useGetAllEmployeesOnTimeOffQuery(null);
  const allProvidersQuery = useGetEmployeesByRoleQuery(USER_ROLES.PROVIDER);
  const careTeamQuery = useGetCareTeamQuery(patientId);

  const [isProviderOnLeave, setIsProviderOnLeave] = useState(false);
  const [isSubAvailable, setIsSubAvailable] = useState(false);

  const providerRecord = careTeamQuery?.data?.find(
    (record) => record.role === "provider" && record.isRemovable !== true
  );

  const tasksQuery = useGetTasksQuery({
    patientId,
  });

  const [meetingLink, setMeetingLink] = useState(null);

  function findMatchingProvider(patient: any, providers: any) {
    const stateToMatch = patient?.shippingInfo?.state || patient?.state;
    if (!stateToMatch) {
      return false;
    }
    const hasMatchingProvider = providers.some((provider) =>
      provider.stateLicenses?.some(
        (license) => license?.stateAbbreviation === stateToMatch
      )
    );
    return hasMatchingProvider;
  }

  const hasProviderAnyPendingTask =
    tasksQuery.data &&
    tasksQuery.data.some(
      (task) =>
        task?.employeeId === providerRecord?.employeeId &&
        task.status !== "COMPLETED" &&
        task.status !== "DELETED"
    );

  useEffect(() => {
    if (
      allTimeOffsQuery?.data &&
      allProvidersQuery?.data &&
      patientQuery?.data
    ) {
      const isEmployeeOnLeave = allTimeOffsQuery?.data.some(
        (leave) => leave.employeeId === providerRecord?.employeeId
      );
      const filteredProviders = allProvidersQuery?.data.filter(
        (item) => !item.archived && item.canCoverTemporaryPatients
      );
      const presentProviders = filteredProviders.filter(
        (provider) =>
          !allTimeOffsQuery?.data.some(
            (leave) => leave.employeeId === provider.id
          )
      );
      setIsProviderOnLeave(isEmployeeOnLeave);
      const isSubstitute = findMatchingProvider(
        patientQuery?.data,
        presentProviders
      );
      setIsSubAvailable(isSubstitute);
    }
  }, [allTimeOffsQuery, allProvidersQuery, patientQuery]);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        await patientQuery.refetch();
        await followUpsQuery.refetch();
        await appointmentsQuery.refetch();
        await subscriptionsQuery.refetch();
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (appointmentsQuery?.data) {
      const patientAppointment = appointmentsQuery.data.find(
        (appointment) =>
          appointment.status === "PENDING" || appointment.status === "CONFIRMED"
      );
      if (patientAppointment) {
        setMeetingLink(patientAppointment?.meetingLink);
      }
    }
  }, [appointmentsQuery]);

  useEffect(() => {
    const isLoading =
      patientQuery.isLoading ||
      followUpsQuery.isLoading ||
      appointmentsQuery.isLoading ||
      subscriptionsQuery.isLoading;
    if (isLoading) {
      setState(STATES.LOADING);
      return;
    }

    const isLoaded =
      patientQuery.isSuccess &&
      followUpsQuery.isSuccess &&
      appointmentsQuery.isSuccess &&
      subscriptionsQuery.isSuccess;

    if (!isLoaded) return;
    if (appointmentsQuery.data.length === 0) {
      if (
        window.localStorage.getItem(
          `appointment-submitted-${patientQuery.data.patientId}`
        )
      ) {
        setState(STATES.INITIAL_VISIT_SUBMITTED);
        return;
      }
      setState(STATES.NO_INITIAL_VISIT);
      return;
    }
    if (
      followUpsQuery.data.length === 0 ||
      (followUpsQuery.data.length === 1 &&
        !followUpsQuery.data[0].treatmentPlan?.nextFollowUpDate)
    ) {
      if (
        appointmentsQuery.data?.[0].endTime &&
        new Date(appointmentsQuery.data[0].endTime).getTime() < Date.now()
      ) {
        setState(STATES.EXPIRED_INITIAL_VISIT);
        return;
      }

      setState(STATES.UPCOMING_INITIAL_VISIT);
      return;
    }

    const nextFollowUpDate =
      followUpsQuery.data[0].treatmentPlan?.nextFollowUpDate;
    if (nextFollowUpDate) {
      if (nextFollowUpDate < Date.now()) {
        setState(STATES.FOLLOW_UP_VISIT_OVERDUE);
        return;
      }

      setState(STATES.FOLLOW_UP_VISIT);
      return;
    }

    setState(STATES.FOLLOW_UP_SUBMITTED);
  }, [patientQuery, followUpsQuery, appointmentsQuery, subscriptionsQuery]);

  const showFollowUpPopup = (membershipActive: boolean) => {
    if (!membershipActive) {
      showPopup(SubscriptionExpiredPopupGenerator());
    } else {
      history.push("/start-follow-up");
    }
  };

  const formatDate = (timestamp: any, timezone: any) => {
    if (!timestamp) return null;

    const date = new Date(timestamp);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: timezone || "America/New_York",
    };

    // Get the individual parts of the date
    const weekday = date.toLocaleDateString("en-US", {
      weekday: "short",
      timeZone: options.timeZone,
    });
    const month = date.toLocaleDateString("en-US", {
      month: "short",
      timeZone: options.timeZone,
    });
    const day = date.toLocaleDateString("en-US", {
      day: "numeric",
      timeZone: options.timeZone,
    });
    const year = date.toLocaleDateString("en-US", {
      year: "numeric",
      timeZone: options.timeZone,
    });

    // Add ordinal suffix to the day
    const dayWithOrdinal = day.replace(/\d+/, (d) => {
      const n = parseInt(d, 10);
      const suffix = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return d + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
    });

    return `${weekday} ${month} ${dayWithOrdinal}, ${year}`;
  };

  // Function to format timestamp to a readable time string
  const formatTime = (timestamp: any, timezone: any) => {
    const date = new Date(timestamp);
    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: timezone || "America/New_York",
    };
    let timeString = date.toLocaleTimeString(undefined, options);

    // Convert AM/PM to lowercase
    timeString = timeString.replace("AM", "am").replace("PM", "pm");
    timeString = timeString.replace(" ", "");

    return timeString;
  };

  function render() {
    const nextFollowUpDate =
      (followUpsQuery.isSuccess &&
        followUpsQuery.data.length > 0 &&
        followUpsQuery.data[0].treatmentPlan?.nextFollowUpDate) ||
      null;
    const timezone =
      (appointmentsQuery.isSuccess &&
        appointmentsQuery.data.length > 0 &&
        appointmentsQuery.data[0].inviteeTimezone) ||
      null;
    const appointmentStartDate =
      formatDate(
        appointmentsQuery.isSuccess &&
          appointmentsQuery.data.length > 0 &&
          appointmentsQuery.data[0].startTime,
        timezone
      ) || null;
    const appointmentStartTime =
      formatTime(
        appointmentsQuery.isSuccess &&
          appointmentsQuery.data.length > 0 &&
          appointmentsQuery.data[0].startTime,
        timezone
      ) || null;
    const appointmentEndTime =
      formatTime(
        appointmentsQuery.isSuccess &&
          appointmentsQuery.data.length > 0 &&
          appointmentsQuery.data[0].endTime,
        timezone
      ) || null;
    const hasSubscription = !!(
      subscriptionsQuery.data &&
      subscriptionsQuery.data.find(
        ({ type, status }) => type === "membership" && status === "active"
      )
    );

    const renderProviderOnLeaveWarning = () =>
      isProviderOnLeave && hasProviderAnyPendingTask && !isSubAvailable ? (
        <div style={{ marginBottom: "15px" }}>
          <InfoBlock
            text={
              <>
                Your provider is out-of-office and you may experience a delayed
                reply
              </>
            }
            className="info-size"
            view="warn"
          />
        </div>
      ) : null;

    switch (state) {
      case STATES.LOADING:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                <Skeleton fullWidth />
              </p>
              <p className="follow-up-visit-card__descr">
                <Skeleton count={3} />
              </p>
            </div>
            <div className="follow-up-visit-card__actions-block">
              <Skeleton />
            </div>
          </div>
        );
      case STATES.NO_INITIAL_VISIT:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                You haven't scheduled your initial visit yet
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                Complete the sign up process and select a time for your initial
                visit.
              </p>
            </div>
            {renderProviderOnLeaveWarning()}
            <div className="follow-up-visit-card__actions-block">
              <Button
                text="schedule my free visit"
                onClick={() => history.push("/submit-appointment")}
                size="small"
              />
            </div>
          </div>
        );
      case STATES.INITIAL_VISIT_SUBMITTED:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Upcoming initial visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                You've submitted your initial visit. Check your email for a
                confirmation from Calendly with your zoom link.
              </p>
            </div>
            <div className="follow-up-visit-card__actions-block">
              <Button text="schedule my free visit" disabled size="small" />
            </div>
          </div>
        );
      case STATES.UPCOMING_INITIAL_VISIT:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Upcoming initial visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              {meetingLink ? (
                <p className="follow-up-visit-card__descr">
                  Your initial consultation (via Zoom) is coming up on:
                  <br />
                  <span className="follow-up-visit-card__time">
                    {appointmentStartDate &&
                    appointmentStartTime &&
                    appointmentEndTime
                      ? `${appointmentStartTime} on ${appointmentStartDate}`
                      : "N/A"}
                  </span>
                  <br />
                  Check your email for a confirmation from Calendly with your
                  zoom link.
                </p>
              ) : (
                <p className="follow-up-visit-card__descr">
                  We noticed that you don’t have an initial consultation
                  scheduled!
                </p>
              )}
            </div>
            <div className="follow-up-visit-card__actions-block">
              <Button
                text={meetingLink ? "reschedule" : "Schedule Now"}
                onClick={() => history.push(`/reschedule/${patientId}`)}
                size="small"
              />
            </div>
            {meetingLink === null ? null : (
              <div className="follow-up-visit-card__actions-block follow-up-visit-card-mobile">
                <Button
                  text="open zoom link"
                  onClick={() => window.open(meetingLink, "_blank")}
                  size="small"
                />
              </div>
            )}
          </div>
        );
      case STATES.EXPIRED_INITIAL_VISIT:
        return (
          <div className="follow-up-visit-card__info attention">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Reschedule your initial visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                Your original initial consult (via Zoom) was scheduled on:
                <br />
                <span className="follow-up-visit-card__time">
                  {appointmentStartDate &&
                  appointmentStartTime &&
                  appointmentEndTime
                    ? `${appointmentStartTime} on ${appointmentStartDate}`
                    : "N/A"}
                </span>
                <br />
                Reschedule using the button below.
              </p>
            </div>
            <div className="follow-up-visit-card__actions-block">
              <Button
                text="reschedule visit"
                onClick={() => history.push(`/reschedule/${patientId}`)}
                size="small"
              />
            </div>
          </div>
        );
      case STATES.FOLLOW_UP_VISIT:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Follow-up visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                Your provider recommended to submit a follow-up on
                <br />
                <span className="follow-up-visit-card__time">
                  {nextFollowUpDate
                    ? getFollowUpDatePretty(nextFollowUpDate)
                    : "N/A"}
                </span>
              </p>
            </div>
            {renderProviderOnLeaveWarning()}
            <div className="follow-up-visit-card__actions-block">
              <Button
                text="start the visit"
                onClick={() => showFollowUpPopup(hasSubscription)}
                size="small"
              />
            </div>
          </div>
        );
      case STATES.FOLLOW_UP_VISIT_OVERDUE:
        return (
          <div className="follow-up-visit-card__info attention">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Follow-up visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                You were recommended to submit a follow-up on
                <br />
                <span className="follow-up-visit-card__time">
                  {nextFollowUpDate
                    ? getFollowUpDatePretty(nextFollowUpDate)
                    : "N/A"}
                </span>
                <br />
                Let your care team know how you're doing!
              </p>
            </div>
            {renderProviderOnLeaveWarning()}
            <div className="follow-up-visit-card__actions-block">
              <Button
                text="start the visit"
                onClick={() => showFollowUpPopup(hasSubscription)}
                size="small"
              />
            </div>
          </div>
        );
      case STATES.FOLLOW_UP_SUBMITTED:
        return (
          <div className="follow-up-visit-card__info">
            <div className="follow-up-visit-card__text-block">
              <p className="follow-up-visit-card__title">
                Follow-up visit
                <p className="follow-up-visit-card__attention">!</p>
              </p>
              <p className="follow-up-visit-card__descr">
                You have submitted your follow-up. Your provider will respond
                shortly.
              </p>
            </div>
            {renderProviderOnLeaveWarning()}
            <div className="follow-up-visit-card__actions-block">
              <Button text="start the visit" disabled size="small" />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="follow-up-visit-card">
      {render()}
      <div className="follow-up-visit-card__img">
        <DoctorInGray />
      </div>
    </div>
  );
}
