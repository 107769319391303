import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import {
  useGetMyProfileQuery,
  useGetPatientByIdQuery,
} from "../../../features/api/patients";
import { getSessionState, setActivePatient } from "../../../features/session";
import { Footer } from "../../NewComponents/Common/Footer";
import { Loader } from "../../NewComponents/Common/Loader";
import { Menu } from "../../NewComponents/Common/Menu";
import { NotificationLayerContext } from "../../NewComponents/Common/NotificationLayer";
import { PopupLayerContext } from "../../NewComponents/Common/PopupLayer";
import { SimplePageLayout } from "../../NewComponents/Common/SimplePageLayout";
import { PatientPickerPopupGenerator } from "../../NewComponents/Patient/PatientPickerPopup";
import { RescheduleAppointmentPage } from "../../NewPages/Common/RescheduleAppointmentPage";
import { BuyProductsPage } from "../../NewPages/Patient/BuyProducts";
import { BuySubscriptionPage } from "../../NewPages/Patient/BuySubscription";
import { FollowUpSurveyPage } from "../../NewPages/Patient/FollowUpSurvey";
import { PatientHomePage } from "../../NewPages/Patient/Home";
import { InitiateMembershipPage } from "../../NewPages/Patient/InitiateMembership";
import { SkinSurveyPage } from "../../NewPages/Patient/SkinSurvey";
import { SubmitAppointmentPage } from "../../NewPages/Patient/SubmitAppointment";
import { PatientDashboard } from "../../Pages/Patient/PatientDashboard";
import BloodWorkPage from "../../NewPages/Patient/BloodWork";
import { processHolidays } from "./holidays";
import "./style.scss";
import { ChatLayer } from "../../NewComponents/Common/ChatLayer";
import { useGetAppointmentsByPatientIdQuery } from "../../../features/api/appointments";
import { useGetSubscriptionsByPatientIdQuery } from "../../../features/api/payment";
import { analyticsIdentify } from "../../../shared/analytics";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import PregnancyWorkPage from "../../NewPages/Patient/Pregnancy";
import { SurveyLastStep } from "../../NewPages/Common/SurveyLastStep";
import { BillingPage } from "../../NewPages/Patient/Billing";

export function PatientView() {
  const history = useHistory();
  const { showPopup } = useContext(PopupLayerContext);
  const { showInfo, showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const dispatch = useDispatch();
  const { userId, userRole } = useSelector(getSessionState);
  if (!userId) {
    throw new Error(
      "Cannot render patient view: no account ID found in session store"
    );
  }
  const location = useLocation();
  const { data, isSuccess } = useGetMyProfileQuery(null);
  useGetAppointmentsByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  useGetSubscriptionsByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const isChildAccount = !!patientQuery?.data?.parentInfo;
  const isMedicalBackground =
    !!patientQuery?.data?.medicalBackground?.skinSurvey;

  useEffect(() => {
    processHolidays(showInfo);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const filteredData = data.filter((patient) => !patient.archived);
      if (filteredData.length === 0) {
        showError({
          title: "Something went wrong...",
          description: "Looks like there is no such patient in database.",
        });
        return;
      }
      dispatch(setActivePatient(filteredData[0].patientId));
      if (filteredData.length > 1) {
        showPopup(PatientPickerPopupGenerator());
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (patientQuery.isSuccess) {
      analyticsIdentify(patientQuery.data);
    }
  }, [patientQuery]);

  function renderMainArea() {
    if (!activePatientId || !userRole) {
      return (
        <div className="main-area__loader-wrap">
          <div className="main-area__loader">
            <Loader />
          </div>
        </div>
      );
    }

    return (
      <Switch location={location}>
        <Route path="/bloodwork">
          <Menu />
          <BloodWorkPage patientId={activePatientId} />
        </Route>
        <Route path="/pregnancy-work">
          <Menu />
          <PregnancyWorkPage patientId={activePatientId} />
        </Route>
        <ChatLayer identity={activePatientId} userRole={userRole}>
          <Route path="/reschedule/:patientId">
            <Menu />
            <div className="main-area">
              <RescheduleAppointmentPage />
              <Footer />
            </div>
          </Route>
          <Route path="/submit-appointment">
            <SimplePageLayout onBack={() => history.push("/")}>
              <SubmitAppointmentPage patientId={activePatientId} />
            </SimplePageLayout>
          </Route>
          <Route path="/all-set">
            <SimplePageLayout onBack={() => history.push("/")}>
              <SurveyLastStep />
            </SimplePageLayout>
          </Route>
          <Route path="/profile">
            <Menu />
            <div className="main-area">
              <PatientDashboard />
              <Footer />
            </div>
          </Route>
          <Route path="/billing">
            <Menu />
            <BillingPage />
            <Footer />
          </Route>
          <Route path="/document">
            <Menu />
            <div className="main-area">
              <PatientDashboard />
              <Footer />
            </div>
          </Route>
          <Route path="/initiate-membership">
            <Menu />
            <div className="main-area">
              <InitiateMembershipPage patientId={activePatientId} />
              <Footer />
            </div>
          </Route>
          <Route path="/buy-products">
            <Menu />
            <div className="main-area">
              <BuyProductsPage />
              <Footer />
            </div>
          </Route>
          <Route path="/buy-membership">
            <Menu />
            <div className="main-area">
              <BuySubscriptionPage />
              <Footer />
            </div>
          </Route>
          <Route path="/start-follow-up">
            <SimplePageLayout onBack={() => history.push("/")}>
              <FollowUpSurveyPage />
            </SimplePageLayout>
          </Route>
          <Route path="/skin-survey">
            <SimplePageLayout>
              <SkinSurveyPage
                isMedicalBackground={isMedicalBackground}
                isChildAccount={isChildAccount}
              />
            </SimplePageLayout>
          </Route>
          <Route exact path="/">
            <Menu />
            <div className="main-area">
              <PatientHomePage />
              <Footer />
            </div>
          </Route>
          <ChatWidget />
        </ChatLayer>
      </Switch>
    );
  }

  return renderMainArea();
}

export default PatientView;
