import React from "react";
import { useHistory } from "react-router-dom";
import { BloodWorkForm } from "../../../NewComponents/Patient/BloodWorkPopup/BloodWorkForm";

export default function BloodWorkPage() {
  const history = useHistory();

  const onClose = () => {
    history.replace("/");
  };

  return <BloodWorkForm onClose={onClose} />;
}
