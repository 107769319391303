import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as WomenWithGift } from "../../../../Assets/NewIcons/women-with-gift.svg";
import "./style.scss";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { ReferralLinkPopupGenerator } from "../ReferralLinkPopup";
import { getSessionState } from "../../../../features/session";
import { Skeleton } from "../../Common/Skeleton";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { hasActiveMembership } from "../../../../utils/has-active-membership";

export function ReferralCard() {
  const history = useHistory();
  const { showPopup } = useContext(PopupLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const subscriptionsQuery =
    useGetSubscriptionsByPatientIdQuery(activePatientId);
  const isLoaded = subscriptionsQuery.isSuccess;

  const hasMembership = isLoaded
    ? hasActiveMembership(subscriptionsQuery.data)
    : false;

  return (
    <div className="referral-card">
      <div className="referral-card__info">
        <p className="subheading-font subheading-font--margin subheading-font--color">
          Give one month, get one month free!
        </p>
        <p className="paragraph-font paragraph-font--color paragraph-font--margin">
          For every friend who signs up for their first month, you each get one
          month free for up to 10 friends.
        </p>
        {activePatientId ? (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              if (hasMembership) {
                showPopup(
                  ReferralLinkPopupGenerator({ patientId: activePatientId })
                );
              } else {
                history.push(`initiate-membership`);
              }
            }}
            className="link-font link-font--color"
          >
            {hasMembership ? (
              <>get link </>
            ) : (
              <>sign up for a membership to unlock your link </>
            )}
          </a>
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="referral-card__img">
        <WomenWithGift />
      </div>
    </div>
  );
}
