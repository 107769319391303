import React, { useEffect } from "react";
import { Box, Typography, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as InfoSign } from "../../../../../Assets/NewIcons/info-rounded.svg";
import { useGetPatientByIdQuery } from "../../../../../features/api/patients";
import { getSessionState } from "../../../../../features/session";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";

const ListItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "flex-start",
  "&:before": {
    content: '"•"',
    color: theme.palette.text.primary,
    fontSize: theme.typography.h6.fontSize,
    marginRight: theme.spacing(1),
  },
}));

export default function Welcome() {
  const { activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const { updateValues, setIsNextDisabled } = usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(true);
  }, []);

  useEffect(() => {
    if (patientQuery.data) {
      updateValues({ patient: patientQuery.data });
      setIsNextDisabled(false);
    }
  }, [patientQuery]);

  return (
    <Grid container direction="column" p={2}>
      <Typography variant="h5" gutterBottom>
        Taking a pregnancy test
      </Typography>
      <Box display="flex" alignItems="center">
        <InfoSign />
        <Typography variant="h6" ml={1}>
          What you need to know:
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{ pl: 4, listStyleType: "disc", rowGap: 1, mt: 1 }}
      >
        <ListItem>
          <Typography variant="body1" color="text.secondary">
            iPledge requires two (2) negative pregnancy tests 30 days apart to
            start therapy – the “30 day wait”.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1" color="text.secondary">
            You will be enrolled into iPledge <strong>after</strong> we review
            your first pregnancy test.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1" color="text.secondary">
            Your first two tests must be done in a lab or medical office.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1" color="text.secondary">
            After you start your first dose, your tests can be done at home.
            iPledge requires a negative pregnancy test every 30 days to be
            confirmed for your next dose.
          </Typography>
        </ListItem>
      </Box>
    </Grid>
  );
}
