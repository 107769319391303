import React, { createContext, useContext, useState, useMemo } from "react";

type HotReloadContext = {
  shouldReload: boolean;
  setShouldReload: (reload: boolean) => void;
};

const DEFAULT_VALUE = {
  setShouldReload: () => null,
  shouldReload: false,
};

const HotReloadContext = createContext<HotReloadContext>(DEFAULT_VALUE);

export function HotReloadProvider({ children }: { children: React.ReactNode }) {
  const [shouldReload, changeReload] = useState(false);

  const setShouldReload = (reload: boolean) => {
    changeReload(reload);
  };

  const contextValue = useMemo(
    () => ({ setShouldReload, shouldReload }),
    [setShouldReload, shouldReload]
  );

  return (
    <HotReloadContext.Provider value={contextValue}>
      {children}
    </HotReloadContext.Provider>
  );
}

export const useHotReload = () => useContext(HotReloadContext);
