import React, { useMemo, useState } from "react";
import { Skeleton, Tooltip, Grid } from "@mui/material";
import { Container } from "./styles";
import { TaskHistory } from "../../../../../types/TaskHistory";
import { useMobile } from "../../../../../hooks/use-mobile";

export function StatusBadge({
  status,
  histories,
}: {
  status: string;
  histories: TaskHistory[];
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isMobile } = useMobile();

  const getDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const getStatus = (statusLabel: string) => {
    switch (statusLabel.trim()) {
      case "IN_PROGRESS":
        return "In-progress";
      case "COMPLETED":
        return "Completed";
      case "REJECTED":
        return "Rejected";
      case "ASSIGNED": {
        return "Assigned";
      }
      case "REQUISITION_CREATED": {
        return "Requisition created";
      }
      case "PARTIAL_RESULTS": {
        return "Analyzing results";
      }
      case "CREATED": {
        return "Created";
      }
      default:
        return status;
    }
  };

  const getInfo = () => (
    <Grid container direction="column">
      {histories?.map((history) => (
        <Grid item key={history.id} m={1}>
          {`${getDate(history.timestamp)}: ${getStatus(history.status)}`}
        </Grid>
      ))}
    </Grid>
  );

  const getAttributes = () => {
    switch (status) {
      case "IN_PROGRESS":
        return {
          label: "In-progress",
          background: "#F3FAF2",
          color: "#60B257",
          tooltip: getInfo(),
        };
      default:
        return null;
    }
  };

  const attributes = useMemo(() => getAttributes(), [status]);

  return attributes && attributes.label !== "Not found" ? (
    <Tooltip
      title={attributes.tooltip}
      open={isTooltipOpen}
      onClick={(e) => {
        e.stopPropagation();
        setIsTooltipOpen(!isTooltipOpen);
      }}
      onMouseLeave={() => !isMobile && setIsTooltipOpen(false)}
      onMouseEnter={() => !isMobile && setIsTooltipOpen(true)}
    >
      <Container background={attributes.background} color={attributes.color}>
        {attributes.label}
      </Container>
    </Tooltip>
  ) : (
    attributes !== null && <Skeleton />
  );
}
