/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "../../../Common/Material/Button";
import { CustomList, CustomListItem, CustomNumericList } from "./styles";
import { FastingBanner } from "../../../Common/FastingBanner";
import { FileButton } from "../../../Common/Material/FileButton";

export const steps = [
  {
    label: "Print the lab slip",
    description: (
      <Box>
        <Typography variant="subtitle2">
          Once your lab slip is created, please download, print, and bring it
          with you to the lab. The lab slip will also be stored in your
          Documents tab.
        </Typography>
      </Box>
    ),
    completed: true,
    key: "download_lab_slip",
  },
  {
    label:
      "Visit your medical office and complete your hCG test (urine or blood accepted)",
    description: (
      <Box>
        <Typography variant="body2" color="#6C6A7C">
          Acceptable settings include:
        </Typography>
        <CustomList>
          <CustomListItem>
            <Typography variant="body2">Primary care doctor</Typography>
          </CustomListItem>
          <CustomListItem>
            <Typography variant="body2">Urgent care</Typography>
          </CustomListItem>
          <CustomListItem>
            <Typography variant="body2">Planned Parenthood</Typography>
          </CustomListItem>
        </CustomList>
      </Box>
    ),
    completed: true,
    key: "visit_medical_office",
  },
  {
    label: "Submit a PDF of your results",
    description: (
      <Box>
        <Typography variant="body2" color="#6C6A7C">
          The PDF must include:
        </Typography>
        <CustomNumericList>
          <CustomListItem>
            <Typography variant="body2">
              Medical office's professional letterhead
            </Typography>
          </CustomListItem>
          <CustomListItem>
            <Typography variant="body2">
              Your full name and Date of Birth on every page
            </Typography>
          </CustomListItem>
          <CustomListItem>
            <Typography variant="body2">
              Name of the licensed provider who tested you
            </Typography>
          </CustomListItem>
          <CustomListItem>
            <Typography variant="body2">
              The result and date of the test
            </Typography>
          </CustomListItem>
        </CustomNumericList>
        <Typography variant="body2" color="#6C6A7C" fontWeight={500} mt={1}>
          The results can be faxed to (917) 254-4419 or uploaded directly to
          your
          <Link
            to="/document"
            target="_blank"
            style={{ textDecoration: "none" }}
          >{` Documents tab `}</Link>
          in your Honeydew account.
        </Typography>
      </Box>
    ),
    completed: false,
    key: "submit_results",
  },
  {
    label: "Once done, mark your test as complete by using the button below",
    description: (
      <Button
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
        onClick={() => {}}
        view="secondary"
        size="small"
        text="MARK HCG TEST AS COMPLETE"
      />
    ),
    completed: false,
    key: "mark-complete",
  },
  {
    label: "Your provider will review the results",
    description: undefined,
    completed: false,
    key: "review",
  },
];

export const getSteps = ({
  onComplete,
  requestLabSlip,
  loading,
  generatingSlip,
  needsFasting,
  canComplete,
  document,
  oldInProgress,
}: {
  onComplete: () => void;
  requestLabSlip: () => void;
  loading?: boolean;
  needsFasting?: boolean;
  generatingSlip?: boolean;
  canComplete?: boolean;
  oldInProgress?: boolean;
  document?: { name?: string; url?: string };
}) =>
  steps.map((step) => {
    if (step.key === "download_lab_slip") {
      return {
        ...step,
        description: (
          <Box>
            <Typography variant="subtitle2" mb={1}>
              Once your lab slip is created, please download, print, and bring
              it with you to the lab. The lab slip will also be stored in your
              Documents tab.
            </Typography>
            {!oldInProgress &&
              (document?.url ? (
                <FileButton name={`${document.name}.pdf`} url={document.url} />
              ) : (
                <Button
                  text="Request lab slip"
                  onClick={requestLabSlip}
                  size="small"
                  isLoading={generatingSlip}
                />
              ))}
          </Box>
        ),
      };
    }
    if (step.key === "mark-complete") {
      return {
        ...step,
        description: (
          <Button
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
            onClick={onComplete}
            isLoading={loading}
            disabled={!canComplete || loading}
            view="secondary"
            size="small"
            text="MARK HCG TEST AS COMPLETE"
          />
        ),
      };
    }
    if (step.key === "visit_medical_office" && needsFasting) {
      return {
        ...step,
        description: (
          <Box>
            <FastingBanner />
            <Typography variant="body2" color="#6C6A7C" mt={1}>
              Acceptable settings include:
            </Typography>
            <CustomList>
              <CustomListItem>
                <Typography variant="body2">Primary care doctor</Typography>
              </CustomListItem>
              <CustomListItem>
                <Typography variant="body2">Urgent care</Typography>
              </CustomListItem>
              <CustomListItem>
                <Typography variant="body2">Planned Parenthood</Typography>
              </CustomListItem>
            </CustomList>
          </Box>
        ),
      };
    }
    return step;
  });
