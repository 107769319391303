import axios from "axios";
import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";
import { GraphQLResponse } from "../../../types/GraphQL";
import { LabCatalog } from "../../../types/LabCatalog";

export const getLabPresets = async (): Promise<LabCatalog[]> => {
  try {
    const token = await AuthenticationService.getAccessToken();
    const result = await axios.post<
      GraphQLResponse<{ getAllPresets: LabCatalog[] }>
    >(
      `${ENV_CONFIG.LAB_SERVICE_GRAPHQL_URL}`,
      {
        query: `
          query getAllPresets {
            getAllPresets {
              id
              name
              type
              tests
            }
          }
        `,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.data.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data.data.getAllPresets;
  } catch (error: any) {
    logError("Error occurred. Cannot get lab locations", error);
    throw error;
  }
};
