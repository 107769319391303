import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CareCoordinatorsTable } from "../../../Components/Administrator/CareCoordinatorsTable";
import { EnrollmentCoordinatorsTable } from "../../../Components/Administrator/EnrollmentCoordinatorsTable";
import { ProvidersTable } from "../../../Components/Administrator/ProvidersTable";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.css";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { USER_ROLES } from "../../../../types/Main";
import { TimeOffPopupGenerator } from "../TimeOffPopupGenerator";

const views = [
  {
    key: "providers",
    label: "Providers",
    children: <ProvidersTable />,
  },
  {
    key: "careCoordinators",
    label: "Care Coordinators",
    children: <CareCoordinatorsTable />,
  },
  {
    key: "enrollmentCoordinators",
    label: "Enrollment Coordinators",
    children: <EnrollmentCoordinatorsTable />,
  },
];

export function EmployeesDashboard() {
  const [currentView, setCurrentView] = useState(views[0].key);
  const { showPopup } = useContext(PopupLayerContext);
  const [role, setRole] = useState("provider");

  useEffect(() => {
    if (currentView === "providers") setRole("provider");
    else if (currentView === "careCoordinators") setRole("care-coordinator");
    else if (currentView === "enrollmentCoordinators")
      setRole("enrollment-coordinator");
    else setRole("provider");
  }, [currentView]);

  useEffect(() => {
    setCurrentView(views[0].key);
  }, []);

  return (
    <div className="greeting-container">
      <div className="employees-dashboard__header">
        <div>
          <h1 className="employees-dashboard__header-title">
            Manage Honeydew Team
          </h1>
          <p className="employees-dashboard__header-subtitle">
            Edit details, add a new team member, remove employees etc.
          </p>
        </div>
        <div className="employees-dashboard__header-buttons">
          {role !== "enrollment-coordinator" && (
            <div>
              <Button
                size="small"
                className="employees-dashboard__button--secondary"
                text="+ ADD TIME OFF"
                onClick={() => showPopup(TimeOffPopupGenerator(role, "create"))}
              />
            </div>
          )}
          <div>
            <Button
              size="small"
              className="employees-dashboard__button--primary"
              text="+ NEW TEAM Member"
              onClick={() => showPopup(EmployeePopupGenerator(undefined, role))}
            />
          </div>
        </div>
      </div>
      <div className="tables-block">
        <Tabs
          items={views}
          initialTab={views[0].label}
          onChange={(tabLabel) => {
            const selectedView = views.find((view) => view.label === tabLabel);
            setCurrentView(selectedView?.key || views[0].key);
          }}
        />
      </div>
    </div>
  );
}
