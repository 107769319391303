import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { PregnancyWorkForm } from "../../../NewComponents/Patient/PregnancyTestPopup/PregnancyWorkForm";
import { getLatestTaskByType } from "../../../../utils/get-latest-task-by-type";
import { getSessionState } from "../../../../features/session";
import { useGetTasksQuery } from "../../../../features/api/accutane";
import { PregnancyTestTask } from "../../../../types/Entities/PregnancyTestTask";

export default function PregnancyWorkPage() {
  const history = useHistory();
  const { activePatientId } = useSelector(getSessionState);
  const [pregnancyTestTask, setPregnancyTestTask] =
    useState<PregnancyTestTask | null>();

  const accutaneTaskQuery = useGetTasksQuery({
    patientId: activePatientId as string,
  });

  useEffect(() => {
    if (accutaneTaskQuery?.data) {
      const pregnancyTest = getLatestTaskByType(
        accutaneTaskQuery?.data,
        "completePregnancyTest"
      );
      setPregnancyTestTask(pregnancyTest);
    }
  }, [accutaneTaskQuery]);

  const onClose = () => {
    history.replace("/");
  };

  return !pregnancyTestTask ? (
    <Skeleton variant="rectangular" height={400} />
  ) : (
    <PregnancyWorkForm
      onClose={onClose}
      pregnancyTestTask={pregnancyTestTask}
    />
  );
}
