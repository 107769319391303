import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EnrollIpledgePopupGenerator from "../../../Pages/Provider/EnrollIpledgePopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import {
  getSessionState,
  setActivePatient,
} from "../../../../features/session";
import {
  ProviderShortMedicalBackgroundTabs,
  ProviderTabs,
} from "../../../../types/Provider";
import { logError } from "../../../../shared/logger";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { showEditPatientInfoPopup } from "../../../../features/editPatientInfo";
import DocumentOverviewPopupGenerator from "../../../NewComponents/Provider/DocumentOverviewPopup";
import { ReactComponent as CheckIcon } from "../../../../Assets/NewIcons/check-icon.svg";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import EmptyTaskView from "../../../Pages/Provider/EmptyTaskView";
import { ChatWidget } from "../../../NewComponents/Common/ChatWidget";
import { EditPatientInfoPopup } from "../../../Components/Common/EditPatientInfoPopup";
import IpledgeDetails from "../../../NewComponents/Provider/IpldedgeDetails";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import ProviderDocuments from "../../../NewComponents/Provider/Documents";
import { PatientActionButtons } from "../../../Components/Common/PatientActionButtons";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import PatientNotes from "../../../NewComponents/Common/PatientNotes";
import { getCareCoordinatorsList } from "../../../../features/careCoordinators";
import ExpandableInfo from "../../../NewComponents/ExpandableInfo";
import SignBloodSlipPopupGenerator from "../../../NewComponents/Common/SignBloodSleepPopup";
import SignIpledgeConsentPopupGenerator from "../../../NewComponents/Patient/SignIpledgeConsentPopup";
import { CareTeamBlock } from "../../../NewComponents/Administrator/CareTeamBlock";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import { getPatientActionsList } from "../../../NewComponents/Patient/WelcomeCard/actions";
import { getMedicineInfoState } from "../../../../features/medicine";
import { Button } from "../../../NewComponents/Common/Button";
import { concatShippingInfo } from "../../../../utils/concat-shipping-info";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { useGetAppointmentsByPatientIdQuery } from "../../../../features/api/appointments";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import {
  useGetAccutaneByPatientIdQuery,
  useGetDocumentsByPatientIdQuery,
  useGetTasksQuery,
} from "../../../../features/api/accutane";
import { InsurancePopupGenerator } from "../../../NewComponents/Patient/InsurancePopup";
import { Provider } from "../../../../types/Employee";
import { getPatientMonthlyTasks } from "../../../../utils/patient/patientMonthlyTasks";
import { getGovtIDDocuments } from "../../../../utils/patient/get-government-issued-id";
import AcceptIPledgePopupGenerator from "../../../Pages/Provider/AcceptIPledgePopup";
import { pregnancyCapabilityStatus } from "../../../../utils/patient/pregnancyCapabilityStatus";
import ReviewNotesSectionPopupGenerator from "../../../Pages/Common/ReviewNotesSectionPopup";

const mapTaskTypeToPopup = (type: string) => {
  switch (type) {
    // case "enrollPatient": {
    //   return EnrollIpledgePopupGenerator;
    // }
    case "acceptPatientIPledge": {
      return AcceptIPledgePopupGenerator;
    }
    case "reviewNotesSection": {
      return ReviewNotesSectionPopupGenerator;
    }
    default: {
      return false;
    }
  }
};

export function PatientDashboardPageForProvider() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupLayerContext);
  const { userId: employeeId, activePatientId } = useSelector(getSessionState);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [patientTimezone, setPatientTimezone] = useState(null);
  const accutaneTaskQuery = useGetTasksQuery({
    patientId: activePatientId as string,
  });
  const accutane = useGetAccutaneByPatientIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const tasksQuery = useGetTasksQuery(
    {
      patientId: activePatientId as string,
      employeeId: employeeId as string,
    },
    {
      skip: !activePatientId || !employeeId,
    }
  );
  const providerTasks = tasksQuery?.data?.filter(
    (task) => task.assignedTo?.id === employeeId
  );

  const documentsQuery = useGetDocumentsByPatientIdQuery(
    activePatientId as string,
    { skip: !activePatientId }
  );
  const [currentTab, setTab] = useState(
    ProviderTabs.TREATMENT_HISTORY as string
  );
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const providerQuery = useGetEmployeeQuery(employeeId as string, {
    skip: !employeeId,
  });
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const appointmentsQuery = useGetAppointmentsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const { userId: patientId } = useParams<{ userId: string }>();

  useEffect(() => {
    dispatch(setActivePatient(patientId || null));
  }, [patientId]);
  useEffect(
    () => () => {
      dispatch(setActivePatient(null));
    },
    []
  );

  useEffect(() => {
    if (appointmentsQuery?.data && appointmentsQuery?.data.length > 0) {
      setAppointmentDate(appointmentsQuery.data[0].startTime);
      setPatientTimezone(appointmentsQuery.data[0]?.inviteeTimezone);
    }
  }, [appointmentsQuery.data]);

  const openInsuranceInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (patientQuery.data)
      showPopup(InsurancePopupGenerator({ patient: patientQuery.data }));
  };

  const isLoaded =
    patientQuery.isSuccess &&
    followUpsQuery.isSuccess &&
    appointmentsQuery.isSuccess &&
    medicationsQuery.isSuccess &&
    accutane.isSuccess &&
    subscriptionsQuery.isSuccess &&
    accutaneTaskQuery.isSuccess;

  // todo add type
  const providerTodos: any[] | null =
    providerTasks?.map((task) => ({
      text: task.message,
      check: () =>
        !(
          task.status?.toUpperCase() === "COMPLETED" ||
          task.status?.toUpperCase() === "DELETED"
        ),
      action: () => {
        const linkedDocument = documentsQuery.data?.find(
          (doc) => doc.id === task.compositeKey.split("_")[2]
        );
        if (
          patientQuery.data &&
          accutane.data &&
          task.type === "signIpledgeConsent"
        ) {
          showPopup(
            SignIpledgeConsentPopupGenerator(
              patientQuery.data,
              "Provider",
              accutane.data,
              task.id,
              task.compositeKey
            )
          );
        }
        if (patientQuery.data && employeeId && task.type === "signBloodSlip") {
          showPopup(
            SignBloodSlipPopupGenerator({
              documentGroupId: task.document?.documentGroupId,
              inviteId: task.document?.inviteId,
              role: "Provider",
              patient: patientQuery.data,
              taskId: task.id,
              compositeKey: task.compositeKey,
              employeeId,
            })
          );
        }
        if (linkedDocument && patientId) {
          showPopup(
            DocumentOverviewPopupGenerator(
              linkedDocument.title,
              patientId,
              task.compositeKey.split("_")[2],
              linkedDocument.url,
              employeeId as string
            )
          );
        } else {
          const mappedPopup = mapTaskTypeToPopup(task.type);
          if (
            mappedPopup &&
            (accutane.data || task.type === "reviewNotesSection") &&
            patientQuery.data &&
            employeeId
          ) {
            showPopup(
              mappedPopup({
                ...accutane.data,
                taskId: task.id,
                userId: patientQuery.data.patientId,
                employeeId,
                provider: providerQuery.data as Provider,
                patient: patientQuery.data,
                documentGroupId: task.document?.documentGroupId,
                inviteId: task.document?.inviteId,
                role: "Provider",
                compositeKey: task.compositeKey,
              })
            );
          }
        }
      },
    })) || null;

  function renderActionItems() {
    return (
      providerTodos
        ?.filter(({ check }) => check())
        .map(({ text, check, action }) => (
          <div
            className={`action-items__item${!check() ? " checked" : ""}`}
            onClick={() => action()}
          >
            <div className="action-items__checkbox">
              <CheckIcon className="action-items__check-icon" />
            </div>
            <p className="action-items__text">{text}</p>
            <ChevronIcon className="action-items__chevron" />
          </div>
        )) || null
    );
  }

  const actionItems = renderActionItems();

  const tabsItems = [
    {
      label: ProviderShortMedicalBackgroundTabs.TODO,
      key: "todos-tab",
      children: (
        <div>
          <div>{actionItems?.length ? actionItems : <EmptyTaskView />}</div>
        </div>
      ),
      counter: actionItems?.length ?? undefined,
    },
    {
      label: ProviderTabs.CARE_TEAM,
      key: "care-team",
      children: <CareTeamBlock />,
    },
  ];

  const providerTabsItems = [
    {
      label: ProviderTabs.TREATMENT_HISTORY,
      key: "treatment-history-tab",
    },
    {
      label: ProviderTabs.DOCUMENTS,
      key: "documents-tab",
    },
  ];

  const onTabChange = (tab: string) => {
    setTab(tab);
  };

  const patientMonthlyTasks = getPatientMonthlyTasks(
    accutane.data || null,
    accutaneTaskQuery.data
  );

  const govtIDDocuments = getGovtIDDocuments(patientId);

  console.log("monthly", patientMonthlyTasks);

  const patientTasks = isLoaded
    ? getPatientActionsList({
        accutane: accutane.data || null,
        patient: patientQuery.data,
        medications: medicationsQuery.data || [],
        followUps: followUpsQuery.data || [],
        appointments: appointmentsQuery.data || [],
        subscriptions: subscriptionsQuery.data || [],
        accutaneTasks: accutaneTaskQuery.data || [],
        showPopup,
        history,
        govtIDDocuments,
        patientMonthlyTasks,
      })
    : [];

  const visiblePatientTasks = patientTasks
    .filter((task) => task.check())
    .map((task) => ({
      label: task.text,
      status: task.status,
      histories: task.histories,
    }));

  const activePatientShipping = {
    address1: patientQuery.data?.shippingInfo?.addressLine1,
    address2: patientQuery.data?.shippingInfo?.addressLine2,
    city: patientQuery.data?.shippingInfo?.city,
    state: patientQuery.data?.shippingInfo?.state,
    zipCode: patientQuery.data?.shippingInfo?.zipCode,
  };

  const shippingInfo = concatShippingInfo(activePatientShipping);
  const expandableInfo = [
    {
      title: "Parent's contact information",
      items: [
        {
          label: "Parent name",
          description: patientQuery.data?.parentInfo?.name ?? "N/A",
        },
        {
          label: "Parent phone",
          description: patientQuery.data?.parentInfo?.phone ?? "N/A",
        },
        {
          label: "Parent email",
          description: patientQuery.data?.parentInfo?.email ?? "N/A",
        },
      ],
      key: "contactInformation",
    },
    {
      title: "Shipping address",
      items: [
        {
          label: shippingInfo,
        },
      ],
      key: "shippingInfo",
    },
    {
      title: "Health insurance",
      items: [
        {
          label: "Member ID",
          description: patientQuery.data?.insurance?.memberId ?? "N/A",
        },
        {
          label: "Insurance name",
          description: patientQuery.data?.insurance?.insuranceName ?? "N/A",
        },
        {
          label: "Policy holder name",
          description: patientQuery.data?.insurance?.policyHolderName ?? "N/A",
        },
        {
          label: "Group number",
          description: patientQuery.data?.insurance?.groupNumber ?? "N/A",
        },
        {
          label: "RxBin number",
          description: patientQuery.data?.insurance?.rxBinNumber ?? "N/A",
        },
      ],
      key: "healthInsurance",
      extra: (
        <div className="edit-button">
          <EditIcon onClick={openInsuranceInfo} />
        </div>
      ),
    },
    {
      title: "Patient's current tasks",
      items: visiblePatientTasks,
      key: "patientCurrentTasks",
    },
  ];

  const patientInfo =
    patientQuery.data &&
    (moment().diff(patientQuery.data.dateOfBirth, "years") >= 18 ||
      !patientQuery.data.parentInfo)
      ? expandableInfo.slice(1)
      : expandableInfo;

  return (
    <>
      <EditPatientInfoPopup />
      <div className="patient-details-dashboard">
        <div className="dashboard-info-container">
          <div className="dashboard-info-wrapper">
            <div className="dashboard-short-info">
              <FollowUpCard />
              <IpledgeDetails
                patientId={activePatientId || undefined}
                isNotPregnantCapability={pregnancyCapabilityStatus(
                  patientQuery?.data
                )}
              />
              <div className="dashboard-short-info__patient-info">
                <div>
                  {patientInfo.map((it) => (
                    <ExpandableInfo
                      title={it.title}
                      items={it.items}
                      extra={it.extra}
                      key={it.key}
                    />
                  ))}
                </div>
              </div>
              <div className="dashboard-short-info__patient-notes">
                <PatientNotes />
              </div>
              <Tabs items={tabsItems} />
            </div>
            <div className="dashboard-detailed-info">
              <Tabs
                className="dashboard-detailed-info__tabs"
                items={providerTabsItems}
                onChange={onTabChange}
              />
              {currentTab === ProviderTabs.TREATMENT_HISTORY && (
                <>
                  <TreatmentHistory />
                  <PatientActionButtons />
                  <VisitsHistory
                    appointmentDate={appointmentDate}
                    patientTimezone={patientTimezone}
                  />
                </>
              )}
              {currentTab === ProviderTabs.DOCUMENTS &&
                (providerQuery.data && activePatientId ? (
                  <ProviderDocuments
                    userId={activePatientId}
                    employeeId={providerQuery.data?.id}
                  />
                ) : (
                  <Skeleton fullWidth flex style={{ height: 200 }} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
