import React, { useEffect } from "react";
import { useBloodWorkStepper } from "../../../../../contexts/blood-work";
import FindLab from "../../../Common/FindLab";
import { Lab } from "../../../../../types/Entities/Lab";

export default function FindLabWrapper() {
  const {
    handleNext,
    bloodwork,
    setIsNextDisabled,
    updateValues,
    labsWithPricing,
    updateLabLocations,
    resetLabLocations,
  } = useBloodWorkStepper();

  useEffect(() => {
    setIsNextDisabled(true);
  }, []);

  const handleChange = (value: Lab) => {
    updateValues({ selectedLab: value });
    setIsNextDisabled(false);
    handleNext();
  };

  return (
    <FindLab
      onLabSelected={(value) => handleChange(value)}
      defaultZipCode={bloodwork.patient?.shippingInfo?.zipCode}
      state={bloodwork?.patient?.shippingInfo?.state}
      taskId={bloodwork?.task?.id}
      labsWithPricing={labsWithPricing}
      updateLabLocations={updateLabLocations}
      resetLabs={resetLabLocations}
    />
  );
}
