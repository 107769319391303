import { MODE } from "../constants";
import { logError, logInfo } from "./logger";
import { Patient } from "../types/Entities/Patient";

function pushToDataLayer(data) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  } catch (e) {
    logError("Unable to push to data layer", e);
  }
}

export function analyticsIdentify(patient?: Patient) {
  try {
    ttq.identify({
      external_id: patient?.accountId,
      email: patient?.email,
      phone_number: patient?.phone,
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyStarted() {
  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "survey_started",
      event_category: "Survey",
      event_action: "Started",
    });
    ttq.track("Survey Started", {
      contents: [
        {
          content_name: "Survey Start",
          content_id: "Survey Start",
        },
      ],
      value: "0",
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendAppointmentScheduled(patientId: string) {
  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "appointment_scheduled",
      event_category: "Appointment",
      event_action: "Appointment scheduled",
      user_id: patientId,
    });
    ttq.track("Appointment Scheduled", {
      contents: [
        {
          content_name: "Appointment Schedule Calendar",
          content_id: "Appointment Schedule Calendar",
        },
      ],
      value: "0",
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSignUpEvent() {
  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "sign_up",
      event_category: "User",
      event_action: "Sign up",
    });
    ttq.track("CompleteRegistration", {
      contents: [
        {
          content_name: "Sign Up Page",
          content_id: "Sign Up Page",
        },
      ],
      value: "0",
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendCardSavedEvent(patientId: string) {
  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "card_saved",
      event_category: "Payment",
      event_action: "Card saved",
      event_value: 0.0,
      currency: "USD",
      user_id: patientId,
    });
    ttq.track("Card Saved", {
      contents: [
        {
          content_name: "Card Saved",
          content_id: "Card Saved",
        },
      ],
      value: "0",
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyEnded(patientId: string) {
  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "survey_ended",
      event_category: "Survey",
      event_action: "Ended",
      user_id: patientId,
    });
    ttq.track("Survey Complete", {
      contents: [
        {
          content_name: "Survey End",
          content_id: "Survey End",
        },
      ],
      value: "0",
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendPaymentSuccess(amountPaid: number, patientId: string) {
  const amount = (amountPaid || 0) / 100;

  if (MODE !== "PROD") return;

  try {
    pushToDataLayer({
      event: "payment_success",
      event_category: "Payment",
      event_action: "Payment successful",
      event_label: "Payment successful",
      event_value: Math.round(amount),
      currency: "USD",
      user_id: patientId,
    });
    ttq.track("CompletePayment", {
      contents: [
        {
          content_name: "Checkout Page",
          content_id: "Checkout Page",
        },
      ],
      value: `${amount}`,
      currency: "USD",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyQuestion(questionName: string) {
  if (MODE !== "PROD") return;
  logInfo("Sending survey question answered event", {
    question: questionName,
  });
  try {
    pushToDataLayer({
      event: "survey_question_answered",
      event_category: "Survey",
      event_action: "Question answered",
      event_label: questionName,
    });
    ttq.track("Question Answered", {
      contents: [
        {
          content_name: "Question Answered",
          content_id: "Question Answered",
        },
      ],
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendNoShow({ experiment, userId }: any) {
  if (MODE !== "PROD") return;
  try {
    pushToDataLayer({
      event: "onboarding-experiment",
      event_category: "A/B Testing - No Show",
      event_label: "User No Show",
      variation: experiment.variation,
      user_id: userId,
    });

    pushToDataLayer({
      event: "user_no_show",
      event_category: "Appointment",
      event_action: "No Show",
      user_id: userId,
    });
    ttq.track("User No Show", {
      contents: [
        {
          content_name: "User No Show",
          content_id: "User No Show",
        },
      ],
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendShow({ experiment, userId }: any) {
  if (MODE !== "PROD") return;
  try {
    pushToDataLayer({
      event: "onboarding-experiment",
      event_category: "A/B Testing - Show",
      event_label: "User Showed Up",
      variation: experiment.variation,
      user_id: userId,
    });

    pushToDataLayer({
      event: "user_show",
      event_category: "Appointment",
      event_action: "Show",
      user_id: userId,
    });
    ttq.track("User Show", {
      contents: [
        {
          content_name: "User Show",
          content_id: "User Show",
        },
      ],
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

// A-B Testing events

export function sendScheduleFinished({ experiment, userId }: any) {
  if (MODE !== "PROD") return;
  try {
    pushToDataLayer({
      event: "onboarding-experiment",
      event_category: "A/B Testing - Onboarding",
      event_label: "Schedule Finished",
      variation: experiment.variation,
      user_id: userId,
      experiment_id: "onboarding-experiment",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendSurveyFinished({ experiment, userId }: any) {
  if (MODE !== "PROD") return;
  try {
    pushToDataLayer({
      event: "onboarding-experiment",
      event_category: "A/B Testing - Onboarding",
      event_label: "Survey Finished",
      variation: experiment.variation,
      user_id: userId,
      experiment_id: "onboarding-experiment",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}

export function sendOnboardingStarted({ experiment, userId }: any) {
  if (MODE !== "PROD") return;
  try {
    pushToDataLayer({
      event: "onboarding-experiment",
      event_category: "A/B Testing - Onboarding",
      event_label: "Onboarding Started",
      variation: experiment.variation,
      user_id: userId,
      experiment_id: "onboarding-experiment",
    });
  } catch (e) {
    logError("Unable to send analytics", e);
  }
}
