import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import StepperButtons from "../../../Stepper/StepperButtons";
import PaymentDetails, {
  SERVICE_FEE,
} from "../../../Common/Payment/PaymentDetails";
import { CustomerInfo } from "../../../../../types/CustomerInfo";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { PregnancyWorkPopupSteps } from "../steps";
import { NotificationLayerContext } from "../../../Common/NotificationLayer";
import { updateLabOrder } from "../../../../../services/honeydew-api/labs/update-lab-order";
import { getChargeItems } from "../../../../../utils/lab-work/get-charge-items";

export default function PaymentDetailsWrapper() {
  const {
    handleBack,
    handleNext,
    setIsNextDisabled,
    isNextDisabled,
    updateValues,
    pregnancyWork,
  } = usePregnancyWorkStepper();
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingMessage, setProcessingMessage] = useState<string>("");
  const { showError } = useContext(NotificationLayerContext);

  useEffect(() => {
    setIsNextDisabled(!consent);
  }, [consent]);

  const onPaymentChange = (customerInfo: CustomerInfo) => {
    updateValues({ customerInfo });
    handleNext(PregnancyWorkPopupSteps.PAYMENT_METHOD);
  };

  const handleCheckout = async () => {
    try {
      setLoading(true);
      const { priceDetails, totalPrice, ...lab } = pregnancyWork.selectedLab;

      setProcessingMessage(
        "Please be patient and do not refresh the page while we process your payment."
      );
      setTimeout(() => {
        setProcessingMessage(
          "Sorry, this is taking longer than expected. We are still processing your payment."
        );
      }, 4500);

      await updateLabOrder({
        taskId: pregnancyWork.task?.id,
        placeOfService: "lab",
        selectedPaymentMethod: pregnancyWork.selectedPaymentMethod,
        charges: getChargeItems({ priceDetails }),
        customerId: pregnancyWork.patient?.customerId,
        paymentMethod: pregnancyWork.paymentMethod,
        lab,
      });
      handleNext();
    } catch (e) {
      showError({
        title: "Payment failed",
        description: (
          <Typography>
            Please try again or use a different card. If the problem persists,
            reach out to{" "}
            <Typography color="#5E39F5">
              membership@honeydewcare.com.
            </Typography>
          </Typography>
        ),
      });
    } finally {
      setProcessingMessage("");
      setLoading(false);
    }
  };

  const onDefaultPaymentMethodChange = (paymentMethodId?: string) => {
    if (paymentMethodId) {
      updateValues({ selectedPaymentMethod: paymentMethodId });
    }
  };

  return (
    <>
      <PaymentDetails
        consent={consent}
        setConsent={setConsent}
        handleChangePaymentMethod={onPaymentChange}
        defaultPaymentMethod={pregnancyWork?.selectedPaymentMethod}
        handleDefaultPaymentMethodChange={onDefaultPaymentMethodChange}
        lab={pregnancyWork?.selectedLab}
      />
      {processingMessage && (
        <Typography pl={2} variant="subtitle2">
          {processingMessage}
        </Typography>
      )}
      <StepperButtons
        backText="BACK"
        confirmText="Checkout"
        onBack={handleBack}
        onConfirm={handleCheckout}
        loading={loading}
        isConfirmDisabled={
          isNextDisabled || !pregnancyWork.selectedPaymentMethod
        }
      />
    </>
  );
}
