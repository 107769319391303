import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
} from "@mui/material";
import { ReactComponent as Checked } from "./checked.svg";
import { ReactComponent as Unchecked } from "./unchecked.svg";
import { ReactComponent as Inactive } from "./inactive.svg";

export const StepperContainer = styled(Box)(() => ({
  padding: "8px",
  border: "1px solid #F2F4F7",
  borderRadius: "8px",
}));

export function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;
  if (completed) return <Checked color="success" />;

  return active ? <Unchecked /> : <Inactive />;
}

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.common.fernGreen,
      minHeight: "16px",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: "16px",
  },
}));
