import React, { useEffect } from "react";
import ChangePaymentMethod from "../../../../Common/Payment/ChangePaymentMethod";
import { PregnancyWorkPopupSteps } from "../../steps";
import { usePregnancyWorkStepper } from "../../../../../../contexts/pregnancy-work";

export default function ChangePaymentMethodWrapper() {
  const { handleNext, pregnancyWork, updateValues, setIsNextDisabled } =
    usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(true);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as "new-card" | string;
    if (value !== "new-card") {
      updateValues({ selectedPaymentMethod: value });
      handleNext(PregnancyWorkPopupSteps.PAYMENT_DETAILS);
    } else {
      handleNext(PregnancyWorkPopupSteps.NEW_CARD);
    }
  };

  return (
    <ChangePaymentMethod
      paymentMethods={pregnancyWork?.customerInfo?.paymentMethods || []}
      handleChange={handleChange}
    />
  );
}
