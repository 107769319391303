import React, { useContext, useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useBloodWorkStepper } from "../../../../../../contexts/blood-work";
import { addNewPaymentMethod } from "../../../../../../utils/stripe/add-new-card";
import StepperButtons from "../../../../Stepper/StepperButtons";
import { useAttachPaymentMethodMutation } from "../../../../../../features/api/payment";
import AddNewCard from "../../../../Common/Payment/AddNewCard";
import { NotificationLayerContext } from "../../../../Common/NotificationLayer";

export default function AddNewCardWrapper() {
  const {
    handleNext,
    bloodwork,
    updateValues,
    setIsNextDisabled,
    isNextDisabled,
    handleBack,
  } = useBloodWorkStepper();
  const [asDefault, setAsDefault] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [validCard, setIsValidCard] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(NotificationLayerContext);
  const [attachPaymentMethod, { isSuccess, isError, error }] =
    useAttachPaymentMethodMutation();

  useEffect(() => {
    setIsNextDisabled(!cardHolderName || !validCard);
  }, [validCard, cardHolderName]);

  const handleNewCard = async () => {
    try {
      setLoading(true);
      const result = await addNewPaymentMethod({
        elements,
        stripe,
        billingDetails: {
          name: cardHolderName,
          email: bloodwork.patient?.email,
          address: bloodwork.patient?.shippingInfo
            ? {
                line1: bloodwork.patient?.shippingInfo?.addressLine1,
                line2: bloodwork.patient?.shippingInfo?.addressLine2 || "",
                state: bloodwork.patient?.shippingInfo?.state,
                postal_code: bloodwork.patient?.shippingInfo?.zipCode,
                city: bloodwork.patient?.shippingInfo?.city,
              }
            : undefined,
        },
        CardElement,
      });

      if (!result.error) {
        await attachPaymentMethod({
          customerId: bloodwork.patient?.customerId,
          setAsDefault: asDefault,
          paymentMethodId: result.paymentId,
        });

        updateValues({ selectedPaymentMethod: result.paymentId });
        handleNext();
      }
    } catch (e) {
      showError({
        title: "Add payment failed",
        description: "Please try again or use a different card.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AddNewCard
        setAsDefault={setAsDefault}
        asDefault={asDefault}
        setCardHolderName={setCardHolderName}
        setIsValidCard={setIsValidCard}
      />
      <StepperButtons
        backText="BACK"
        confirmText="ADD AND CONTINUE"
        onBack={handleBack}
        onConfirm={handleNewCard}
        loading={loading}
        isConfirmDisabled={isNextDisabled}
      />
    </>
  );
}
