import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../Common/Button";
import { Props } from "../FormsOfPregnancyPopup/types";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import "./style.scss";
import { InfoBlock } from "../../Common/InfoBlock";
import { Checkbox } from "../../Common/Checkbox";
import { ImageUploadTile } from "../ImageUploadTile";
import { Input } from "../../Common/Input";
import generateHash from "../../../../utils/generate-hash";
import { ImagePayload } from "../../../../types/Entities/Image";
import {
  getPatientStore,
  storeAccutaneInfo,
} from "../../../../features/patientView";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { accutaneApiSlice } from "../../../../features/api/accutane";
import pregnancyTestTemplete from "../../../../Assets/NewImages/prenancy-text-templete.svg";
import markAsTestComplete from "../../../../Assets/NewImages/pregnancy-test-btn.svg";
import enrollmentText from "../../../../Assets/NewImages/enrollment-text.svg";
import { Loader } from "../../Common/Loader";
import disableBtn from "../../../../Assets/NewImages/pregnancy-disable-btn.svg";
import { PregnancyWorkForm } from "./PregnancyWorkForm";
import { useFeatureToggle } from "../../../../contexts/feature-toggle";
import { FEATURES } from "../../../../data/available-features";
import { usePregnancyWorkStepper } from "../../../../contexts/pregnancy-work";
import { useHotReload } from "../../../../contexts/hot-reload";

interface ErrorProps {
  error: boolean;
  errorMessage: string;
}

interface ValidatorProps<T> {
  id: T;
  validator: (value: any | any[]) => ErrorProps;
}

interface ValidatedFieldProps {
  [key: string]: ErrorProps;
}

interface PregnancyTestForm {
  image: ImageBlob | Blob | null;
  eSignature: string;
  confirmTest: boolean;
}

enum FormIds {
  eSignature = "eSignature",
  confirmTest = "confirmTest",
  image = "image",
}

const infoBlockText =
  "After enrollment, a pregnancy test will be required every 30" +
  " days and must be done at a certified lab. We will send you a lab" +
  " order in time for your first lab test in 30 days.";

const warnBlockText = "In the picture be sure to include:";

const errorsListInitialState: ValidatedFieldProps = {
  confirmTest: {
    error: false,
    errorMessage: "",
  },
  image: {
    error: false,
    errorMessage: "",
  },
  eSignature: {
    error: false,
    errorMessage: "",
  },
};

const eSignatureValidator = ({ eSignature }: { eSignature: string }) => ({
  error: !eSignature,
  errorMessage: "* This field is required",
});
const confirmTestValidator = ({
  confirmTest,
  image,
}: {
  confirmTest: boolean;
  image: ImageBlob | null;
}) => ({
  error: !confirmTest || !image,
  errorMessage: "* This field is required",
});

const validatorsMap: ValidatorProps<FormIds>[] = [
  { id: FormIds.eSignature, validator: eSignatureValidator },
  { id: FormIds.confirmTest, validator: confirmTestValidator },
];

const validate = (pregnancyTestForm: PregnancyTestForm): ValidatedFieldProps =>
  validatorsMap.reduce((acc, it) => {
    acc[it.id] = it.validator(pregnancyTestForm);

    return acc;
  }, {} as ValidatedFieldProps);

export function PregnancyTestPopup({
  onClose,
  isLabTask,
}: {
  onClose: () => void;
  isLabTask: boolean;
}) {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  // const patientQuery = useGetPatientByIdQuery(activePatientId as string);
  const [hasError, setError] = useState<ValidatedFieldProps>(
    errorsListInitialState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pregnancyTestForm, setFormField] = useState<PregnancyTestForm>({
    [FormIds.eSignature]: "",
    [FormIds.confirmTest]: false,
    [FormIds.image]: null,
  });

  if (!activePatientId) {
    return null;
  }

  // todo extract to separate function
  const generateImagesFilename = () => {
    const extension = `${pregnancyTestForm.image?.type.split("/").pop()}`;

    return `${activePatientId}.${generateHash(10)}.${extension}`;
  };

  const onSubmit = async () => {
    if (!activePatientId) return;
    const errorsList = validate(pregnancyTestForm);
    setError(errorsList);

    const isInvalid = Object.keys(errorsList).some(
      (it) => errorsList[it].error
    );

    async function generateBase64Image(): Promise<ImagePayload> {
      const filename = generateImagesFilename();
      return new Promise<ImagePayload>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result as string;
          resolve({
            contentType: pregnancyTestForm.image?.type ?? "",
            filename,
            source: result.replace(/^data:.+;base64,/, ""),
          });
        };

        if (pregnancyTestForm.image) {
          reader.readAsDataURL(pregnancyTestForm.image);
        }
      });
    }

    if (!isInvalid || isLabTask) {
      let base64Image: ImagePayload | undefined;

      if (!isLabTask) {
        base64Image = await generateBase64Image();
      }

      setIsLoading(true);

      try {
        const result = isLabTask
          ? await AcneAwayAPI.patients.addPregnancyLabTest(activePatientId)
          : await AcneAwayAPI.patients.confirmPregnancyTest(
              activePatientId,
              base64Image,
              pregnancyTestForm.eSignature
            );

        dispatch(
          accutaneApiSlice.util.invalidateTags([
            {
              type: "accutane",
              id: activePatientId,
            },
          ])
        );

        onClose();
        setIsLoading(false);
        showSuccess({
          title: "Success!",
          description: "You've submitted your pregnancy test",
        });
      } catch (e) {
        setIsLoading(false);
        showError({
          title: "Something went wrong...",
          description: "Unable to submit pregnancy test",
        });
      }
    }
  };

  const togglePregnancyConfirmation = (isChecked: boolean) => {
    setFormField({
      ...pregnancyTestForm,
      confirmTest: !pregnancyTestForm.confirmTest,
    });
    if (isChecked && pregnancyTestForm.image) {
      setError({
        ...hasError,
        confirmTest: {
          error: false,
          errorMessage: "",
        },
      });
    }
  };

  const setImageHandler = (image: Blob | ImageBlob | null) => {
    setFormField({ ...pregnancyTestForm, image });
    setError({
      ...hasError,
      image: {
        error: false,
        errorMessage: "",
      },
    });
  };

  const setEsignatureHandler = (eSignature: string) => {
    setFormField({ ...pregnancyTestForm, eSignature });
    setError({
      ...hasError,
      eSignature: {
        error: false,
        errorMessage: "",
      },
    });
  };

  return (
    <div className="pregnancy-test-popup">
      {isLabTask ? (
        <>
          <img
            src={isLoading ? disableBtn : markAsTestComplete}
            onClick={isLoading ? null : onSubmit}
            className="pregnancy-test-popup__extra-button"
          />
          <img
            src={enrollmentText}
            className="pregnancy-test-popup__pregnancy-block"
          />
          <img
            src={pregnancyTestTemplete}
            className="pregnancy-test-popup__full-width"
          />
          <div className="pregnancy-test-popup__submit-button-fixed-position">
            <img
              src={isLoading ? disableBtn : markAsTestComplete}
              onClick={isLoading ? null : onSubmit}
            />
          </div>
        </>
      ) : (
        <>
          <Button
            text="Sign & Send"
            size="small"
            onClick={onSubmit}
            className="pregnancy-test-popup__extra-button"
          />
          <p className="pregnancy-test-popup__description">
            iPledge requires a negative pregnancy test to enroll you and begin
            your 30-day wait period. Please upload a picture of your urine
            pregnancy test.
          </p>
          <InfoBlock
            view="info"
            text={infoBlockText}
            className="pregnancy-test-popup__info-block"
          />
          <div className="pregnancy-test-popup-form">
            <div className="pregnancy-test-popup-form__item">
              <div className="pregnancy-test-popup-form__option-wrapper">
                <p className="pregnancy-test-popup-form__text">
                  1. Upload an image of your pregnancy test
                  <InfoBlock
                    view="warn"
                    text={warnBlockText}
                    className="pregnancy-test-popup__info-block"
                    isPregnancyTest
                  />
                </p>
              </div>
              <div className="pregnancy-test-popup__test-upload">
                <ImageUploadTile
                  side="center"
                  setImage={setImageHandler}
                  placeholderClassName="pregnancy-test-popup__test-upload__placeholder"
                />
              </div>
              <Checkbox
                checked={pregnancyTestForm.confirmTest}
                onChange={togglePregnancyConfirmation}
                hasError={hasError.confirmTest}
                disabled={!pregnancyTestForm.image}
              >
                <span>
                  I confirm that this is my own pregnancy test and it was taken
                  today
                </span>
              </Checkbox>
            </div>
            <div className="pregnancy-test-popup-form__item">
              <div className="pregnancy-test-popup-form__option-wrapper">
                <p className="pregnancy-test-popup-form__text">
                  2. Type your name to provide an e-signature
                </p>
              </div>
              <Input
                id="fullName"
                name="fullName"
                onChange={setEsignatureHandler}
                placeholder="Enter your full name to sign the provided result"
                hasError={hasError.eSignature}
              />
            </div>
          </div>
          <div className="pregnancy-test-popup__submit-button">
            <Button text="Sign & Send" size="small" onClick={onSubmit} />
          </div>
        </>
      )}
    </div>
  );
}

export function PregnancyTestPopupGenerator(isLabTask: boolean) {
  const { isFeatureEnabled } = useFeatureToggle();
  const { cleanup } = usePregnancyWorkStepper();
  const { setShouldReload } = useHotReload();
  const enabled = useMemo(() => isFeatureEnabled(FEATURES.VITAL), []);

  return function render({ onClose }: Props) {
    const handleClose = () => {
      cleanup();
      setShouldReload(true);
      onClose();
    };

    return (
      <ActionPopupTemplate
        title={
          isLabTask
            ? "Complete pregnancy test"
            : "Complete urine pregnancy test"
        }
        onClose={enabled ? handleClose : onClose}
      >
        {enabled && isLabTask ? (
          <PregnancyWorkForm onClose={handleClose} />
        ) : (
          <PregnancyTestPopup onClose={onClose} isLabTask={isLabTask} />
        )}
      </ActionPopupTemplate>
    );
  };
}
