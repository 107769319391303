import React from "react";
import { PatientDashboardItem } from "../../../../types/Dashboard";

export function FullName({ payload }: { payload: PatientDashboardItem }) {
  return (
    <p>
      {payload.fullName}{" "}
      {!payload.followUp ? (
        <span
          className="patients-table__attention-sign"
          data-tooltip="No medical background"
        >
          !
        </span>
      ) : null}
      {payload.subscription?.accutane ? (
        <span className="patients-table__accutane-sign">Accutane</span>
      ) : null}
    </p>
  );
}
