import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const SectionTitle = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: "#403E48",
});

export const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  color: "#403E48",
  marginBottom: "2rem",
});

export const LabAddress = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#6C6A7C",
});

export const LabName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#403E48",
});

export const NoLabsLabel = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
});
