import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CareCoordinatorsTable } from "../../../Components/Administrator/CareCoordinatorsTable";
import { EnrollmentCoordinatorsTable } from "../../../Components/Administrator/EnrollmentCoordinatorsTable";
import { ProvidersTable } from "../../../Components/Administrator/ProvidersTable";
import { Tabs } from "../../../NewComponents/Common/Tabs";
import { Button } from "../../../NewComponents/Common/Button";
import "./style.css";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { EmployeePopupGenerator } from "../../../NewComponents/Administrator/EmployeePopup";
import { USER_ROLES } from "../../../../types/Main";
import { TimeOffPopupGenerator } from "../TimeOffPopupGenerator";
import { PriorityTable } from "../../../Components/Administrator/PriorityTable";

const views = [
  {
    key: "providers",
    label: "Providers",
    children: <ProvidersTable />,
  },
  {
    key: "careCoordinators",
    label: "Care Coordinators",
    children: <CareCoordinatorsTable />,
  },
  {
    key: "enrollmentCoordinators",
    label: "Enrollment Coordinators",
    children: <EnrollmentCoordinatorsTable />,
  },
];

export function ProvidersPriorityDashboard() {
  const history = useHistory();
  // const [currentView, setCurrentView] = useState(views[0].key);
  // const { showPopup } = useContext(PopupLayerContext);
  // const [role, setRole] = useState("provider");

  // useEffect(() => {
  //   if (currentView === "providers") setRole("provider");
  //   else if (currentView === "careCoordinators") setRole("care-coordinator");
  //   else if (currentView === "enrollmentCoordinators")
  //     setRole("enrollment-coordinator");
  //   else setRole("provider");
  // }, [currentView]);

  // useEffect(() => {
  //   setCurrentView(views[0].key);
  // }, []);

  return (
    <div className="greeting-container">
      <div className="employees-dashboard__header">
        <div>
          <h1 className="employees-dashboard__header-title">
            Editing substitute provider priority{" "}
          </h1>
          {/* <p className="employees-dashboard__header-subtitle">Lorem ipusum </p> */}
        </div>
        <div className="employees-dashboard__header-buttons">
          <div>
            <Button
              size="big"
              className="employees-dashboard__button--secondary"
              text="RETURN TO MANAGE TEAM"
              onClick={() => history.push("employees")}
            />
          </div>
        </div>
      </div>
      <div className="tables-block">
        {/* <Tabs
          items={views}
          initialTab={views[0].label}
          onChange={(tabLabel) => {
            const selectedView = views.find((view) => view.label === tabLabel);
            setCurrentView(selectedView?.key || views[0].key);
          }}
        /> */}
        <PriorityTable />
      </div>
    </div>
  );
}
