import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { isEmpty } from "../../../../../utils/is-empty";
import { RadioButtonsList } from "../../../../NewComponents/Common/RadioButtonsList";
import { checkIsAccutaneSource } from "../../../../../utils/patient/isAccutaneSource";
import { Button } from "../../../../NewComponents/Common/Material/Button";

const ANSWERS = {
  IS_CHILD: true,
  IS_ADULT: false,
};

const list = [
  {
    value: ANSWERS.IS_CHILD,
    label: "I'm signing up my child",
  },
  {
    value: ANSWERS.IS_ADULT,
    label: "I'm signing up myself",
  },
];

function surveyToComponent(data: SurveyData) {
  return data.isChild;
}

function componentToSurvey(value: boolean) {
  return {
    isChild: value,
  };
}

export function IsChildSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    checkIsAccutaneSource(location, history);
  }, [location, history]);

  function submit() {
    if (isEmpty(currentData)) return;

    setData(currentData);
    sendSurveyQuestion("isChild");
    onDone(componentToSurvey(currentData));
  }

  function autocontinue(value: boolean) {
    setData(value);
    sendSurveyQuestion("isChild");
    onDone(componentToSurvey(value));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">Which best describes you?</p>
      <p className="survey-question__descr paragraph-font--color" />
      <RadioButtonsList
        list={list}
        currentValue={isEmpty(currentData) ? null : currentData}
        onChange={(value) =>
          isEmpty(currentData) ? autocontinue(value) : setData(value)
        }
        className="survey-question__input"
      />
      <Grid container alignItems="center" rowGap={2}>
        <Grid item xs={12}>
          <Button
            text="continue"
            onClick={submit}
            fullWidth
            className="survey-question__button"
            disabled={isEmpty(currentData)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
