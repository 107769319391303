import { useSelector, useDispatch } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { Conversation, JSONObject, Participant } from "@twilio/conversations";
import { useHistory } from "react-router-dom";
import _chunk from "lodash.chunk";
import { v4 as uuidv4 } from "uuid";
import { getTwilioState } from "../../../../features/twilio";
import { TwilioController } from "../../../../services/twilio";
import { ReactComponent as ChatCloud } from "../../../../Assets/NewIcons/chat-cloud.svg";
import { ReactComponent as ConfirmSign } from "../../../../Assets/NewIcons/check-rounded.svg";
import { ReactComponent as MarkSign } from "../../../../Assets/NewIcons/checkmark-stroke.svg";
import { USER_ROLES } from "../../../../types/Main";
import {
  useGetPatientByIdQuery,
  useMarkAsReadByPatientIdsMutation,
} from "../../../../features/api/patients";
import { Skeleton } from "../Skeleton";
import { PopupLayerContext } from "../PopupLayer";
import { Loader } from "../Loader";
import {
  formatRelativeTimeShort,
  getDatePretty,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import { PatientsTablePagination } from "../PatientsTableElastic/tablePagination";
import { NotificationLayerContext } from "../NotificationLayer";
import { StyledSelect } from "../StyledSelect";
import {
  useGetEmployeeQuery,
  useGetEmployeesByRoleQuery,
} from "../../../../features/api/employees";
import { ConversationParticipant } from "../../../../types/Chat";
import {
  CareCoordinator,
  Employee,
  Provider,
} from "../../../../types/Employee";
import { patientDashboardForAdministratorFilters } from "../../../NewPages/Administrator/PatientsPage/filters";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { isEmpty } from "../../../../utils/is-empty";
import { getPatientDashboardForProviderFilters } from "../../../NewPages/Provider/PatientsPage/filters";
import { getPatientDashboardForCareCoordinatorFilters } from "../../../NewPages/CareCoordinator/PatientsPage/filters";
import {
  getAllChannelMetadata,
  getTimeOfLatestMsg,
} from "../../../../utils/patient/pubnubChatCount";
import { pubnubClient } from "../../../../services/pubnub";
import { fetchChatData } from "../../../../utils/employee/fetch-chat-data";

type NewMessageItemProps = {
  conversation: any;
  participants: Participant[];
};

function sortAssignees(a: Employee, b: Employee) {
  if (a.firstName < b.firstName) return -1;
  if (a.firstName > b.firstName) return 1;
  return 0;
}

export const participantsOptions = (
  careCoordinatorsParticipants: CareCoordinator[],
  providersParticipants: Provider[]
) => [
  {
    label: "Care Coordinators",
    options: careCoordinatorsParticipants
      .filter(({ archived }) => !archived)
      .sort(sortAssignees)
      .map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      })),
  },
  {
    label: "Clinicians",
    options: providersParticipants
      .filter(({ archived }) => !archived)
      .sort(sortAssignees)
      .map(({ firstName, lastName, title, id }) => ({
        label: `${firstName} ${lastName}${title ? `, ${title}` : ""}`,
        value: id,
      })),
  },
];

function NewMessageItem({ conversation, participants }: NewMessageItemProps) {
  const history = useHistory();
  const { showPopup } = useContext(PopupLayerContext);
  const [patientId, setPatientId] = useState<string | null>(null);
  const patientQuery = useGetPatientByIdQuery(patientId as string, {
    skip: !patientId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (conversation) {
      setPatientId(conversation.patientId);
    }
  }, [conversation]);

  // useEffect(() => {
  //   (async () => {
  //     const participant = participants.find(
  //       ({ attributes }) =>
  //         attributes && (attributes as JSONObject).role === USER_ROLES.PATIENT
  //     );
  //     if (!participant) return;

  //     setPatientId(participant.identity);
  //   })();
  // }, [conversation]);
  return (
    <div
      className="new-message-list__item"
      onClick={() => {
        if (!patientId) return;
        history.push(`/${patientId}`);
      }}
    >
      <div className="new-message-list__icon">
        <ChatCloud className="new-message-list__icon--inner" />
      </div>
      <p className="new-message-list__title">
        {conversation.length !== 0 ? (
          conversation.fullName
        ) : (
          <Skeleton fullWidth />
        )}
      </p>
      <p className="new-message-list__title">
        {conversation.lastMessage
          ? formatRelativeTimeShort(conversation.lastMessage)
          : null}
      </p>
    </div>
  );
}

// function MarkAllChatsAsReadButton({}: // conversations,
// // onComplete,
// {
//   // conversations: Conversation[];
//   // onComplete: () => void;
// }) {
//   const { userRole } = useSelector(getSessionState);
//   console.log("userRole", userRole);
//   const { showError, showSuccess } = useContext(NotificationLayerContext);
//   const [loading, setLoading] = useState(false);

//   async function submit() {
//     setLoading(true);
//     try {
//     } catch (e) {
//       showError({
//         title: "Something went wrong...",
//         description:
//           "Error occured during marking conversations as read. Please try again later",
//       });
//     } finally {
//       setLoading(false);
//     }
//   }

//   if (userRole !== "admins") return null;

//   return (
//     <div
//       className={`new-message-list__mark-all-as-read new-message-list__mark-all-as-read--${
//         loading ? "loading" : "idle"
//       }`}
//       // onClick={loading ? undefined : submit} // to-do with pubnub
//       data-tooltip="Mark All As Read"
//     >
//       {loading ? (
//         <Loader className="new-message-list__mark-all-as-read__icon" />
//       ) : (
//         <MarkSign className="new-message-list__mark-all-as-read__icon" />
//       )}
//     </div>
//   );
// }

function isEmployeeExistsInConversation(
  participants: Participant[],
  careTeamMemberId: string
) {
  return participants.some(({ identity }) => identity === careTeamMemberId);
}

const NEW_MESSAGES_PER_PAGE = 25;

export function NewChatMessagesList() {
  const dispatch = useDispatch();
  const { userRole } = useSelector(getSessionState);
  const { initialized } = useSelector(getTwilioState);
  const [loading, setLoading] = useState(true);
  // const [pubnub, setPubNub] = useState<PubNub | null>(null);
  const [markAsReadByPatientIds] = useMarkAsReadByPatientIdsMutation();
  const providersQuery = useGetEmployeesByRoleQuery(USER_ROLES.PROVIDER);
  const careCoordinatorsQuery = useGetEmployeesByRoleQuery(
    USER_ROLES.CARE_COORDINATOR
  );
  const [newMessageConversations, setNewMessageConversations] = useState<
    Conversation[]
  >([]);
  const [conversationsParticipants, setConversationsParticipants] =
    useState<Map<string, Participant[]> | null>(null);
  const [selectedParticipant, setSelectedParticipant] = useState<string | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [newChats, setNewChats] = useState<any>([]);

  const { userId: adminId } = useSelector(getSessionState);

  const employeeQuery = useGetEmployeeQuery(adminId as string, {
    skip: !adminId,
  });
  const [unReadloading, setUnReadloading] = useState(false);

  const [chatHistoryRecords, setChatHistoryRecords] = useState([]);

  const [filterArray, setFilterArray] = useState([]);
  const [allUnreadData, setAllUnreadData] = useState([]);

  useEffect(() => {
    const role = employeeQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "provider":
          newFilterArray = getPatientDashboardForProviderFilters(
            adminId as string
          );
          break;
        case "care-coordinator":
          newFilterArray = getPatientDashboardForCareCoordinatorFilters(
            adminId as string
          );
          break;
        case "administrator":
          newFilterArray = patientDashboardForAdministratorFilters(
            adminId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, employeeQuery, adminId]);

  const [associatedChats, setAssociatedChats] = useState<any>(null);
  const [isNewChatAvailable, setIsNewChatAvailable] = useState<any>(null);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);
  const [isMarkAsRead, setIsMarkAsRead] = useState<any>(false);

  useEffect(() => {
    if (filterArray.length > 0 && userRole !== "admins") {
      const fetchData = async () => {
        const { filter } = filterArray[0];
        const mustQuery = Array.isArray(filter.must) ? [...filter.must] : [];
        const query = {
          query: {
            from: 0,
            size: 10000,
            query: {
              function_score: {
                query: {
                  bool: {
                    must: mustQuery,
                    filter: filter.filter || [],
                  },
                },
              },
            },
          },
        };
        try {
          const results = await HoneydewAPI.dashboard.queryDashboardItems(
            query
          );
          const hits = results.hits || [];
          setFilteredChat(hits);
          setPatientIds(hits.map((chat: any) => chat._source.patientId) || []);
        } catch (error) {
          console.log("Error in fetching table data", error);
        }
      };
      fetchData();
    }
  }, [filterArray]);

  const setPaginatedChats = async (filteredChats) => {
    const formattedObjectsArray = filteredChats.map((chat) => {
      const careTeam = chat._source.careTeam || [];

      const provider = careTeam.find((member) => member.role === "provider");
      const careCoordinator = careTeam.find(
        (member) => member.role === "care-coordinator"
      );

      return {
        fullName: chat._source.fullName,
        patientId: chat._source.patientId,
        lastMessage: chat._source?.lastUpdated,
        authorIds: chat._source?.authorIds,
        providerId: provider ? provider.employeeId : null,
        careCoordinatorId: careCoordinator ? careCoordinator.employeeId : null,
      };
    });
    setLoading(false);
    setChatHistoryRecords(formattedObjectsArray);
    formattedObjectsArray.sort(
      (a, b) => new Date(b.lastMessage) - new Date(a.lastMessage)
    );
    const startIndex = page * NEW_MESSAGES_PER_PAGE;
    const endIndex = startIndex + NEW_MESSAGES_PER_PAGE;
    const paginatedChat = formattedObjectsArray.slice(startIndex, endIndex);
    setIsNewChatAvailable(true);
    // paginatedChat.sort(
    //   (a, b) => new Date(b.lastMessage) - new Date(a.lastMessage)
    // );
    setAllUnreadData(formattedObjectsArray);
    setNewChats(paginatedChat);
    setTotalChats(formattedObjectsArray.length);
  };
  const [metaDataPatient, setMetaDataPatient] = useState([{}]);
  const [allUnreadMetaData, setAllUnreadMetaData] = useState([]);

  useEffect(() => {
    if (patientIds.length > 0 && userRole !== "admins") {
      const fetchData = async () => {
        const pubNubSubscribeKey = await HoneydewAPI.chats.pubNubSubscribeKey();
        const metadataFlags = await getAllChannelMetadata(
          adminId,
          patientIds,
          pubNubSubscribeKey
        );
        const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
          acc[obj.id] = obj;
          return acc;
        }, {});
        const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

        const newMsgs = uniqueMetadataArray.filter((obj) => {
          if (obj.custom[adminId]) {
            const customData = JSON.parse(obj.custom[adminId]);
            return customData.isUnread === true;
          }
          return false; // Skip if adminId is not present in custom
        });

        setAllUnreadMetaData(newMsgs);
        // dispatch(setNewMsgsCount(newMsgs.length));
        if (newMsgs) {
          const metaDataPatients = newMsgs.map((metadata) => {
            const customData =
              metadata.custom && metadata.custom[adminId]
                ? JSON.parse(metadata.custom[adminId])
                : null;

            return {
              id: metadata.id,
              updated: customData?.timestamp || metadata.updated, // Use timestamp if available
              authorIds: metadata.custom && Object.keys(metadata.custom),
            };
          });

          if (metaDataPatients.length === 0) {
            console.log(metaDataPatients.length === 0);
            setFilteredChat([]);
            setPaginatedChats([]);
          }
          setMetaDataPatient(metaDataPatients);
        } else {
          setNewChats([]);
        }
      };
      fetchData();
    }
  }, [patientIds]);

  const filterChatByMetadata = async (chatMetaDataPatient: any) => {
    const chatPatientIds = chatMetaDataPatient.map((item) => item.id);
    const pubNubSubscribeKey = await HoneydewAPI.chats.pubNubSubscribeKey();
    const pubnubToken = await HoneydewAPI.chats.getTokenGenerated(
      chatPatientIds,
      adminId
    );
    const pubnubInstance = pubnubClient({
      userId: adminId,
      pubNubSubscribeKey,
      pubnubToken,
    });
    const filteredResult = filteredChat.filter((chat: any) => {
      const chatMetaData = chatMetaDataPatient.find(
        (metaData: any) => metaData.id === chat._source.patientId
      );
      if (chatMetaData) {
        chat._source.lastUpdated = chatMetaData.updated;
        chat._source.authorIds = chatMetaData.authorIds;
        return true;
      }
      return false;
    });
    dispatch(setNewMsgsCount(filteredResult.length));
    setFilteredChat(filteredResult);
    setPaginatedChats(filteredResult);
  };

  useEffect(() => {
    if (
      metaDataPatient.length &&
      Object.keys(metaDataPatient[0]).length > 0 &&
      filteredChat.length &&
      userRole !== "admins"
    ) {
      const filteredChatResult = filterChatByMetadata(metaDataPatient);
    }
  }, [metaDataPatient]);

  useEffect(() => {
    if (filteredChat.length) {
      setPaginatedChats(filteredChat);
    }
  }, [page]);

  useEffect(() => {
    if (selectedParticipant) {
      const particularPatients = chatHistoryRecords?.filter(
        (obj) =>
          // obj.authorIds.includes(selectedParticipant)
          obj.careCoordinatorId === selectedParticipant ||
          obj.providerId === selectedParticipant
      );
      setAssociatedChats(particularPatients);
    }
  }, [selectedParticipant]);

  const filterChatBydata = async (data: any) => {
    // Modify the existing data by adding lastUpdated and authorIds attributes
    const updatedChats = data.map((item: any) => {
      const {
        _source: { chat, latestChatTimestamp },
      } = item;

      if (chat && Array.isArray(chat)) {
        // Extract userId from each chat entry and remove duplicates
        const authorIds = [...new Set(chat.map((entry: any) => entry.userId))];

        // Add authorIds and lastUpdated to the existing data item
        item._source.authorIds = authorIds; // Adding authorIds to the existing object
        item._source.lastUpdated = latestChatTimestamp; // Adding lastUpdated to the existing object
      }

      return item; // Return the updated object
    });
    // Dispatch updated chat count based on the filtered result
    dispatch(setNewMsgsCount(updatedChats.length));
    // Set the filtered chats and paginated chats based on the modified structure
    setFilteredChat(updatedChats);
    setPaginatedChats(updatedChats);
  };

  useEffect(() => {
    if (filterArray.length > 0 && userRole === "admins") {
      const fetchData = async () => {
        try {
          // Call the utility function to fetch data
          const filteredChats = await fetchChatData({
            filterArray,
            userId: adminId,
          });

          // Set the filtered chats and pass them to another function
          setFilteredChat(filteredChats);
          filterChatBydata(filteredChats);
        } catch (error) {
          console.log("Error in fetching chat data", error);
        }
      };

      fetchData();
    }
  }, [filterArray, isMarkAsRead]);

  if (loading) {
    return (
      <div className="new-message-list__wrap">
        <div className="new-message-list">
          <div className="new-message-list__empty">
            <Loader className="chat-popup__loader" />
            <p className="chat-popup__loader-text paragraph-font paragraph-font--color">
              Loading
            </p>
          </div>
        </div>
      </div>
    );
  }

  // function reloadConversations() {
  //   const conversations = TwilioController.getConversations();
  //   const newMessages = TwilioController.getNewMessages();
  //   if (!conversations || !newMessages) return;
  //   const _conversations: Conversation[] = [];

  //   Object.entries(newMessages).forEach(([id, isNewMessage]) => {
  //     const conversation = conversations[id];
  //     if (isNewMessage) _conversations.push(conversation);
  //   });

  //   setNewMessageConversations(_conversations);
  // }

  const showAssociatedChat = () => {
    if (associatedChats && associatedChats.length !== 0) {
      return associatedChats.map((chat: any) => (
        <NewMessageItem conversation={chat} participants={[]} />
      ));
    }
    return (
      <div className="new-message-list__empty">
        <ConfirmSign className="chat-popup__loader" />
        <p className="chat-popup__loader-text paragraph-font paragraph-font--color">
          No new messages in chats for you
        </p>
      </div>
    );
  };

  const renderChat = () => {
    if (newChats?.length !== 0) {
      return newChats?.map((chat: any) => (
        <NewMessageItem conversation={chat} participants={[]} />
      ));
    }
    return (
      <div className="new-message-list__empty">
        <ConfirmSign className="chat-popup__loader" />
        <p className="chat-popup__loader-text paragraph-font paragraph-font--color">
          No new messages in chats for you
        </p>
      </div>
    );
  };

  const checkWhichChatToShow = () => {
    if (selectedParticipant) return showAssociatedChat();
    if (isNewChatAvailable) return renderChat();
    return (
      <div className="new-message-list__wrap">
        <div className="new-message-list">
          <div className="new-message-list__empty">
            <Loader className="chat-popup__loader" />
            <p className="chat-popup__loader-text paragraph-font paragraph-font--color">
              Loading
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handlePaginationChange = (it: any) => {
    setIsNewChatAvailable(null);
    setPage(it);
  };

  async function fetchMetaDataFromPubNub(
    unreadPatientIds: any[],
    pubNubSubscribeKey: string
  ) {
    try {
      const metadataFlags = await getAllChannelMetadata(
        adminId,
        unreadPatientIds,
        pubNubSubscribeKey
      );

      // Use Map for uniqueness based on 'id'
      const uniqueMetadataMap = new Map();
      metadataFlags.forEach((obj) => uniqueMetadataMap.set(obj.id, obj));

      // Filter the metadata where adminId flag is false
      const newMsgs = Array.from(uniqueMetadataMap.values()).filter(
        (obj) => obj.custom[adminId] === false
      );

      return newMsgs;
    } catch (error) {
      console.error("Error fetching metadata from PubNub:", error);
      throw error;
    }
  }

  async function unreadAllMessage() {
    try {
      const pubNubSubscribeKey = await HoneydewAPI.chats.pubNubSubscribeKey();
      setUnReadloading(true);
      const unreadPatientIds = allUnreadData.map((data) => data.patientId);
      const allData = await fetchMetaDataFromPubNub(
        unreadPatientIds,
        pubNubSubscribeKey
      );
      const pubnub = await pubnubClient({
        userId: adminId,
        pubNubSubscribeKey,
      });

      if (!pubnub) {
        throw new Error("PubNub client not initialized");
      }

      // Define a chunk size
      const chunkSize = 100;
      const chunks = [];
      for (let i = 0; i < allData.length; i += chunkSize) {
        chunks.push(allData.slice(i, i + chunkSize));
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const chunk of chunks) {
        console.log("chunk", chunk);
        const updatePromises = chunk.map(async ({ id, custom }) => {
          try {
            const updatedCustom = {
              ...custom,
              [adminId]: JSON.stringify({
                ...JSON.parse(custom?.[adminId] || "{}"),
                isUnread: false,
              }),
            };
            await pubnub.objects.setChannelMetadata({
              channel: id,
              data: {
                custom: updatedCustom,
              },
            });

            console.log(`Metadata updated for channel ${id}`);
          } catch (error) {
            console.error(
              `Failed to update metadata for channel ${id}:`,
              error
            );
          }
        });
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(updatePromises);
        const chunkPatientIds = chunk.map((data) => data.id);
        if (chunkPatientIds.length) {
          // eslint-disable-next-line no-await-in-loop
          await markAsReadByPatientIds({
            patientIds: chunkPatientIds,
            userId: adminId,
          });
        }
      }

      console.log("All metadata updated successfully");
    } catch (error) {
      setUnReadloading(false);
      console.error("Error updating metadata:", error);
      throw error;
    } finally {
      setUnReadloading(false);
      console.log("Finished processing unread messages");
      setAllUnreadMetaData([]);
      setAllUnreadData([]);
      setIsMarkAsRead(true);
    }
  }

  return (
    <div className="new-message-list__wrap">
      <div className="new-message-list__wrapper">
        <div className="new-message-list">{checkWhichChatToShow()}</div>
      </div>
      <div className="new-message-list__pagination">
        {userRole === "admins" ? (
          <div className="new-message-list__pagination__line">
            {providersQuery.data && careCoordinatorsQuery.data ? (
              <StyledSelect
                id="assigneeSelect"
                name="assigneeSelect"
                options={participantsOptions(
                  careCoordinatorsQuery.data as CareCoordinator[],
                  providersQuery.data as Provider[]
                )}
                onChange={(data: any) => {
                  setSelectedParticipant(data?.value || null);
                }}
                placeholder="Select a care team member"
                menuPlacement="top"
                isChatsSelect
                // menuPosition="absolute"
              />
            ) : (
              <Skeleton />
            )}
          </div>
        ) : null}
        <div className="new-message-list__pagination__line">
          {userRole === "admins" && (
            <div
              className={`new-message-list__mark-all-as-read new-message-list__mark-all-as-read--${
                unReadloading ? "loading" : "idle"
              }`}
              data-tooltip="Mark All As Read"
              onClick={unreadAllMessage}
            >
              {unReadloading ? (
                <Loader className="new-message-list__mark-all-as-read__icon" />
              ) : (
                <MarkSign className="new-message-list__mark-all-as-read__icon" />
              )}
            </div>
          )}
          <PatientsTablePagination
            max={Math.ceil(totalChats / NEW_MESSAGES_PER_PAGE)}
            current={page}
            onChange={(it) => handlePaginationChange(it)}
            simple
          />
        </div>
      </div>
    </div>
  );
}
