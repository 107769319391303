import React, { useContext } from "react";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import { DashboardDataContext } from "../../../../contexts/dashboard-data";

const RADIUS = 1;

interface Props {
  max: number;
  current: number;
  onChange: (value: number) => void;
  simple?: boolean;
  showMore?: boolean;
}

function renderEmphasis() {
  return <div className="patients-table__pagination-emphasis">...</div>;
}

function renderButton(
  number: number,
  onClick: (state: number) => void,
  isSelected?: boolean
) {
  return (
    <div
      className={`patients-table__pagination-button patients-table__pagination-button--page ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => (!isSelected ? onClick(number) : null)}
    >
      {number + 1 < 10 ? `0${number + 1}` : number + 1}
    </div>
  );
}

function renderPages(
  current: number,
  max: number,
  onClick: (state: number) => void,
  simple?: boolean
) {
  const radius = simple ? 0 : RADIUS;
  const items = [];

  let leftEmphasisSet = false;
  let rightEmphasisSet = false;

  if (simple) {
    return [renderButton(current, onClick, true)];
  }

  for (let i = 0; i < max; i++) {
    if (i < current) {
      if (i < current - radius && i !== 0) {
        if (!leftEmphasisSet) items.push(renderEmphasis());
        leftEmphasisSet = true;
      } else {
        items.push(renderButton(i, onClick));
      }
    } else if (i > current) {
      if (i > current + radius && i !== max - 1) {
        if (!rightEmphasisSet) items.push(renderEmphasis());
        rightEmphasisSet = true;
      } else {
        items.push(renderButton(i, onClick));
      }
    } else {
      items.push(renderButton(current, onClick, true));
    }
  }

  return items;
}

const disabledButtonStyle = {
  background: "#F5F5F5",
  cursor: "not-allowed",
};
export function PatientsTablePagination({
  max,
  current,
  onChange,
  simple,
  showMore,
}: Props) {
  const {
    miscellaneousTasks: { fetchMoreData, isFetching, lastEvaluatedKey },
  } = useContext(DashboardDataContext);
  if (showMore)
    if (lastEvaluatedKey)
      // has more items to be processed
      return (
        <button
          type="button"
          disabled={isFetching}
          className="patients-table__pagination-button patients-table__pagination-button--page patients-table__pagination-show-more"
          onClick={fetchMoreData}
        >
          {isFetching ? "Loading..." : "Load more"}
        </button>
      );
    else return null;
  return (
    <div className="patients-table__pagination">
      <div
        className="patients-table__pagination-button patients-table__pagination-button--prev"
        onClick={() => {
          if (current > 0) onChange(current - 1);
        }}
        style={max === 1 || current === 0 ? disabledButtonStyle : {}}
      >
        <ChevronIcon />
      </div>

      {renderPages(current, max, onChange, simple)}
      <div
        className="patients-table__pagination-button patients-table__pagination-button--next"
        onClick={() => {
          if (current < max - 1) onChange(current + 1);
        }}
        style={max === 1 || current === max - 1 ? disabledButtonStyle : {}}
      >
        <ChevronIcon />
      </div>
    </div>
  );
}
