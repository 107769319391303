import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import { useDispatch, useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { ReactComponent as ProfileDocument } from "../../../../Assets/icons/profile-document.svg";
import "./style.scss";
import { Patient } from "../../../../types/Entities/Patient";
import { StyledSelect } from "../StyledSelect";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import { Button } from "../Button";
import { SelectOption } from "../../Patient/FormsOfPregnancyPopup/types";
import { TextInput } from "../TextInput";
import { NotificationLayerContext } from "../NotificationLayer";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";
import { NumberInput } from "../NumberInput";
import { getSessionState } from "../../../../features/session";
import { UpdateItem } from "../../../../services/acneaway-api/entities/patients/update-patient-info";
import {
  useUpdatePatientInfoMutation,
  useUpdateCognitoUserMutation,
} from "../../../../features/api/patients";
import { show } from "../../../../features/errorNotification";
import camelizeText from "../../../../utils/camelize-text";

interface GeneratorProps {
  onClose: () => void;
}

interface EditPatientDetailsProps {
  patient: Patient;
  onClose: () => void;
}

interface BloodSlipPopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}

const adminRequiredFields = [
  "name",
  "sex",
  "dateOfBirth",
  "phoneNumber",
  "emailAddress",
  "height",
  "weight",
  // "parentEmail",
  // "parentPhone",
  // "parentName",
  // "addressLine1",
  // "city",
  // "state",
  // "zipCode",
];
const initialErrorsValuesForAdmin = adminRequiredFields.reduce((acc, it) => {
  acc[it] = null;
  return acc;
}, {} as Record<string, string | number | null>);

const coordinatorRequiredFields = [
  // "parentEmail",
  // "parentPhone",
  // "parentName",
  // "addressLine1",
  // "city",
  // "state",
  // "zipCode",
];
const initialErrorsValuesForCoordinator = coordinatorRequiredFields.reduce(
  (acc, it) => {
    acc[it] = null;
    return acc;
  },
  {} as Record<string, string | number | null>
);

const sexOptions = [
  {
    label: "Not selected",
    value: "Not selected",
  },
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

// todo extract to separate component, find same name in code
function BloodSlipPopupSection({
  title,
  description,
  fields,
}: BloodSlipPopupSectionProps) {
  return (
    <div className="edit-patient-details-popup__content__section">
      <div className="edit-patient-details-popup__content__section__head">
        <p className="edit-patient-details-popup__content__section__head__title">
          {title}
        </p>
        <p className="edit-patient-details-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="edit-patient-details-popup__content__section__fields">
        {fields.map((field, index) => (
          <div
            className="edit-patient-details-popup__content__section__fields__field"
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${title}__${description}__${index}`}
          >
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

const validateNameHandler = (
  fieldName: string,
  editableName: string,
  showError: any,
  setError: (fieldName: string, error: string | null) => void
) => {
  if (!editableName.length) {
    showError({
      title: "Error",
      description: "Full name cannot be empty",
    });
    setError(fieldName, "Full name cannot be empty");
    return;
  }

  if (editableName.length > 255) {
    showError({
      title: "Error",
      description: "Full name is too long",
    });
    setError(fieldName, "Full name is too long");
    return;
  }

  if (editableName.match(/[^a-zA-Z' -]/)) {
    setError(
      fieldName,
      "You can use only latin letters, space, - and ' symbols"
    );
    showError({
      title: "Error",
      description: "You can use only latin letters, space, - and ' symbols",
    });
    return;
  }

  const words = editableName.split(" ").filter((it) => !!it);
  if (words.length < 2 || !words.every((it) => /[a-zA-Z]/.test(it))) {
    showError({
      title: "Error",
      description: "Please, enter full name",
    });
    setError(fieldName, "Please, enter full name");
    return;
  }
  setError(fieldName, null);
};

const validatePhone = (
  fieldName: string,
  phoneNumber: string,
  showError: any,
  setError: any
) => {
  const phone = phoneNumber.replaceAll(/[^+0-9]/g, "");
  if (
    Joi.extend(JoiPhoneNumber)
      .string()
      .phoneNumber({
        strict: true,
        format: "e164",
      })
      .validate(phone).error
  ) {
    setError(fieldName, "Invalid phone number");
    showError({
      title: "Error",
      description: "Please, enter valid phone number",
    });
    return;
  }
  setError(fieldName, null);
};

const validateEmail = (
  fieldName: string,
  value: string,
  showError: any,
  setError: any
) => {
  if (
    Joi.string()
      .email({ tlds: { allow: false } })
      .validate(value).error
  ) {
    setError(fieldName, "Invalid email");
    showError({
      title: "Error",
      description: "Please, enter valid email",
    });
  }
};

const validateParentInfo = (
  parentName: string,
  parentEmail: string,
  parentPhone: string,
  showError: any,
  setError: any
) => {
  validateNameHandler("parentName", parentName, showError, setError);
  validatePhone("parentPhone", parentPhone, showError, setError);
  validateEmail("parentEmail", parentEmail, showError, setError);
};

const validateFormRequiredFields = (
  changedFields: Record<string, string | number | null>,
  requiredFields: string[],
  setError: any
) => {
  const changedFieldsKeys = Object.keys(changedFields);
  const fieldsWithErrors: string[] = [];

  changedFieldsKeys.forEach((fieldKey) => {
    if (requiredFields.includes(fieldKey) && !changedFields[fieldKey]) {
      setError(fieldKey, "Field required");
      fieldsWithErrors.push(fieldKey);
    } else {
      setError(fieldKey, null);
    }
  });

  return fieldsWithErrors.length === 0;
};

const mapPatientsToPayload = (
  formValues: Record<string, string | number | null | any>
): UpdateItem[] => {
  const payload: UpdateItem[] = [];
  if (formValues.name) {
    payload.push({
      key: "fullName",
      value: formValues.name,
      queryType: "String!",
      queryName: "updatePatientName",
    });
  }
  if (formValues.phoneNumber) {
    payload.push({
      key: "phone",
      value: formValues.phoneNumber,
      queryType: "String!",
      queryName: "updatePatientPhone",
    });
  }
  if (formValues.emailAddress) {
    payload.push({
      key: "email",
      value: formValues.emailAddress,
      queryType: "String!",
      queryName: "updatePatientEmail",
    });
  }
  if (formValues.sex) {
    payload.push({
      key: "sex",
      value: formValues.sex,
      queryType: "String!",
      queryName: "updatePatientSex",
    });
  }
  if (formValues.height.feet || formValues.height.inches) {
    const totalHeight = formValues.height.feet * 12 + formValues.height.inches;

    payload.push({
      key: "height",
      value: totalHeight,
      queryType: "Int!",
      queryName: "updatePatientHeight",
    });
  }
  if (formValues.weight) {
    payload.push({
      key: "weight",
      value: formValues.weight,
      queryType: "Int!",
      queryName: "updatePatientWeight",
    });
  }
  if (formValues.diagnosisCodes.length) {
    payload.push({
      key: "diagnosisCodes",
      value: formValues.diagnosisCodes,
      queryType: "[String!]!",
      queryName: "updatePatientDiagnosisCodes",
    });
  }
  if (
    formValues.parentName ||
    formValues.parentPhone ||
    formValues.parentEmail
  ) {
    payload.push({
      key: "parentInfo",
      value: {
        name: formValues.parentName,
        email: formValues.parentEmail,
        phone: formValues.parentPhone,
      },
      queryType: "ParentInfoInput!",
      queryName: "updatePatientParentInfo",
    });
  }
  if (formValues.dateOfBirth) {
    payload.push({
      key: "dateOfBirth",
      value: formValues.dateOfBirth,
      queryType: "String!",
      queryName: "updatePatientDateOfBirth",
    });
  }
  if (
    formValues.addressLine1 ||
    formValues.addressLine2 ||
    formValues.city ||
    formValues.state ||
    formValues.zipCode
  ) {
    payload.push({
      key: "shippingInfo",
      value: {
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2,
        city: formValues.city,
        state: formValues.state,
        zipCode: formValues.zipCode,
      },
      queryType: "ShippingInfoInput!",
      queryName: "updatePatientShippingInfo",
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return payload;
};

function EditPatientDetailsPopup({
  patient,
  onClose,
}: EditPatientDetailsProps) {
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const dispatch = useDispatch();
  const [calendarVisible, setVisible] = useState(false);
  const [formSubmitting, setFormSubmitted] = useState(false);
  const [updatePatientInfo] = useUpdatePatientInfoMutation();
  const [updateCognitoUser] = useUpdateCognitoUserMutation();
  const { userRole } = useSelector(getSessionState);
  const initialValues: Record<string, string | string[] | number | null | any> =
    {
      name: patient.fullName,
      sex: patient.medicalBackground?.sex,
      dateOfBirth: patient.dateOfBirth,
      phoneNumber: patient.phone,
      emailAddress: patient.email,
      diagnosisCodes: [],
      height: {
        feet: patient.medicalBackground?.height
          ? Math.floor(patient.medicalBackground.height / 12)
          : null,
        inches: patient.medicalBackground?.height
          ? Math.floor(patient.medicalBackground.height % 12)
          : null,
      },
      weight: patient.medicalBackground?.weight,
      parentEmail: patient.parentInfo?.email,
      parentPhone: patient.parentInfo?.phone,
      parentName: patient.parentInfo?.name,
      addressLine1: patient.shippingInfo?.addressLine1,
      addressLine2: patient.shippingInfo?.addressLine2,
      city: patient.shippingInfo?.city,
      state: patient.shippingInfo?.state,
      zipCode: patient.shippingInfo?.zipCode,
    };
  const [formValues, setFormField] = useState<
    Record<string, string | number | null | any>
  >({ ...initialValues });
  const requiredFields =
    userRole === "admins" ? adminRequiredFields : coordinatorRequiredFields;
  const shouldShowParentsInfo =
    initialValues.parentPhone ||
    initialValues.parentName ||
    initialValues.parentEmail;
  const [formErrors, setError] = useState<Record<string, any>>(
    userRole === "admins"
      ? initialErrorsValuesForAdmin
      : initialErrorsValuesForCoordinator
  );
  const showSubmitButton = Object.keys(formValues).some((key) => {
    const changedValueByKey = formValues[key];
    const initialValueByKey = initialValues[key];

    return (
      changedValueByKey !== null && changedValueByKey !== initialValueByKey
    );
  });

  const changeFieldValue = (fieldName: string) => (value: string) => {
    // this is temp fix, later I'll add validation for email and phone number regx.
    setFormField({
      ...formValues,
      [fieldName]:
        fieldName === "parentEmail" || fieldName === "emailAddress"
          ? value?.toLowerCase()
          : camelizeText(value),
    });
  };

  const changeBirthdateValue = (value: Date) => {
    setFormField({
      ...formValues,
      dateOfBirth: moment(value).format("MM/DD/YYYY"),
    });
    setVisible(false);
  };

  const handleFormError = (fieldName: string, errorMessage: string | null) => {
    setError((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    validateFormRequiredFields(formValues, requiredFields, handleFormError);
    if (userRole === "admins") {
      validateNameHandler(
        "patientName",
        formValues.name,
        showError,
        handleFormError
      );
      validatePhone(
        "phoneNumber",
        formValues.phoneNumber,
        showError,
        handleFormError
      );
    }

    if (initialValues.parentName) {
      validateParentInfo(
        formValues.parentName,
        formValues.parentEmail,
        formValues.parentPhone,
        showError,
        handleFormError
      );
    }
  };

  const onSaveChangesHandler = () => {
    validateForm();
    setFormSubmitted(true);
  };

  useEffect(() => {
    (async () => {
      if (formSubmitting) {
        const formHasNoErrors = Object.values(formErrors).every(
          (value) => !value
        );

        if (formHasNoErrors) {
          const payload = mapPatientsToPayload(formValues);
          const emailElement = payload.find((item) => item.key === "email");
          const email = emailElement ? emailElement.value : null;
          if (patient.email !== email) {
            const result: any = await updateCognitoUser({
              userId: patient?.patientId,
              userName: patient?.accountId,
              newEmail: email,
            });
            if (result.data?.error) {
              showError({
                title: "Something went wrong...",
                description: result.data?.error,
              });
              setFormSubmitted(false);
              return;
            }
            if (result.data?.success) {
              await updatePatientInfo({
                patientId: patient.patientId,
                items: payload,
              });
            }
          } else {
            await updatePatientInfo({
              patientId: patient.patientId,
              items: payload,
            });
          }
          showSuccess({
            title: "Success",
            description: "Patient details successfully updated",
          });
          onClose();
        } else {
          setFormSubmitted(false);
        }
      }
    })();
  }, [formSubmitting]);

  const changeWeightHandler = (value: any) => {
    const intValue = value ? parseInt(value, 10) : undefined;
    setFormField({ ...formValues, weight: intValue });
  };

  return (
    <div className="edit-patient-details-popup">
      {showSubmitButton && (
        <Button
          text="Save changes"
          className="edit-patient-details-popup__save-button"
          size="small"
          onClick={onSaveChangesHandler}
          disabled={formSubmitting || !formValues.weight}
        />
      )}
      <p className="edit-patient-details-popup__info">
        After you save the applied changes the details will be changed for
        patient as well.
      </p>
      <div className="edit-patient-details-popup__content">
        {(userRole === "admins" || userRole === "care-coordinators") && (
          <>
            <BloodSlipPopupSection
              title="Photo"
              description=""
              fields={[
                <ProfileDocument style={{ width: "72px", height: "72px" }} />,
              ]}
            />
            {/* <BloodSlipPopupSection
              title="Diagnosis codes"
              description=""
              fields={[
                <>
                  <p className="edit-patient-details-popup__input-title">
                    Diagnosis codes
                  </p>
                  <TextInput
                    id="diagnosis-codes"
                    name="diagnosis-codes"
                    placeholder="Enter diagnosis codes"
                    onChange={(value) => {
                      console.log("diagnosis codes", value);
                    }}
                  />
                </>,
              ]}
            /> */}
            <BloodSlipPopupSection
              title="Personal details"
              description=""
              fields={[
                <>
                  <p className="edit-patient-details-popup__input-title">
                    Patient's name
                  </p>
                  <TextInput
                    id="patient-name-input"
                    error={formErrors?.patientName ?? null}
                    name="patientName"
                    value={formValues?.name ?? initialValues?.name ?? ""}
                    onChange={changeFieldValue("name")}
                  />
                </>,
                <>
                  <p className="edit-patient-details-popup__input-title">Sex</p>
                  <StyledSelect
                    id="patient-sex"
                    name="patientSex"
                    isClearable={false}
                    onChange={(changedValue: SelectOption) => {
                      const changeHandler = changeFieldValue("sex");
                      changeHandler(changedValue.value);
                    }}
                    defaultValue={
                      initialValues.sex
                        ? {
                            label: capitalizeFirstLetter(
                              initialValues.sex as string
                            ),
                            value: initialValues.sex,
                          }
                        : {
                            label: "Not selected",
                            value: "Not selected",
                          }
                    }
                    options={sexOptions}
                  />
                </>,
                <div style={{ position: "relative" }}>
                  <p className="edit-patient-details-popup__input-title">
                    Date of birth
                  </p>
                  <TextInput
                    id="date-of-birth"
                    name="patientDateOfBirth"
                    value={
                      (formValues?.dateOfBirth as string) ||
                      (initialValues?.dateOfBirth as string) ||
                      ""
                    }
                    error={formErrors?.dateOfBirth ?? null}
                    onChange={() => null}
                    onBlur={() => setVisible(true)}
                  />
                  {calendarVisible && (
                    <OutsideClickHandler
                      onClickOutside={() => {
                        setVisible(false);
                      }}
                    >
                      <Calendar
                        calendarType="US"
                        minDetail="year"
                        defaultValue={new Date(patient.dateOfBirth)}
                        onChange={(date: Date) => changeBirthdateValue(date)}
                        className="edit-patient-calendar"
                        maxDate={new Date()}
                      />
                    </OutsideClickHandler>
                  )}
                </div>,
                <div className="height-wrapper">
                  <NumberInput
                    id="height-feet"
                    name="height-feet"
                    min={0}
                    step={1}
                    onChange={(feet) => {
                      if (/^[0-9]*$/.test(feet)) {
                        setFormField({
                          ...formValues,
                          height: {
                            ...formValues.height,
                            feet,
                          },
                        });
                      }
                    }}
                    value={
                      formValues.height?.feet || initialValues?.height?.feet
                    }
                    label="Height (feet)"
                  />
                  <NumberInput
                    id="height-inch"
                    name="height-inch"
                    min={0}
                    max={11}
                    step={1}
                    onChange={(inches) => {
                      if (/^[0-9]*$/.test(inches)) {
                        setFormField({
                          ...formValues,
                          height: {
                            ...formValues.height,
                            inches,
                          },
                        });
                      }
                    }}
                    value={
                      formValues.height?.inches || initialValues?.height?.inches
                    }
                    label="Height (inches)"
                  />
                </div>,
                <>
                  <p className="edit-patient-details-popup__input-title">
                    Weight (lbs)
                  </p>
                  <TextInput
                    id="weight"
                    name="weight"
                    error={formErrors?.weight ?? null}
                    value={formValues?.weight}
                    onChange={changeWeightHandler}
                  />
                </>,
              ]}
            />
            <BloodSlipPopupSection
              title="Contact details"
              description=""
              fields={[
                <>
                  <p className="edit-patient-details-popup__input-title">
                    Phone number
                  </p>
                  <TextInput
                    id="phone-number"
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    error={formErrors?.phoneNumber ?? null}
                    value={
                      formValues?.phoneNumber ??
                      initialValues?.phoneNumber ??
                      ""
                    }
                    onChange={changeFieldValue("phoneNumber")}
                  />
                </>,
                <>
                  <p className="edit-patient-details-popup__input-title">
                    Email address
                  </p>
                  <TextInput
                    id="email-address"
                    name="emailAddress"
                    // disabled
                    error={formErrors?.emailAddress ?? null}
                    value={
                      formValues?.emailAddress ??
                      initialValues?.emailAddress ??
                      ""
                    }
                    onChange={changeFieldValue("emailAddress")}
                  />
                </>,
              ]}
            />
          </>
        )}
        {shouldShowParentsInfo && (
          <BloodSlipPopupSection
            title="Parent's info"
            description=""
            fields={[
              <>
                <p className="edit-patient-details-popup__input-title">
                  Parent name
                </p>
                <TextInput
                  id="parent-name"
                  name="parentName"
                  placeholder="Enter parent name"
                  error={formErrors?.parentName ?? null}
                  value={
                    formValues?.parentName ?? initialValues.parentName ?? ""
                  }
                  onChange={changeFieldValue("parentName")}
                />
              </>,
              <>
                <p className="edit-patient-details-popup__input-title">
                  Parent email
                </p>
                <TextInput
                  id="parent-email"
                  name="parentEmail"
                  error={formErrors?.parentEmail ?? null}
                  value={
                    formValues?.parentEmail ?? initialValues.parentEmail ?? ""
                  }
                  onChange={changeFieldValue("parentEmail")}
                />
              </>,
              <>
                <p className="edit-patient-details-popup__input-title">
                  Parent phone
                </p>
                <TextInput
                  id="parent-phone"
                  name="parentPhone"
                  error={formErrors?.parentPhone ?? null}
                  value={
                    formValues?.parentPhone ?? initialValues.parentPhone ?? ""
                  }
                  onChange={changeFieldValue("parentPhone")}
                />
              </>,
            ]}
          />
        )}
        <BloodSlipPopupSection
          title="Shipping details"
          description=""
          fields={[
            <>
              <p className="edit-patient-details-popup__input-title">
                Address line 1
              </p>
              <TextInput
                id="address-line-1"
                name="addressLine1"
                error={formErrors?.addressLine1 ?? null}
                value={
                  formValues?.addressLine1 ?? initialValues?.addressLine1 ?? ""
                }
                onChange={changeFieldValue("addressLine1")}
              />
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">
                Address line 2
              </p>
              <TextInput
                id="address-line-2"
                name="addressLine2"
                error={formErrors?.addressLine2 ?? null}
                value={
                  formValues?.addressLine2 ?? initialValues?.addressLine2 ?? ""
                }
                onChange={changeFieldValue("addressLine2")}
              />
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">City</p>
              <TextInput
                id="city"
                name="city"
                error={formErrors?.city ?? null}
                value={formValues?.city ?? initialValues?.city ?? ""}
                onChange={changeFieldValue("city")}
              />
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">State</p>
              <TextInput
                id="state"
                name="state"
                error={formErrors?.state ?? null}
                value={formValues?.state ?? initialValues?.state ?? ""}
                onChange={changeFieldValue("state")}
              />
            </>,
            <>
              <p className="edit-patient-details-popup__input-title">
                Zip code
              </p>
              <TextInput
                id="zip-code"
                name="zipCode"
                error={formErrors?.zipCode ?? null}
                value={formValues?.zipCode ?? initialValues?.zipCode ?? ""}
                onChange={changeFieldValue("zipCode")}
              />
            </>,
          ]}
        />
      </div>
      {showSubmitButton && (
        <Button
          text="Save changes"
          className="edit-patient-details-popup__save-button--mobile"
          size="small"
          onClick={onSaveChangesHandler}
          disabled={formSubmitting}
        />
      )}
    </div>
  );
}

function EditPatientDetailsPopupGenerator(patient: Patient) {
  return function render({ onClose }: GeneratorProps) {
    return (
      <ActionPopupTemplate title="Edit patient's details" onClose={onClose}>
        <EditPatientDetailsPopup patient={patient} onClose={onClose} />
      </ActionPopupTemplate>
    );
  };
}

export default EditPatientDetailsPopupGenerator;
