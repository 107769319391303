import React, { useEffect, useState } from "react";
import { Button, Box, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as NextArrow } from "../../../../../Assets/NewIcons/card-arrow.svg";
import { getSessionState } from "../../../../../features/session";
import { CustomerInfo, PaymentMethod } from "../../../../../types/CustomerInfo";
import { HoneydewAPI } from "../../../../../services/honeydew-api";
import { CardName } from "./styles";

interface Props {
  defaultPaymentMethod?: string;
  handleChangePaymentMethod: (customerInfo: CustomerInfo) => void;
  handleDefaultPaymentMethodChange: (paymentMethodId?: string) => void;
}

export default function PaymentMethodButton({
  defaultPaymentMethod,
  handleChangePaymentMethod,
  handleDefaultPaymentMethodChange,
}: Props) {
  const { activePatientId } = useSelector(getSessionState);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);

  useEffect(() => {
    async function getStripeCustomerInfo() {
      if (!activePatientId) return;
      const _customerInfo = await HoneydewAPI.payments.getCustomerInfo(
        activePatientId
      );

      const paymentMethod = defaultPaymentMethod
        ? _customerInfo.paymentMethods.find(
            (payment) => payment.id === defaultPaymentMethod
          )
        : _customerInfo.paymentMethods[0];
      setCustomerInfo(_customerInfo);
      setSelectedPaymentMethod(paymentMethod ?? null);
      handleDefaultPaymentMethodChange(paymentMethod?.id);
    }

    getStripeCustomerInfo();
  }, [activePatientId, defaultPaymentMethod]);

  return (
    <Button
      fullWidth
      variant="outlined"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 16px",
        borderRadius: "24px",
        borderColor: "#E0E0E0",
        backgroundColor: "#FFFFFF",
        textTransform: "none",
        color: "#403E48",
      }}
      onClick={() => handleChangePaymentMethod(customerInfo)}
    >
      {selectedPaymentMethod ? (
        <>
          <CardName>
            {`Paying with ${selectedPaymentMethod?.brand} ending ${selectedPaymentMethod?.cardNumber}`}
          </CardName>
          <Box
            sx={{
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            <NextArrow />
          </Box>
        </>
      ) : (
        <Skeleton width="100%" />
      )}
    </Button>
  );
}
