interface LabHistory {
  status: string;
}

export const calculateProgress = (histories: LabHistory[]) => {
  if (histories.find((history) => history.status === "PARTIAL_RESULTS")) {
    return 3;
  }
  if (histories.find((history) => history.status === "REQUISITION_CREATED")) {
    return 2;
  }
  return 1;
};
