import React, { useEffect } from "react";
import { Box, Typography, Grid, RadioGroup, Radio } from "@mui/material";
import { CustomRadioButton } from "../../../Common/Material/RadioButton";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { getPriceRange } from "../../../../../utils/lab-work/get-price-range";
import { ReactComponent as CheckIcon } from "../../../../../Assets/NewIcons/check-icon.svg";

export default function InsuranceSelection() {
  const {
    handleNext,
    updateValues,
    setIsNextDisabled,
    pregnancyWork,
    labsWithPricing,
  } = usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(!pregnancyWork.paymentMethod);
  }, [pregnancyWork.paymentMethod]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as "cash" | "with-insurance";
    if (value) {
      updateValues({ paymentMethod: value });
      handleNext();
    }
  };

  const priceRange = getPriceRange(labsWithPricing);

  return (
    <Grid container direction="column" p={2}>
      <Typography variant="h5" gutterBottom>
        How are you paying for your blood work?
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={handleChange}
        defaultValue={pregnancyWork.paymentMethod}
      >
        <Grid container columnGap={8}>
          <Grid item xs={12} md={5}>
            <CustomRadioButton
              value="cash"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">
                    Directly through Honeydew
                  </Typography>
                  {priceRange && (
                    <>
                      <Typography
                        style={{ display: "inline" }}
                        variant="subtitle2"
                      >
                        Total estimated cost:{" "}
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          color: "common.fernGreen",
                          fontWeight: 500,
                        }}
                        variant="subtitle2"
                      >
                        {priceRange}
                      </Typography>
                    </>
                  )}
                  <Box marginTop="1rem">
                    <Typography variant="subtitle2">
                      <CheckIcon
                        style={{ color: "gray", marginRight: "3px" }}
                      />
                      Access our discounted prices
                    </Typography>
                    <Typography variant="subtitle2">
                      <CheckIcon
                        style={{ color: "gray", marginRight: "3px" }}
                      />
                      Results will be sent automatically
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <CustomRadioButton
              value="with-insurance"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">With insurance</Typography>
                  <Typography variant="subtitle2">
                    Subject to your copay and deductible
                  </Typography>
                  <Box marginTop="1rem">
                    <Typography
                      variant="subtitle2"
                      sx={{ display: "inline", fontWeight: "bold" }}
                    >
                      Note:{" "}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ display: "inline" }}>
                      Results must be faxed or uploaded
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  );
}
