import React from "react";

export function CheckedIcon({ filled }: { filled?: boolean }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="17" height="17" rx="4" fill="#60B257" />
      <path
        d="M12.75 6L7.5 12L5.25 9.75"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
