import { useHistory, useLocation } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useState } from "react";
import {
  sendOnboardingStarted,
  sendScheduleFinished,
  sendSurveyFinished,
} from "../shared/analytics";

export enum Step {
  SCHEDULE_APPOINTMENT = "submit-appointment",
  MEDICAL_BACKGROUND = "skin-survey",
  REGISTRATION = "registration",
  ALL_SET = "all-set",
}

const experimentalOrder = [
  Step.REGISTRATION,
  Step.MEDICAL_BACKGROUND,
  Step.SCHEDULE_APPOINTMENT,
  Step.ALL_SET,
];
const defaultOrder = [
  Step.REGISTRATION,
  Step.SCHEDULE_APPOINTMENT,
  Step.MEDICAL_BACKGROUND,
  Step.ALL_SET,
];

const analyticsEventWhenFinished = {
  [Step.REGISTRATION]: sendOnboardingStarted,
  [Step.SCHEDULE_APPOINTMENT]: sendScheduleFinished,
  [Step.MEDICAL_BACKGROUND]: sendSurveyFinished,
};

function isLastStep(order: Step[], index: number) {
  return index === order.length - 1;
}

export function useSurveySteps(currentStep: Step) {
  const history = useHistory();
  const location = useLocation();
  const inOnboarding = location?.state?.inOnboarding;

  const moveFromCurrentStep = (userId: string, featureEnabled: boolean) => {
    const orderToUse = featureEnabled ? experimentalOrder : defaultOrder;

    if (currentStep in analyticsEventWhenFinished)
      analyticsEventWhenFinished[currentStep]({
        experiment: { variation: featureEnabled },
        userId,
      });

    const currentStepIndex = orderToUse.indexOf(currentStep);
    const hasNextStep =
      currentStepIndex >= 0 && currentStepIndex < orderToUse.length - 1;
    const isFirstStep = currentStepIndex === 0;

    if (hasNextStep && (inOnboarding || isFirstStep)) {
      const nextStep = orderToUse[currentStepIndex + 1];
      history.push(`/${nextStep}`, { inOnboarding: true });
    } else if (isLastStep(orderToUse, currentStepIndex) || !inOnboarding) {
      history.push("/");
    }
  };

  return [moveFromCurrentStep];
}
