import React from "react";
import "./style.scss";
import { ActionPopupTemplate } from "../../../../Components/Common/ActionPopup";
import { Patient } from "../../../../../types/Entities/Patient";
import { Provider } from "../../../../../types/Entities/Employee";
import { SendBloodSlipPopup } from "../../../../NewComponents/Common/SendBloodSlipPopup";

interface Props {
  onClose: () => void;
}

interface SendBloodSlipData {
  patient: Patient;
  provider: Provider;
  data: any;
  onSave: (data: any) => void;
  isPregnantCapability: boolean;
}

/**
 * Lipid Profile, CMP - if can not get pregnant
 * Lipid Profile, CMP, HCG - if can get pregnant
 */

function BloodSlipFormPopupGenerator(sendBloodSlipData: SendBloodSlipData) {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate title="Edit lab slip form" onClose={onClose}>
        <SendBloodSlipPopup
          onClose={onClose}
          patient={sendBloodSlipData.patient}
          provider={sendBloodSlipData.provider}
        />
      </ActionPopupTemplate>
    );
  };
}

export default BloodSlipFormPopupGenerator;
