import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FastingBanner } from "../../../Common/FastingBanner";
import { Lab } from "../../../../../types/Entities/Lab";

export const steps = [
  {
    label: "Order placed",
    description: <p />,
    completed: true,
    key: "download_lab_slip",
  },
  {
    label: "Requisition form created",
    description: (
      <Box>
        <Typography variant="subtitle2">
          We will send the lab order directly to your lab. It will also populate
          in your
          <Link
            to="/document"
            target="_blank"
            style={{ textDecoration: "none" }}
          >{` Documents tab `}</Link>{" "}
          in case you want to bring it with you.
        </Typography>
      </Box>
    ),
    completed: false,
    key: "submit_results",
  },
  {
    label: "Visit your lab and complete your blood test",
    description: undefined,
    completed: false,
    key: "blood_test",
  },
  {
    label: "The lab will send us your test results ",
    description: undefined,
    completed: false,
    key: "lab_send",
  },
  {
    label: "Your provider will review the results",
    description: undefined,
    completed: false,
    key: "review",
  },
];

export const getSteps = ({
  needsFasting,
  lab,
}: {
  needsFasting?: boolean;
  lab?: Lab;
}) =>
  steps.map((step) => {
    if (step.key === "blood_test" && needsFasting) {
      return {
        ...step,
        description: <FastingBanner />,
      };
    }
    if (step.key === "submit_results" && lab?.metadata) {
      return {
        ...step,
        description: (
          <Box>
            <Typography variant="subtitle2">
              We will send the lab order directly to your lab:
            </Typography>
            <Typography variant="subtitle2" mt={2}>
              {lab?.metadata.name}
            </Typography>
            <Typography variant="subtitle2">
              {`${lab?.metadata?.firstLine ?? ""} ${
                lab?.metadata?.secondLine ?? ""
              }`}
            </Typography>
            <Typography variant="subtitle2" mb={2}>
              {` ${lab?.metadata?.city}, ${lab?.metadata?.state} ${lab?.metadata?.zipCode}`}
            </Typography>
            <Typography variant="subtitle2">
              It will also populate in your
              <Link
                to="/document"
                target="_blank"
                style={{ textDecoration: "none" }}
              >{` Documents tab `}</Link>
              in case you want to bring it with you.
            </Typography>
          </Box>
        ),
      };
    }
    return step;
  });
