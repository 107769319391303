import React, { useEffect, useState } from "react";
import { MenuItem, ListItemText } from "@mui/material";
import "./style.css";
import { SearchInput } from "../../../NewComponents/Common/SearchInput";
import { Checkbox } from "../../../NewComponents/Common/Checkbox";

function PriorityEditor({
  providerList,
  editingRecord,
  priority,
  priorityToSet,
  state,
  updatePriorities,
}: any) {
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [remainingProviders, setRemainingProviders] = useState([]);
  const [checkedProviders, setCheckedProviders] = useState([]);
  const [searchText, setSearchText] = useState(""); // State to hold search text

  useEffect(() => {
    const stateProviders = providerList.find(
      (item) => item.state === editingRecord
    )?.providers;

    const selectedProvidersList = stateProviders.filter((provider) =>
      priority.includes(provider.name)
    );

    const remainingProvidersList = stateProviders.filter(
      (provider) => !priority.includes(provider.name)
    );

    setSelectedProviders(selectedProvidersList);
    setCheckedProviders(selectedProvidersList);
    setRemainingProviders(remainingProvidersList);
  }, [providerList, editingRecord, priority]);

  const handleProviderChange = (provider) => {
    setCheckedProviders((prev) => {
      const updated = prev.some((item) => item.name === provider.name)
        ? prev.filter((item) => item.name !== provider.name) // Deselect
        : [...prev, provider]; // Select

      // Generate the payload for both selected and deselected providers
      const selectedEmployeeIds = updated.map((item) => ({
        id: item.id,
        priority: priorityToSet,
      }));

      const deselectedProviders = selectedProviders.filter(
        (item) => !updated.some((checked) => checked.name === item.name)
      );

      const deselectedEmployeeIds = deselectedProviders.map((item) => ({
        id: item.id,
        priority: 0,
      }));

      const payload = {
        state,
        priorities: [...selectedEmployeeIds, ...deselectedEmployeeIds],
      };

      updatePriorities(payload); // Ensure `updatePriorities` is called with the correct data
      return updated;
    });
  };

  const filteredSelectedProviders = selectedProviders.filter((provider) =>
    provider.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredRemainingProviders = remainingProviders.filter((provider) =>
    provider.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="priority-editor-container">
      <div className="priority-editor-seearch-container">
        <SearchInput
          id="search"
          name="search"
          placeholder="Search"
          onChange={(text) => setSearchText(text.trim())} // Update search text
          inputClassName="priority-editor-search"
        />
      </div>
      <div className="priority-editor-lists">
        <div>
          {filteredSelectedProviders.length > 0 && (
            <>
              <h4 className="priority-editor-providers">Selected</h4>
              {filteredSelectedProviders.map((provider, idx) => (
                <MenuItem key={provider.id} value={provider.name}>
                  <Checkbox
                    checked={checkedProviders.some(
                      (item) => item.name === provider.name
                    )}
                    onChange={() => handleProviderChange(provider)}
                  >
                    <ListItemText
                      primary={`${provider.name} ${provider.priority}`}
                    />
                  </Checkbox>
                </MenuItem>
              ))}
            </>
          )}
        </div>
        <div>
          {filteredRemainingProviders.length > 0 && (
            <>
              <h4 className="priority-editor-providers">All Providers</h4>
              {filteredRemainingProviders.map((provider, idx) => (
                <MenuItem key={provider.id} value={provider.name}>
                  <Checkbox
                    checked={checkedProviders.some(
                      (item) => item.name === provider.name
                    )}
                    onChange={() => handleProviderChange(provider)}
                  >
                    <ListItemText
                      primary={`${provider.name} ${provider.priority}`}
                    />
                  </Checkbox>
                </MenuItem>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PriorityEditor;
