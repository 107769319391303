/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNull } from "util";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import "./style.scss";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { Administrator } from "../../../../types/Employee";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { Stylings } from "../../../../types/Table/TableBodyPayload";
import {
  patientDashboardForAdministratorFilters,
  patientDashboardForAdministratorFiltersInactive,
} from "./filters";
import { patientDashboardForAdministratorSort } from "./sortings";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import { getTwilioState } from "../../../../features/twilio";
import { patientDashboardForAdministratorOrder } from "./orders";
import { tableColumns } from "./columns";
import { fetchChatData } from "../../../../utils/employee/fetch-chat-data";
import { DashboardDataContext } from "../../../../contexts/dashboard-data";

function getStyleForDashboard(employeeId: string) {
  return (payload: PatientDashboardItem, currentFilter?: string) => {
    switch (true) {
      case currentFilter === "New follow-ups":
        return Stylings.Blue;
      case currentFilter === "Miscellaneous tasks":
        return Stylings.Orange;
      case currentFilter === "New patients":
        return Stylings.Red;
      case payload.followUp &&
        !payload.followUp.isInitialFollowUp &&
        !payload.followUp.isTreatmentPlanSet:
        return Stylings.Blue;
      case payload.tasks?.includes(employeeId):
        return Stylings.Orange;
      case !payload.followUp?.isTreatmentPlanSet &&
        (payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED"):
        return Stylings.Red;

      default:
        return Stylings.Base;
    }
  };
}

const tabs = ["Active Patients", "Inactive Patients"];

export function PatientsPageForAdministrator() {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);
  const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);
  const { userId: adminId, newMsgsCount } = useSelector(getSessionState);
  const adminQuery = useGetEmployeeQuery(adminId as string, {
    skip: !adminId,
  });
  const administrator = adminQuery.data as Administrator | undefined;

  const adminName = administrator
    ? [administrator.firstName, administrator.lastName]
    : null;

  const [filterArray, setFilterArray] = useState([]);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);
  const activeDashboardData = useContext(DashboardDataContext);
  const prepopulatedList = {
    0: activeDashboardData,
  };

  useEffect(() => {
    const role = adminQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "administrator":
          newFilterArray = patientDashboardForAdministratorFilters(
            adminId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, adminQuery, adminId]);

  const filterChatBydata = async (data: any) => {
    // Modify the existing data by adding lastUpdated and authorIds attributes
    const updatedChats = data.map((item: any) => {
      const {
        _source: { chat, latestChatTimestamp },
      } = item;

      if (chat && Array.isArray(chat)) {
        // Extract userId from each chat entry and remove duplicates
        const authorIds = [...new Set(chat.map((entry: any) => entry.userId))];

        // Add authorIds and lastUpdated to the existing data item
        item._source.authorIds = authorIds; // Adding authorIds to the existing object
        item._source.lastUpdated = latestChatTimestamp; // Adding lastUpdated to the existing object
      }

      return item; // Return the updated object
    });
    // Dispatch updated chat count based on the filtered result
    dispatch(setNewMsgsCount(updatedChats.length));
  };

  useEffect(() => {
    if (filterArray.length > 0) {
      const fetchData = async () => {
        try {
          // Call the utility function to fetch data
          const filteredChats = await fetchChatData({
            filterArray,
            userId: adminId,
          });

          // Set the filtered chats and pass them to another function
          setFilteredChat(filteredChats);
          filterChatBydata(filteredChats);
        } catch (error) {
          console.log("Error in fetching chat data", error);
        }
      };

      fetchData();
    }
  }, [filterArray, adminId]);

  // useEffect(() => {
  //   if (filteredChat.length && !newMsgsCount && patientIds.length > 0) {
  //     const fetchData = async () => {
  //       try {
  //         const metadataFlags = await getAllChannelMetadata(
  //           adminId,
  //           patientIds
  //         );
  //         const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
  //           acc[obj.id] = obj;
  //           return acc;
  //         }, {});
  //         const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

  //         const newMsgs = uniqueMetadataArray.filter(
  //           (obj) => obj.custom[adminId] === false
  //         );
  //         const filteredResult = filteredChat.filter((chat) => {
  //           const chatMetaData = newMsgs.find(
  //             (metaData) => metaData.id === chat._source.patientId
  //           );
  //           if (chatMetaData) {
  //             return true;
  //           }
  //           return false;
  //         });
  //         dispatch(setNewMsgsCount(filteredResult.length));
  //         // dispatch(setNewMsgsCount(newMsgs.length));
  //         // setNewMessagesCount(newMsgsCount);
  //       } catch (error) {
  //         // Handle error here if needed
  //         console.error("Error fetching metadata flags:", error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [filteredChat, patientIds]);

  useEffect(() => {
    if (!adminId) return;
    setTableParams([
      {
        filters: patientDashboardForAdministratorFilters(adminId as string),
        sort: patientDashboardForAdministratorSort(),
        order: patientDashboardForAdministratorOrder(adminId as string),
      },
      {
        filters: patientDashboardForAdministratorFiltersInactive,
        sort: [],
        order: [],
      },
    ]);
  }, [adminId]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  if (!adminId) return null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            prepopulatedItems={prepopulatedList[tab]}
            stylizeRow={getStyleForDashboard(adminId as string)}
            onClick={(item, state) => {
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              window.scrollTo(0, 0);
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "New patients",
            filter: patientDashboardForAdministratorFilters(
              adminId as string
            ).find(({ text }) => text === "New patients")?.filter,
            color: TaskColors.Pink,
          },
          {
            text: "Follow-ups",
            filter: patientDashboardForAdministratorFilters(
              adminId as string
            ).find(({ text }) => text === "New follow-ups")?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "New messages",
            count: newMsgsCount ?? undefined,
            color: TaskColors.Green,
          },
          {
            text: "Miscellaneous tasks",
            count: activeDashboardData.miscellaneousTasks?.count,
            color: TaskColors.Orange,
          },
        ]}
        name={adminName ? adminName.join(" ") : null}
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
