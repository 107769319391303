import React, { useEffect, useState } from "react";
import {
  SurveyPage,
  SurveyData,
  SkinSurvey,
} from "../../../../types/CustomSurvey";
import { ReactComponent as ChevronIcon } from "../../../../Assets/NewIcons/chevron.svg";
import "./style.scss";
import { useCustomSurveyStepper } from "../../../../contexts/custom-survey";
import { SurveyTitle } from "../OnBoardingTitle";
import { ProgressBar } from "../ProgressBar";
import { useProgress } from "../../../../hooks/use-progress";

interface Props {
  questions: SurveyPage[];
  onComplete: (data: SurveyData) => Promise<boolean> | boolean;
  data: SurveyData;
  offsetStart?: number;
  offsetEnd?: number;
  localStorage?: string;
  className?: string;
  setState?: SurveyData;
  isFromAccutaneSource?: boolean;
  isMedicalBackground?: boolean;
  title?: string;
  miminumPage?: number;
  customBack?: boolean;
  showProgressBar?: boolean;
}

export function CustomSurvey({
  questions,
  onComplete,
  data = {},
  offsetStart = 0,
  offsetEnd = 0,
  localStorage,
  className,
  setState,
  isFromAccutaneSource,
  isMedicalBackground,
  title,
  miminumPage = 1,
  customBack,
  showProgressBar = false,
}: Props) {
  const savedData = localStorage
    ? window.localStorage.getItem(localStorage)
    : null;
  const [currentData, setData] = useState(
    savedData ? JSON.parse(savedData) : data
  );
  const filteredQuestions = questions?.filter(
    ({ when }) => !when || !!when(currentData)
  );
  const [currentPage, setPage] = useState(
    filteredQuestions?.findIndex(({ isFilled }) => !isFilled(currentData))
  );

  const initialStep = 100 / filteredQuestions.length;

  const [progress, setStep, moveStep] = useProgress(0, initialStep);

  useEffect(() => {
    const newStep = 100 / filteredQuestions.length;
    setStep(newStep);
  }, [filteredQuestions.length]);

  useEffect(() => {
    moveStep(currentPage);
  }, [currentPage]);

  const { setBackFunction } = useCustomSurveyStepper();

  useEffect(() => {
    if (localStorage) {
      window.localStorage.setItem(localStorage, JSON.stringify(currentData));
    }
  }, [currentData]);

  const scrollUp = () => {
    setTimeout(() => {
      window.scrollTo({ top: -30, behavior: "smooth" });
    }, 100); // wait for mobile
  };

  const back = () => {
    if (currentPage <= 0) setState(1);
    setPage((oldPage) => Math.max(oldPage - 1, 0));
    scrollUp();
  };

  useEffect(() => {
    setBackFunction(back);
  }, [currentPage]);

  async function submit(_data: SkinSurvey) {
    const isDone = await onComplete(_data);
    if (isDone && localStorage) window.localStorage.removeItem(localStorage);
  }

  const currentQuestion = filteredQuestions[currentPage];
  if (!currentQuestion) return null;
  return (
    <div className={`custom-survey ${className || ""}`}>
      {showProgressBar && filteredQuestions.length > 1 ? (
        <ProgressBar progress={progress} />
      ) : null}
      <div
        className="custom-survey__heading"
        style={showProgressBar ? { paddingTop: "16px" } : {}}
      >
        {title ? <SurveyTitle>{title}</SurveyTitle> : null}
        {!customBack &&
        ((!currentPage > 0 && isFromAccutaneSource) ||
          (!isFromAccutaneSource &&
            currentPage >= miminumPage &&
            !isMedicalBackground)) ? (
          <p
            className="custom-survey__back-link"
            onClick={() =>
              currentPage > 0 ? setPage(currentPage - 1) : setPage(0)
            }
          >
            <ChevronIcon /> Back
          </p>
        ) : (
          <div />
        )}
        {!showProgressBar && filteredQuestions.length > 1 ? (
          <p className="custom-survey__counter">
            {currentPage + 1 + offsetStart} of{" "}
            {filteredQuestions.length + offsetStart + offsetEnd}
          </p>
        ) : null}
      </div>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <currentQuestion.Component
        onDone={async (_data) => {
          if (currentPage === filteredQuestions.length - 1) {
            await submit({
              ...currentData,
              ..._data,
            });
            return;
          }

          const updatedData = {
            ...currentData,
            ..._data,
          };
          if (currentQuestion.onComplete) {
            try {
              await currentQuestion.onComplete(updatedData);
            } catch (e) {
              return;
            }
          }

          setData(updatedData);
          setPage(currentPage + 1);
          scrollUp();
        }}
        data={currentData}
        stepper={{
          current: currentPage + offsetStart,
          max: filteredQuestions.length + offsetStart + offsetEnd - 1,
        }}
        question={currentQuestion?.question}
        isChildAccount={currentQuestion?.isChildAccount}
        previousAnswer={currentQuestion.previousAnswer}
      />
    </div>
  );
}
