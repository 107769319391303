import React, { useEffect } from "react";
import { useBloodWorkStepper } from "../../../../../../contexts/blood-work";
import { BloodWorkPopupSteps } from "../../steps";
import ChangePaymentMethod from "../../../../Common/Payment/ChangePaymentMethod";

export default function ChangePaymentMethodWrapper() {
  const { handleNext, bloodwork, updateValues, setIsNextDisabled } =
    useBloodWorkStepper();

  useEffect(() => {
    setIsNextDisabled(true);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as "new-card" | string;
    if (value !== "new-card") {
      updateValues({ selectedPaymentMethod: value });
      handleNext(BloodWorkPopupSteps.PAYMENT_DETAILS);
    } else {
      handleNext(BloodWorkPopupSteps.NEW_CARD);
    }
  };

  return (
    <ChangePaymentMethod
      paymentMethods={bloodwork?.customerInfo?.paymentMethods ?? []}
      handleChange={handleChange}
    />
  );
}
