import React, { useContext, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { TableBodyPayload } from "../../../../types/Table/TableBodyPayload";
import { Provider } from "../../../../types/Employee";
import { ReactComponent as CaretDownIcon } from "../../../../Assets/NewIcons/caret-icon.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/pencil.svg";
import { ReactComponent as SaveIcon } from "../../../../Assets/NewIcons/check.svg";

import "./style.css";
import PriorityEditor from "./priorityEditor";
import { useUpdateProviderStatePriorityMutation } from "../../../../features/api/employees";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";

interface Props {
  priorityList: TableBodyPayload<Provider>[];
  providerList: any;
}

export function PriorityTableBody({ priorityList, providerList }: Props) {
  const [editingRecord, setEditingRecord] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedPriorites, setUpdatedPriorities] = useState(null);

  const { showSuccess, showError } = useContext(NotificationLayerContext);

  const [updateProviderStatePriority] =
    useUpdateProviderStatePriorityMutation();

  const [editingCell, setEditingCell] = useState<number | null>(null); // Track which cell is being edited

  const handleCellEdit = (index: number) => {
    setEditingCell(editingCell === index ? null : index); // Toggle editing for individual cell
  };

  const handleUpdate = async (payload: any) => {
    const result = await updateProviderStatePriority({ payload }).unwrap();

    if (result.success) {
      showSuccess({
        title: "Success!",
        description: result.message,
      });
    } else {
      showError({
        title: "Something went wrong...",
        description: result.message,
      });
      throw new Error("Unable to archive a care coordinator");
    }
    setEditingRecord(null);
  };

  const isRowEditable = (state: string) => editingRecord === state;
  const handleRecordEdit = (state: string) => {
    if (isRowEditable(state)) {
      if (updatedPriorites) handleUpdate(updatedPriorites);
      else {
        setIsEditing(false);
        setEditingRecord(null);
      }
    } else {
      setEditingRecord(editingRecord === state ? null : state); // Set the record being edited
      // setIsEditing(false);
    }
    setIsEditing(editingRecord !== state); // Toggle editing
  };

  return (
    <>
      {priorityList.map(
        ({
          state,
          id,
          priority1,
          priority2,
          priority3,
          priority4,
          priority5,
        }) => (
          <tr key={id}>
            <td>{state}</td>
            {[priority1, priority2, priority3, priority4, priority5].map(
              (priority, priorityIndex) => {
                const renderPriorityContent = () => {
                  if (isRowEditable(state)) {
                    return (
                      <>
                        <span
                          className="priorityTable__cell"
                          onClick={() => handleCellEdit(priorityIndex)}
                        >
                          <span className="priorityTable__cellContent">
                            {priority && priority.length > 0 ? (
                              <Tooltip
                                title={
                                  <ul>
                                    {priority.map((name) => (
                                      <li key={name}>{name}</li>
                                    ))}
                                  </ul>
                                }
                                className="priorityTable__tooltip"
                                arrow
                              >
                                <span>
                                  {priority.length > 1 ? (
                                    <>
                                      {priority[0]}{" "}
                                      <span>, +{priority.length - 1}</span>
                                    </>
                                  ) : (
                                    priority[0]
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              <span style={{ fontSize: "14px" }}>
                                Not assigned
                              </span>
                            )}
                          </span>
                          <span>
                            <CaretDownIcon />
                          </span>
                        </span>
                        {editingCell === priorityIndex && isEditing && (
                          <PriorityEditor
                            providerList={providerList}
                            editingRecord={editingRecord}
                            priority={priority}
                            priorityToSet={editingCell + 1}
                            state={state}
                            updatePriorities={setUpdatedPriorities}
                          />
                        )}
                      </>
                    );
                  }

                  return priority && priority.length > 0 ? (
                    <Tooltip
                      title={
                        <ul>
                          {priority.map((name) => (
                            <li key={priority.license}>{name}</li>
                          ))}
                        </ul>
                      }
                      className="priorityTable__tooltip"
                      arrow
                    >
                      <span>
                        {priority.length > 1 ? (
                          <>
                            {priority[0]} <span>, +{priority.length - 1}</span>
                          </>
                        ) : (
                          priority[0]
                        )}
                      </span>
                    </Tooltip>
                  ) : (
                    <span style={{ fontSize: "14px" }}>Not assigned</span>
                  );
                };

                return <td key={state}>{renderPriorityContent()}</td>;
              }
            )}
            <td>
              <span
                onClick={() => handleRecordEdit(state)}
                className="patientsTable__actionsList"
              >
                {isRowEditable(state) ? <SaveIcon /> : <EditIcon />}
              </span>
            </td>
          </tr>
        )
      )}
    </>
  );
}
