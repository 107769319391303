import React, { createContext, ReactNode, useState } from "react";
import { logInfo } from "../../../../shared/logger";
import { ReactComponent as CloseSign } from "../../../../Assets/NewIcons/close-cross.svg";
import { ReactComponent as SuccessSign } from "../../../../Assets/NewIcons/check-rounded.svg";
import { ReactComponent as ErrorSign } from "../../../../Assets/NewIcons/error-rounded.svg";
import { ReactComponent as InfoSign } from "../../../../Assets/NewIcons/info-rounded.svg";
import { ReactComponent as WarnRounded } from "../../../../Assets/NewIcons/warn-rounded.svg";
import "./style.scss";

type NOTIFICATION_TYPES = "error" | "info" | "success" | "warn";

export interface ShowNotificationProps {
  title: string;
  description: ReactNode | string;
  duration?: number;
}

interface NotificationComponentProps {
  title: string;
  descr: string;
  type: NOTIFICATION_TYPES;
  onClose: () => void;
  state: "show" | "fade";
}

function NotificationComponent({
  title,
  descr,
  type,
  onClose,
  state,
}: NotificationComponentProps) {
  let Sign;

  switch (type) {
    case "success":
      Sign = SuccessSign;
      break;
    case "info":
      Sign = InfoSign;
      break;
    case "warn":
      Sign = WarnRounded;
      break;
    case "error":
      Sign = ErrorSign;
      break;
    default:
      Sign = null;
      break;
  }

  return (
    <div className={`notification-component ${state}`}>
      {Sign ? (
        <div className="notification-component__sign-wrap">
          <Sign className="notification-component__sign" />
        </div>
      ) : null}
      <div className="notification-component__message">
        <p className="notification-component__title">{title}</p>
        <p className="notification-component__descr">{descr}</p>
      </div>
      <div className="notification-component__close-button-wrap">
        <CloseSign
          className="notification-component__close-button"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

export const NotificationLayerContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showError: (config: ShowNotificationProps) => logInfo(""),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showInfo: (config: ShowNotificationProps) => logInfo(""),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSuccess: (config: ShowNotificationProps) => logInfo(""),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showWarn: (config: ShowNotificationProps) => logInfo(""),
  clearAllNotifications: () => logInfo(""),
});

interface Props {
  children: (JSX.Element | null)[] | JSX.Element | null;
}

let id = 0;

const components = new Map<number, NotificationComponentProps>();

export function NotificationLayer({ children }: Props) {
  const [render, setRender] = useState(0);

  function removeNotification(_id: number) {
    setTimeout(() => {
      components.delete(_id);
      setRender(Math.random());
    }, 200);

    const component = components.get(_id);
    if (component) {
      component.state = "fade";
      setRender(Math.random());
    }
  }

  function showNotification(
    title: string,
    descr: string,
    type: NOTIFICATION_TYPES,
    duration = 2
  ) {
    const currentId = id;
    components.set(currentId, {
      title,
      descr,
      type,
      onClose: () => removeNotification(currentId),
      state: "show",
    });
    setRender(Math.random());
    setTimeout(() => {
      removeNotification(currentId);
    }, duration * 1000);
    id++;
  }

  function showError(config: ShowNotificationProps) {
    showNotification(
      config.title,
      config.description,
      "error",
      config.duration
    );
  }

  function showInfo(config: ShowNotificationProps) {
    showNotification(config.title, config.description, "info", config.duration);
  }

  function showSuccess(config: ShowNotificationProps) {
    showNotification(
      config.title,
      config.description,
      "success",
      config.duration
    );
  }

  function showWarn(config: ShowNotificationProps) {
    showNotification(config.title, config.description, "warn", config.duration);
  }

  function renderNotifications() {
    return Array.from(components.values()).map(
      ({ title, descr, type, onClose, state }) => (
        <NotificationComponent
          type={type}
          title={title}
          descr={descr}
          onClose={onClose}
          state={state}
        />
      )
    );
  }

  function clearAllNotifications() {
    components.clear();
    setRender(Math.random());
  }

  return (
    <NotificationLayerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        showError,
        showInfo,
        showSuccess,
        showWarn,
        clearAllNotifications,
      }}
    >
      {children}
      <div className="notification-component__wrap">
        {renderNotifications()}
      </div>
    </NotificationLayerContext.Provider>
  );
}
