import React from "react";
import { Grid } from "@mui/material";
import { Button } from "../../../NewComponents/Common/Material/Button";

interface SurveyNavigationProps {
  onBack: () => void;
  submit: () => void;
  disabledContinue: boolean;
}

export function SurveyNavigationButtons({
  onBack,
  submit,
  disabledContinue,
}: SurveyNavigationProps) {
  return (
    <Grid container marginTop="16px" alignItems="center" rowGap={2}>
      <Grid item xs={12}>
        <Button
          text="Continue"
          onClick={submit}
          fullWidth
          className="survey-question__button"
          disabled={disabledContinue}
        />
      </Grid>
      <Grid item xs={12}>
        <Button text="Back" fullWidth view="secondary" onClick={onBack} />
      </Grid>
    </Grid>
  );
}
