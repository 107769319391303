import { FieldStructure } from "../../app/Pages/Provider/PatientFollowUpVisit";

export const parseFieldsToPdf = (fields: Record<string, string>) => {
  const fieldsToSend: FieldStructure[] = Object.keys(fields)
    .filter((field) => field !== "bloodSlipDetails.template")
    .filter(
      (field) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !!fields[field]
    )
    .map((field) => ({
      field_name: field,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prefilled_text: Array.isArray(fields[field])
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fields[field].map(({ label }: { label: string }) => label).join(";")
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fields[field],
    }));

  return fieldsToSend;
};
