import React, { ReactNode } from "react";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepContent,
} from "@mui/material";
import { CustomConnector, CustomStepIcon, StepperContainer } from "./styles";
import { theme } from "../../../../../config/theme";

type Step = {
  key: string;
  label: string;
  description?: ReactNode;
  completed: boolean;
};

interface Props {
  steps: Step[];
  activeStep?: number;
}

export default function CustomStepper({ steps, activeStep = 0 }: Props) {
  return (
    <StepperContainer mt={1}>
      <Stepper
        orientation="vertical"
        sx={{
          mb: 3,
          "& .Mui-completed .MuiStepContent-root": {
            borderColor: theme.palette.common.fernGreen,
          },
        }}
        connector={<CustomConnector />}
      >
        {steps.map((step, index) => (
          <Step
            key={step.key}
            active={index === activeStep}
            completed={index < activeStep ?? step.completed}
            expanded
          >
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                "& .Mui-disabled": {
                  color: "#403E48",
                  fontWeight: 500,
                },
              }}
            >
              {step.label}
            </StepLabel>
            <StepContent>{step.description}</StepContent>
          </Step>
        ))}
      </Stepper>
    </StepperContainer>
  );
}
