import React, { useState } from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "../../../../../Assets/NewIcons/expand-arrow.svg";
import { CustomAccordion } from "../../Material/Accordion";
import { ReactComponent as VaccineIcon } from "./icons/vaccine.svg";
import { ReactComponent as BloodIcon } from "./icons/blood-drop.svg";
import { ReactComponent as DocumentIcon } from "./icons/documents.svg";
import { ReactComponent as LabIcon } from "./icons/lab.svg";
import { ReactComponent as ComputerIcon } from "./icons/computer.svg";
import { TestCaption, TestDetails, TestName } from "./styles";
import { parsePrice } from "../../../../../utils/price";
import {
  PHYSICIAN_REQUISITION,
  ORDER_COST,
  SERVICE_FEE,
} from "../../../../../utils/lab-work/get-price-range";

export default function PriceDetails({ orderPrice, priceDetails }: any) {
  const [expanded, setExpanded] = useState(false);

  return (
    <CustomAccordion expanded={expanded} onClick={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={undefined}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="body2">Total</Typography>
              <Typography variant="body2">{`$${parsePrice(
                orderPrice
              )}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <TestDetails display="flex" alignItems="center">
              {`Tests: ${priceDetails.map((detail) => ` ${detail.test}`)}`}
              <ExpandMoreIcon
                style={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                  marginLeft: "8px",
                }}
              />
            </TestDetails>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            borderTop: "1px solid #E1E0E6",
            marginTop: "-10px",
            marginBottom: "10px",
          }}
        />
        <TestCaption gutterBottom>
          Test costs are passed through directly to the lab
        </TestCaption>
        <Box rowGap={2} display="flex" flexDirection="column">
          {priceDetails.map((detail) => (
            <Box display="flex" justifyContent="space-between">
              <TestName>
                <Box columnGap={1} display="flex" alignItems="center">
                  <VaccineIcon /> {detail.test}
                </Box>
              </TestName>
              <TestName>{`$${detail.price.toFixed(2)}`}</TestName>
            </Box>
          ))}
          <Box display="flex" justifyContent="space-between">
            <TestName>
              <Box columnGap={1} display="flex" alignItems="center">
                <LabIcon /> Physician requisition
              </Box>
            </TestName>
            <TestName>{`$${parsePrice(PHYSICIAN_REQUISITION)}`}</TestName>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TestName>
              <Box columnGap={1} display="flex" alignItems="center">
                <DocumentIcon /> Order cost
              </Box>
            </TestName>
            <TestName>{`$${parsePrice(ORDER_COST)}`}</TestName>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TestName>
              <Box columnGap={1} display="flex" alignItems="center">
                <BloodIcon /> Draw fee
              </Box>
            </TestName>
            <TestName>{`$${parsePrice(priceDetails?.[0]?.drawFee)}`}</TestName>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TestName>
              <Box columnGap={1} display="flex" alignItems="center">
                <ComputerIcon /> Service fees
              </Box>
            </TestName>
            <TestName>{`$${parsePrice(SERVICE_FEE)}`}</TestName>
          </Box>
        </Box>
      </AccordionDetails>
    </CustomAccordion>
  );
}
