export function getPatientDashboardForProviderFilters(providerId: string) {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              archived: false,
            },
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            bool: {
              should: [
                {
                  match: {
                    "subscription.membership": true,
                  },
                },
                {
                  bool: {
                    must: [
                      {
                        bool: {
                          should: [
                            {
                              match: {
                                appointmentStatus: "PENDING",
                              },
                            },
                            {
                              match: {
                                appointmentStatus: "CONFIRMED",
                              },
                            },
                          ],
                        },
                      },
                      {
                        bool: {
                          should: [
                            {
                              bool: {
                                must_not: {
                                  exists: {
                                    field: "followUp",
                                  },
                                },
                              },
                            },
                            {
                              bool: {
                                must: [
                                  {
                                    match: {
                                      "followUp.isTreatmentPlanSet": false,
                                    },
                                  },
                                  {
                                    match: {
                                      "followUp.isInitialFollowUp": true,
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      text: "New patients",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              archived: false,
            },
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      {
                        match: {
                          appointmentStatus: "PENDING",
                        },
                      },
                      {
                        match: {
                          appointmentStatus: "CONFIRMED",
                        },
                      },
                    ],
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        bool: {
                          must_not: {
                            exists: {
                              field: "followUp",
                            },
                          },
                        },
                      },
                      {
                        bool: {
                          must: [
                            {
                              match: {
                                "followUp.isTreatmentPlanSet": false,
                              },
                            },
                            {
                              match: {
                                "followUp.isInitialFollowUp": true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      text: "New follow-ups",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              archived: false,
            },
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            match: {
              "followUp.isTreatmentPlanSet": false,
            },
          },
          {
            match: {
              "followUp.isInitialFollowUp": false,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
        ],
      },
    },
    {
      text: "Accutane",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              archived: false,
            },
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            match: {
              "subscription.accutane": true,
            },
          },
          {
            match: {
              "subscription.membership": true,
            },
          },
        ],
      },
    },
    {
      text: "Miscellaneous tasks",
      key: "miscellaneousTasks",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              archived: false,
            },
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            bool: {
              should: [
                {
                  match: {
                    "subscription.membership": true,
                  },
                },
                {
                  bool: {
                    must: [
                      {
                        bool: {
                          should: [
                            {
                              match: {
                                appointmentStatus: "PENDING",
                              },
                            },
                            {
                              match: {
                                appointmentStatus: "CONFIRMED",
                              },
                            },
                          ],
                        },
                      },
                      {
                        bool: {
                          should: [
                            {
                              bool: {
                                must_not: {
                                  exists: {
                                    field: "followUp",
                                  },
                                },
                              },
                            },
                            {
                              bool: {
                                must: [
                                  {
                                    match: {
                                      "followUp.isTreatmentPlanSet": false,
                                    },
                                  },
                                  {
                                    match: {
                                      "followUp.isInitialFollowUp": true,
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            match: {
              tasks: providerId,
            },
          },
        ],
      },
    },
  ];
}

const noMembership = {
  bool: {
    must_not: {
      match: {
        "subscription.membership": true,
      },
    },
  },
};

const scheduledAccount = {
  exists: {
    field: "appointmentId",
  },
};

const activeFollowUps = {
  should: [
    {
      match: {
        "followUp.isInitialFollowUp": false,
      },
    },
    {
      bool: {
        must: [
          {
            match: {
              "followUp.isInitialFollowUp": true,
            },
          },
          {
            match: {
              "followUp.isTreatmentPlanSet": true,
            },
          },
        ],
      },
    },
  ],
};

const noShowAppointment = {
  match: {
    appointmentStatus: "NO_SHOW",
  },
};

const cancelledAppointment = {
  match: {
    appointmentStatus: "CANCELLED",
  },
};

const archivedPatient = {
  match: {
    archived: true,
  },
};

export function getPatientDashboardForProviderFiltersInactive(
  providerId: string
) {
  return [
    {
      text: "All",
      filter: {
        must: [
          {
            match_all: {},
          },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noMembership,
          },
          {
            bool: {
              should: [
                {
                  bool: {
                    must_not: {
                      ...scheduledAccount,
                    },
                  },
                },
                {
                  bool: {
                    ...activeFollowUps,
                  },
                },
                {
                  ...noShowAppointment,
                },
                {
                  ...cancelledAppointment,
                },
                {
                  ...archivedPatient,
                },
              ],
            },
          },
        ],
      },
    },
    {
      text: "Inactive membership",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noMembership,
          },
          {
            bool: activeFollowUps,
          },
          {
            bool: {
              must_not: {
                ...archivedPatient,
              },
            },
          },
        ],
      },
    },
    {
      text: "No shows",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noShowAppointment,
          },
          {
            bool: {
              must_not: {
                bool: activeFollowUps,
              },
            },
          },
          {
            bool: {
              must_not: {
                ...archivedPatient,
              },
            },
          },
        ],
      },
    },
    {
      text: "Cancelled",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noMembership,
          },
          {
            ...cancelledAppointment,
          },
          {
            bool: {
              must_not: {
                bool: activeFollowUps,
              },
            },
          },
          {
            bool: {
              must_not: {
                ...archivedPatient,
              },
            },
          },
        ],
      },
    },
    {
      text: "Archived",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noMembership,
          },
          {
            ...archivedPatient,
          },
        ],
      },
    },
    {
      text: "Unscheduled accounts",
      filter: {
        must: [
          { match_all: {} },
          {
            match: {
              "careTeam.employeeId": providerId,
            },
          },
          {
            ...noMembership,
          },
          {
            bool: {
              must_not: {
                ...scheduledAccount,
              },
            },
          },
          {
            bool: {
              must_not: {
                ...archivedPatient,
              },
            },
          },
        ],
      },
    },
  ];
}
