import React from "react";
import { downloadDocument } from "../../../../../utils/download-document";
import { ReactComponent as FolderIcon } from "../../../../../Assets/NewIcons/folder.svg";
import { Container } from "./styles";

export function FileButton({ name, url }: { name: string; url: string }) {
  return (
    <Container onClick={downloadDocument(url, name)}>
      <>
        <FolderIcon /> {name}
      </>
    </Container>
  );
}
