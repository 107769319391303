import { Grid } from "@mui/material";
import React from "react";
import { Button } from "../Common/Material/Button";

interface Props {
  onBack?: () => void;
  onConfirm?: () => void;
  isConfirmDisabled: boolean;
  confirmText?: string;
  backText?: string;
  loading?: boolean;
}

export default function StepperButtons({
  onBack,
  onConfirm,
  isConfirmDisabled,
  confirmText,
  backText,
  loading,
}: Props) {
  return (
    <Grid container gap={2} justifyContent="flex-start">
      {onBack && (
        <Grid item>
          <Button
            text={backText ?? ""}
            size="small"
            view="secondary"
            disabled={loading}
            onClick={onBack}
          />
        </Grid>
      )}
      {onConfirm && (
        <Grid item xs={8} md="auto">
          <Button
            text={confirmText ?? ""}
            size="small"
            fullWidth
            isLoading={loading}
            onClick={onConfirm}
            disabled={isConfirmDisabled || loading}
          />
        </Grid>
      )}
    </Grid>
  );
}
