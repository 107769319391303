import { Validator } from "./validator.interface";
import { literal } from "../../constants";

function isFutureDate(date: any) {
  const currentDateTimeStamp = new Date().getTime();
  let inputDate = date.split(literal.character.FORWARD_SLASH);
  inputDate = new Date(
    inputDate[2].trim(),
    inputDate[0].trim() - 1,
    inputDate[1].trim()
  ).getTime();
  return currentDateTimeStamp - inputDate < 0;
}

export class BirthDateValidator implements Validator {
  // eslint-disable-next-line class-methods-use-this
  validate(data: string): string | boolean {
    const valid = new RegExp(/^\d{2} \/ \d{2} \/ \d{4}$/).test(data);
    if (!valid) {
      return "Please, enter the date in MM / DD / YYYY format";
    }

    if (isFutureDate(data)) {
      return "Date cannot be in the future. Please enter a valid date";
    }

    return true;
  }
}
