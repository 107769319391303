export const getLatestTaskByType = (accutaneTasks, type: string) => {
  if (!accutaneTasks || !type) return null;

  return accutaneTasks
    .filter((task) => task.type === type)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )[0];
};
